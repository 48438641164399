import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const getWebComponentAccessTracker = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            getWebComponentAccessLoading : true,
            getWebComponentAccessError : false,
            getWebComponentAccessErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/webcomponent/getaccesstracker`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getWebComponentAccessLoading : false,
                getWebComponentAccessError : false,
                getWebComponentAccessErrorMessage : '',
                webComponentAccessTracker : request
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                getWebComponentAccessLoading : false,
                getWebComponentAccessError : true,
                getWebComponentAccessErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const webComponentGrantAccess = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            webComponentGrantAccessLoading : true,
            webComponentGrantAccessError : false,
            webComponentGrantAccessErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/webcomponent/grantaccess`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                webComponentGrantAccessLoading : false,
                webComponentGrantAccessError : false,
                webComponentGrantAccessErrorMessage : '',
                webComponentGrantAccessResponse : request
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                webComponentGrantAccessLoading : false,
                webComponentGrantAccessError : true,
                webComponentGrantAccessErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const webComponentRevokeAccess = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_BUILDER_REDUCER',
        payload : {
            webComponentRevokeAccessLoading : true,
            webComponentRevokeAccessError : false,
            webComponentRevokeAccessErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/webcomponent/revokeaccess`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                webComponentRevokeAccessLoading : false,
                webComponentRevokeAccessError : false,
                webComponentRevokeAccessErrorMessage : '',
                webComponentRevokeAccessResponse : request
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_BUILDER_REDUCER',
            payload : {
                webComponentRevokeAccessLoading : false,
                webComponentRevokeAccessError : true,
                webComponentRevokeAccessErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const setWebComponentReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_WEB_COMPONENT_REDUCER',
        payload : data
    });
}

export const resetWebComponentReducer = () => dispatch => {
    dispatch({
        type : 'RESET_WEB_COMPONENT_REDUCER'
    });
}

export const getMyWebComponents = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_WEB_COMPONENT_REDUCER',
        payload : {
            getMyWebComponentsLoading : true,
            getMyWebComponentsError : false,
            getMyWebComponentsErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/webcomponent/getmywebcomponents`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                getMyWebComponentsLoading : false,
                getMyWebComponentsError : false,
                getMyWebComponentsErrorMessage : '',
                myWebComponents : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                getMyWebComponentsLoading : false,
                getMyWebComponentsError : true,
                getMyWebComponentsErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const updateWebComponentConfig = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_WEB_COMPONENT_REDUCER',
        payload : {
            updateWebComponentConfigLoading : true,
            updateWebComponentConfigError : false,
            updateWebComponentConfigErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/webcomponent/updatewebcomponentconfig`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                updateWebComponentConfigLoading : false,
                updateWebComponentConfigError : false,
                updateWebComponentConfigErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                updateWebComponentConfigLoading : false,
                updateWebComponentConfigError : true,
                updateWebComponentConfigErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getDeployedWebComponents = () => async dispatch => {
    dispatch({
        type : 'SET_WEB_COMPONENT_REDUCER',
        payload : {
            getDeployedWebComponentsLoading : true,
            getDeployedWebComponentsError : false,
            getDeployedWebComponentsErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/webcomponent/getdeployedwebcomponents`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                getDeployedWebComponentsLoading : false,
                getDeployedWebComponentsError : false,
                getDeployedWebComponentsErrorMessage : '',
                deployedWebComponents : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_WEB_COMPONENT_REDUCER',
            payload : {
                getDeployedWebComponentsLoading : false,
                getDeployedWebComponentsError : true,
                getDeployedWebComponentsErrorMessage : request.errorMessage || ''
            }
        });
    }
}