const POST_NEW_FORM = 'POST_NEW_FORM';
const POST_NEW_FORM_ELEMENT = 'POST_NEW_FORM_ELEMENT';
const POST_EDIT_FORM_ELEMENT = 'POST_EDIT_FORM_ELEMENT';
const GET_FLEXFORMS = 'GET_FLEXFORMS';
const GET_ONE_FLEXFORM = 'GET_ONE_FLEXFORM';
const CLEAR_FLEX_FORM = 'CLEAR_FLEX_FORM';
const CLEAR_FLEX_FORMS = 'CLEAR_FLEX_FORMS';
const DELETE_FLEX_FORM = 'DELETE_FLEX_FORM'

export default function(state={}, action){
    switch(action.type){
        case POST_NEW_FORM:
            return {...state, formInConstruction:action.payload}
        case POST_NEW_FORM_ELEMENT:
            return {...state, formInConstructionUpdate:action.payload}
        case POST_EDIT_FORM_ELEMENT:
            return {...state, formInConstructionUpdate:action.payload}
        case GET_FLEXFORMS:
            return {...state,flexForms:action.payload}
        case GET_ONE_FLEXFORM:
            return {...state,formInConstruction:action.payload}
        case CLEAR_FLEX_FORM:
            return {...state, formInConstruction:action.payload}
        case CLEAR_FLEX_FORMS:
            return {...state, flexForms:action.payload}
        case DELETE_FLEX_FORM:
            return {...state, deletedForm : action.payload}
        default:
            return state;
    }
}