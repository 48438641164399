import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const getRoles = (data={}, pagination=false) => async dispatch => {
    dispatch({
        type : 'ROLES_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/roles/getroles`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_ROLES_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const saveRole = (data={}) => async dispatch => {
    dispatch({
        type : 'ROLES_REQUEST_SENT'
    });
    let request = await postRequest(
        data['_id'] ? 
        `${config.devServerProxy}/api/roles/edit` : 
        `${config.devServerProxy}/api/roles/create`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_ROLE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const getRole = (id='') => async dispatch => {
    dispatch({
        type : 'ROLES_REQUEST_SENT'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/roles/getrole/${id}`
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_ROLE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const setRoleReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_ROLE_REDUCER',
        payload : data
    });
}

export const deleteRole = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_ROLE_REDUCER',
        payload : {
            roleDeleteLoading : true,
            roleDeleteError : false,
            roleDeleteErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/roles/delete`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_ROLE_REDUCER',
            payload : {
                roleDeleteLoading : false,
                roleDeleteError : false,
                roleDeleteErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_ROLE_REDUCER',
            payload : {
                roleDeleteLoading : false,
                roleDeleteError : true,
                roleDeleteErrorMessage : request.errorMessage || ''
            }
        })
    }
}