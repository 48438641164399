const initialState = {
    taxRules : [],
    taxRuleTotalCount : 0,
    getTaxRulesLoading : false,
    getTaxRulesError : false,
    getTaxRulesErrorMessage : false,
    taxRuleInFocus : {isDefault : false},
    getTaxRuleLoading : false,
    getTaxRuleError : false,
    getTaxRuleErrorMessage : '',
    deleteTaxRuleLoading : false,
    deleteTaxRuleError : false,
    deleteTaxRuleErrorMessage : '',
    createTaxRuleLoading : false,
    createTaxRuleError : false,
    createTaxRuleErrorMessage : '',
    updateTaxRuleLoading : false,
    updateTaxRuleError : false,
    updateTaxRuleErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_TAX_RULE_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_TAX_RULE_REDUCER':
            return initialState;
        default:
            return state;
    }
}