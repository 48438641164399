const initialState = {
    subscribeActionLoading : false,
    subscribeActionError : false,
    subscribeActionErrorMessage : '',
    subscriberBaseView : '',
    subscribedUserInfo : {},
    cancelSubscriptionResponse : {},
    transferSubscriptionResponse : {},
    migrationTiers : [],
    migrationTierLoading : false,
    cancelSubscriptionLoading : false,
    cancelSubscriptionError : false,
    cancelSubscriptionErrorMessage : '',
    transferSubscriptionLoading : false,
    transferSubscriptionError : false,
    transferSubscriptionErrorMessage : '',
    subscriptionMigrationLoading : false,
    subscriptionMigrationError : false,
    subscriptionMigrationErrorMessage : '',
    addToMigrationQueueResponse : {},
    getApiKeyLoading : false,
    getApiKeyError : false,
    getApiKeyErrorMessage : false,
    apiKey : '',
    changeApiKeyLoading : false,
    changeApiKeyError : false,
    changeApiKeyErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SUBSCRIBER_ACTION_LOADING':
            return {
                ...state,
                subscribeActionLoading : true,
                subscribeActionError : false,
                subscribeActionErrorMessage : ''
            }
        case 'GET_MIGRATION_TIERS_LOADING':
            return {
                ...state,
                migrationTierLoading : true,
                subscribeActionError : false,
                subscribeActionErrorMessage : ''
            }
        case 'SUBSCRIBER_ACTION_ERROR':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : true,
                subscribeActionErrorMessage : action.payload.errorMessage || ''
            }
        case 'GET_MIGRATION_TIERS_ERROR':
            return {
                ...state,
                migrationTierLoading : false,
                subscribeActionError : true,
                subscribeActionErrorMessage : action.payload.errorMessage || ''
            }
        case 'SET_SUBSCRIBE_SUCCESS':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : ''
            }
        case 'CANCEL_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : '',
                cancelSubscriptionResponse : action.payload
            }
        case 'GET_MIGRATION_TIERS_SUCCESS':
            return {
                ...state,
                migrationTierLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : '',
                migrationTiers : action.payload
            }
        case 'TRANSFER_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : '',
                transferSubscriptionResponse : action.payload
            }
        case 'MIGRATION_QUEUE_SUCCESS':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : '',
                addToMigrationQueueResponse : action.payload
            }
        case 'SET_SUBSCRIBED_USER_INFO_SUCCESS':
            return {
                ...state,
                subscribeActionLoading : false,
                subscribeActionError : false,
                subscribeActionErrorMessage : '',
                subscribedUserInfo : action.payload
            }
        case 'SET_SUBSCRIBER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_SUBSCRIBER_REDUCER':
            return initialState;
        default:
            return state;
    }
}