// import sizeOf from 'object-sizeof'

import config from "../../config/config";
import LZUTF8 from 'lzutf8';
import dotNotationObjectUpdate from "../../utils/data/dotNotationObjectUpdate";

const initialState = {
    appBuilderColorTheme : 'dark',
    editorUrlPath : '',
    leftPanelState : {
        hoverState : '',
        activeState : ''
    },
    dragAndDropInitElementType : '',
    dragAndDropInitElementObject : {},
    dndScrollIndicator : '',
    App_Data : {},
    elementAppData : {},
    elementAppDataTracker : {},
    clearElementAppDataTracker : {},
    appInFocus : {},
    editorApp : {},
    appElements : [],
    appElementsMap : {},
    pageRootElementMap : {},
    appPages : [],
    editorPages : [],
    appBuilderLoading : false,
    appBuilderError : false,
    appBuilderErrorMessage : '',
    abTestInFocus : {},
    variationId : 'original',
    abTests : [],
    pageInFocusId : '',
    versionToQuery : 0,
    highestVersion : 0,
    makeVersionChangeRequest : true,
    apps : [],
    appDeviceMode : 'desktop',
    pdfAppDevideMode : 'desktop',
    hoveredDeviceMode : '',
    dragMode : false,
    draggableElements : {
        sectionInFocus : 'native', //native, custom
        sectionInFocusHovered : ''
    },
    dragState : {
        startX : -1000,
        startY : -1000,
        x : -1000,
        y : -1000,
        draggableType : '', //initElement, element
        initElementType : '',
        elementId : '',
        parentElementId : '',
        elementRefId : '',
        droppedElementId : '',
        dragPosition : -1,
        droppedPosition : -1,
        elementWithDropMarker : '',
        prevElementWithDropMarker : '',
        markerPosition : '', //left, right, top, bottom
    },
    previewMode : false,
    webAppPageWrapperState : {
        width : 0,
        height : '100%',
        minWidth : 0,
        maxWidth : 0,
        resizeMode : false
    },
    elementStyleGuideMap : {},
    appUpdateLoading : false,
    appUpdateError : false,
    appUpdateErrorMessage : '',
    mouseStateMap : {},
    dragStateMap : {},
    quietMode : true,
    globalQuietMode : true,
    quiteModeSocketIds : [],
    editorElements : [],
    editQueue : [],
    tempUpdateQueue : [],
    tempDeletedElementIds : [],
    tempDeletedPageIds : [],
    tempPageEditQueue : [],

    tempUpdateQueueCache : [],
    tempDeletedElementIdsCache : [],
    tempDeletedPageIdsCache : [],
    tempPageEditQueueCache : [],

    deletedPageIds : [],
    pageEditQueue : [],
    lastEditedById : '',
    lastEditBrowserId : '',
    lastEditTimestamp : 0,
    currentEditVersion : -1, 
    elementRefTracker : {},
    elementInFocusId : '',
    rightPanelState : {
        activeView : 'styles', //styles, elementSettings, events, interactions, pageInteractions
        hoveredView : ''
    },
    leftPanelWidth : 0,
    middlePanelWidth : 0,
    rightPanelWidth : 241,
    builderSectionVH : 0,
    styleBuilderSectionToggle : {
        "Flex Items" : true,
        Layout : true,
        Spacing : true,
        Size : true,
        Position : true,
        Typography : true,
        Backgrounds : true,
        Borders : true,
        Effects : true,
        "Box shadow" : true,
        Transitions : true,
        "2D & 3D Transforms" : true
    },
    pageFormSectionToggle : {
        'General' : true,
        'Route parameters' : true,
        'Dynamic SEO settings' : true,
        'SEO settings' : true,
        'Open graph settings' : true,
        Security : true,
        'Life cycle functions' : true,
        'Custom code' : true,
        'Size settings' : true,
        'Aspect ratio' : true
    },
    appSettingsSectionToggle : {
        'General' : true,
        'Hosting' : true,
        'App access' : true,
        'Manage access' : true,
        'Internal user editor access control' : true,
        'External user editor access control' : true,
        'Approval workflow' : true,
        'Launch approval workflow' : true,
        'Screen display dimensions' : true,
        'Custom code (Javascript) (HTML Head tag)' : false,
        'Delete app' : false
    },
    selectedElementStylesMap : {},
    selectedElementParentStylesMap : {},
    appFunctionMap : {},
    deletedElementIds : [],
    visualStateData : {
        sectionInFocus : 'liveState', 
        hoveredSection : ''
    },
    multiplayerHoverMap : {},
    multiPlayerUserHoverMap : {},
    multiPlayerHoverMapChangeTracker : '',
    localHoverMap : {},
    functionHubState : {
        sectionInFocus : 'lifeCycleFunctions',
        hoveredSection : '',
        lifecycleInFocus : 'onAppLoad',
        lifecycleHovered : '',
        lifecycleFunctionInFocus : {}
    },
    styleGuideSectionState : {
        sectionInFocus : 'styleGuideSection',
        hoveredSection : ''
    },
    mouseDown : false,
    mouseSumPositions : [], //only when mouse is down to detect dragging
    dragMetaState : {
        nestedElementsRectMap : {},
        direction : '', //vertical, horizontal, singular
        errorWhileDropping : false
    },
    editableTextInFocusId : '',
    editableTextElementRect : {},
    textUiCommandTracker : {},
    refreshStylePropsInterval : 0,
    sliderInFocusIndexField : '',
    tabInFocusIndexField : '',
    modalElementIds : [],
    activeModalElementId : '',
    lastMouseMoveTimestamp : 0,
    lastMouseHoverTimestamp : 0,
    lastElementUpdateTimestamp : 0,
    mouseMoveElementId : '',
    mouseMoveComponentId : '',
    mouseMoveElementPosition : -1,
    mouseMoveParentElementId : '',
    mouseMoveParentIds : [],
    mouseMoveElementListPath : '',
    mouseMoveElementRefId : '',
    mouseMoveUseParent : false,
    pageManagerPageInFocusId : '',
    appInFocusId : '',
    newAppInitialized : false,
    pageMap : {},
    birdView : false,
    showPresentationNotes : false,
    mappedRouteParams : {},
    mappedQueryStrings : {},
    elementControlFormInFocus : '', //saveStyles, updateStyles, importStyles
    saveElementStylesLoading : false,
    saveElementStylesError : false,
    saveElementStylesErrorMessage : '',
    savedElementStyleInFocus : {},
    savedElementStyles : [],
    getSavedElementStylesLoading : false,
    getSavedElementStylesError : false,
    getSavedElementStylesErrorMessage : '',
    styleArrayMode : '', //hover, focus, press
    savedStyleNameIsAvailable : false,
    savedStyleNameCheckLoading : false,
    savedStyleNameCheckError : false,
    savedStyleNameCheckErrorMessage : '',
    recentlyUpdatedVersion : 0,
    deleteSavedElementStylesLoading : false,
    deleteSavedElementStylesError : false,
    deleteSavedElementStylesErrorMessage : '',
    saveStyleGuideLoading : false,
    saveStyleGuideError : false,
    saveStyleGuideErrorMessage : '',
    styleGuides : [],
    getStyleGuideLoading : false,
    getStyleGuideError : false,
    getStyleGuideErrorMessage : '',
    deleteStyleGuideLoading : false,
    deleteStyleGuideError : false,
    deleteStyleGuideErrorMessage : '',
    parentElementIdMap : {},
    pageSlugMap : {},
    animationInFocus : {},
    animationFilterText : '',
    tableIdMap : {},
    sliderIdMap : {},
    tabIdMap : {},
    userInFocusId : '',
    logExternalUserEditorAccessLoading : false,
    logExternalUserEditorAccessError : false,
    logExternalUserEditorAccessErrorMessage : '',
    passwordSetLoading : false,
    passwordSetError : false,
    passwordSetErrorMessage : '',
    multiplayer : false,
    roomMembers : [],
    readAccess : false,
    writeAccess : false,
    savedAppElements : [],
    getSavedAppElementsLoading : false,
    getSavedAppElementsError : false,
    getSavedAppElementsErrorMessage : '',
    deleteSavedAppElementLoading : false,
    deleteSavedAppElementError : false,
    deleteSavedAppElementErrorMessage : '',
    saveAppElementLoading : false,
    saveAppElementError : false,
    saveAppElementErrorMessage : '',
    elementHeirarchyTree : {},
    elementInFocusParentId : '',
    launchRequestLoading : false,
    launchRequestError : false,
    launchRequestErrorMessage : '',
    queuedDeploymentLoading : false,
    queuedDeploymentError : false,
    queuedDeploymentErrorMessage : '',
    queuedDeployment : {},
    queuedDeployments : [],
    buildApprovalLoading : false,
    buildApprovalError : false,
    buildApprovalErrorMessage : '',
    localHoverMapChangeTracker : '',
    appDataChangeTracker : '',
    dragStateChangeTracker : '', 
    deploymentReports : [],
    getDeploymentReportsLoading : false,
    getDeploymentReportsError : false,
    getDeploymentReportsErrorMessage : '',
    deploymentMergeLoading : false,
    deploymentMergeError : false,
    deploymentMergeErrorMessage : '',
    getWebComponentAccessLoading : false,
    getWebComponentAccessError : false,
    getWebComponentAccessErrorMessage : '',
    webComponentAccessTracker : {},
    webComponentGrantAccessLoading : false,
    webComponentGrantAccessError : false,
    webComponentGrantAccessErrorMessage : '',
    webComponentGrantAccessResponse : {},
    webComponentRevokeAccessLoading : false,
    webComponentRevokeAccessError : false,
    webComponentRevokeAccessErrorMessage : '',
    webComponentRevokeAccessResponse : {},
    assetOwnerSubdomain : '',
    deleteAppLoading : false,
    deleteAppError : false,
    deleteAppErrorMessage : '',
    customDomainActivationLoading : false,
    customDomainActivationError : false,
    customDomainActivationErrorMessage : '',
    customDomainDeactivationLoading : false,
    customDomainDeactivationError : false,
    customDomainDeactivationErrorMessage : '',
    customDomainIndex : -1,
    newCustomDomainId : '',
    disableElementRefUpdate : false,
    skipRefIds : [],
    sliderPositionFieldMap : {},
    tabPositionFieldMap : {},
    sliderFixedIndexMap : {},
    dragScrollTop : 0,
    dragDropFailed : false,
    dragDropFailMessage : '',
    requestedLatestData : false,
    tableColumnVisibilityMap : {},
    tableWidthMapCache : {},
    sliderStateMap : {},
    onLoadAnimationTracker : {},
    paymentElementPublicKeyCache : {},
    paymentElementPaymentIntentCache : {},
    webComponentCache : {},
    getWebComponentLoading : false,
    getWebComponentError : false,
    getWebComponentErrorMessage : '',
    undeployAppLoading : false,
    undeployAppError : false,
    undeployAppErrorMessage : '',
    appFunctionMapTracker : '',
    selectedListItemData : {}
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_APP_BUILDER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_ONLOAD_ANIMATION_TRACKER':
            return {
                ...state,
                onLoadAnimationTracker : {
                    ...state.onLoadAnimationTracker,
                    [action.elementId] : action.value
                }
            }
        case 'SET_REDUCER_APP_DATA':
            let appSettings = state.App_Data.appSettings || {};
            let updatedSettings = action.payload.appSettings || {};
            let normalizedSettings = {
                ...appSettings,
                ...updatedSettings
            }
            return {
                ...state,
                App_Data : {
                    ...state.App_Data,
                    ...action.payload,
                    appSettings : normalizedSettings
                }
            }
        case 'SET_APP_BUILDER_DATA_IN_REDUCER':
            let appSettingsObj = state.App_Data.appSettings || {};
            let path = action.payload.path || '';
            let updateData = action.payload.data || '';
            let finalUpdate = dotNotationObjectUpdate(state.App_Data, updateData, path);
            return {
                ...state,
                App_Data : {
                    ...finalUpdate,
                    appSettings : appSettingsObj
                }
            }
        case 'UPDATE_GENERIC_ELEMENT_CACHE_IN_APP_BUILDER_REDUCER':
            return {
                ...state,
                [action.payload.field] : {
                    ...state[action.payload.field],
                    [action.payload.elementId] : {
                        ...state[action.payload.field][action.payload.elementId],
                        ...action.payload.data
                    }
                }
            }
        case 'SET_ELEMENT_APP_DATA':
            return {
                ...state,
                elementAppData : {
                    ...state.elementAppData,
                    [action.payload.elementId] : state.elementAppData[action.payload.elementId] ? 
                    {
                        ...state.elementAppData[action.payload.elementId],
                        ...action.payload.data
                    }
                    :
                    action.payload.data
                },
                elementAppDataTracker : {
                    ...state.elementAppDataTracker,
                    [action.payload.elementId] : action.payload.refresh ? 
                    Math.random() : state.elementAppDataTracker[action.payload.elementId]
                },
                clearElementAppDataTracker : {
                    ...state.clearElementAppDataTracker,
                    [action.payload.elementId] : action.payload.clearCache
                }
            }
        case 'SET_APP_BUILDER_SYNC':
            return {
                ...state,
                [action.stateField] : {
                    ...state[action.stateField],
                    [action.objectField] : {
                        ...state[action.stateField][action.objectField],
                        ...action.data
                    }
                }
            }
        case 'SET_REDUCER_APP_SETTINGS':
            return {
                ...state,
                App_Data : {
                    ...state.App_Data,
                    appSettings : {
                        ...state.App_Data.appSettings,
                        ...action.payload
                    }
                }
            }
        case 'REPLACE_REDUCER_APP_DATA':
            return {
                ...state,
                App_Data : action.payload
            }
        case 'RESET_APP_BUILDER_REDUCER':
            return initialState;
        case 'APP_BUILDER_APP_LOADING':
            return {
                ...state,
                appBuilderLoading : true,
                appBuilderError : false,
                appBuilderErrorMessage : ''
            }
        case 'APP_BUILDER_APP_ERROR':
            return {
                ...state,
                appBuilderLoading : false,
                appBuilderError : true,
                appBuilderErrorMessage : action.payload.errorMessage
            }
        case 'GET_APP_BUILDER_APP_SUCCESS':
            let decompressedApp = LZUTF8.decompress(action.payload.compressedResponse, {
                inputEncoding : 'Base64'
            });
            let parsedPayload = JSON.parse(decompressedApp);
            return {
                ...state,
                appBuilderLoading : false,
                appBuilderError : false,
                appBuilderErrorMessage : '',
                appElements : parsedPayload.elements,
                editorElements : parsedPayload.elements,
                appInFocus : parsedPayload.app,
                appInFocusId : (parsedPayload.app && parsedPayload.app['appId']) || '',
                editorApp : parsedPayload.app,
                appPages : parsedPayload.pages,
                editorPages : parsedPayload.pages,
                assetOwnerSubdomain : parsedPayload.assetOwnerSubdomain,
                versionToQuery : parsedPayload.versionInFocus,
                highestVersion : parsedPayload.activeVersion,
                appFunctionMap : {
                    ...state.appFunctionMap,
                    ...parsedPayload.functionMap
                },
                appFunctionMapTracker : Math.random(),
                appElementsTracker : Math.random(),
                birdView : config.birdViewAppTypes.includes(parsedPayload.app && parsedPayload.app['appType'])
            }
        case 'GET_APP_BUILDER_APPS_SUCCESS':
            return {
                ...state,
                appBuilderLoading : false,
                appBuilderError : false,
                appBuilderErrorMessage : '',
                apps : action.payload.results
            }
        case 'APP_UPDATE_LOADING':
            return {
                ...state,
                appUpdateLoading : true,
                appUpdateError : false,
                appUpdateErrorMessage : ''
            }
        case 'APP_UPDATE_ERROR':
            return {
                ...state,
                appUpdateLoading : false,
                appUpdateError : true,
                appUpdateErrorMessage : action.payload.errorMessage
            }
        case 'APP_UPDATE_SUCCESS':
            return {
                ...state,
                appUpdateLoading : false,
                appUpdateError : false,
                appUpdateErrorMessage : ''
            }
        case 'SAVE_ELEMENT_STYLES_LOADING':
            return {
                ...state,
                saveElementStylesLoading : true,
                saveElementStylesError : false,
                saveElementStylesErrorMessage : ''
            }
        case 'SAVE_ELEMENT_STYLES_ERROR':
            return {
                ...state,
                saveElementStylesLoading : false,
                saveElementStylesError : true,
                saveElementStylesErrorMessage : action.payload.errorMessage || 'An error occured'
            }
        case 'SAVE_ELEMENT_STYLES_SUCCESS':
            return {
                ...state,
                saveElementStylesLoading : false,
                saveElementStylesError : false,
                saveElementStylesErrorMessage : ''
            }
        case 'SAVED_ELEMENT_NAMECHECK_LOADING':
            return {
                ...state,
                savedStyleNameCheckLoading : true,
                savedStyleNameCheckError : false,
                savedStyleNameCheckErrorMessage : ''
            }
        case 'SAVED_ELEMENT_NAMECHECK_SUCCESS':
            return {
                ...state,
                savedStyleNameCheckLoading : false,
                savedStyleNameCheckError : false,
                savedStyleNameCheckErrorMessage : '',
                savedStyleNameIsAvailable : action.payload['available']
            }
        case 'SAVED_ELEMENT_NAMECHECK_ERROR':
            return {
                ...state,
                savedStyleNameCheckLoading : false,
                savedStyleNameCheckError : true,
                savedStyleNameCheckErrorMessage : action.payload.errorMessage || '',
                savedStyleNameIsAvailable : false
            }
        case 'GET_SAVED_ELEMENT_STYLES_LOADING':
            return {
                ...state,
                getSavedElementStylesLoading : true,
                getSavedElementStylesError : false,
                getSavedElementStylesErrorMessage : '',
            }
        case 'GET_SAVED_ELEMENT_STYLES_ERROR':
            return {
                ...state,
                getSavedElementStylesLoading : false,
                getSavedElementStylesError : true,
                getSavedElementStylesErrorMessage : action.payload.errorMessage || '',
            }
        case 'GET_SAVED_ELEMENT_STYLES_SUCCESS':
            return {
                ...state,
                savedElementStyles : action.payload.results || [],
                getSavedElementStylesLoading : false,
                getSavedElementStylesError : false,
                getSavedElementStylesErrorMessage : '',
            }
        case 'DELETE_SAVED_ELEMENT_STYLES_LOADING':
            return {
                ...state,
                deleteSavedElementStylesLoading : true,
                deleteSavedElementStylesError : false,
                deleteSavedElementStylesErrorMessage : ''
            }
        case 'DELETE_SAVED_ELEMENT_STYLES_ERROR':
            return {
                ...state,
                deleteSavedElementStylesLoading : false,
                deleteSavedElementStylesError : true,
                deleteSavedElementStylesErrorMessage : action.payload.errorMessage || '',
            }
        case 'DELETE_SAVED_ELEMENT_STYLES_SUCCESS':
            return {
                ...state,
                deleteSavedElementStylesLoading : false,
                deleteSavedElementStylesError : false,
                deleteSavedElementStylesErrorMessage : '',
            }
        case 'GET_APP_STYLE_GUIDES_LOADING':
            return {
                ...state,
                getStyleGuideLoading : true,
                getStyleGuideError : false,
                getStyleGuideErrorMessage : ''
            }
        case 'GET_APP_STYLE_GUIDES_ERROR':
            return {
                ...state,
                getStyleGuideLoading : false,
                getStyleGuideError : true,
                getStyleGuideErrorMessage : action.payload.errorMessage || ''
            }
        case 'GET_APP_STYLE_GUIDES_SUCCESS':
            return {
                ...state,
                getStyleGuideLoading : false,
                getStyleGuideError : false,
                getStyleGuideErrorMessage : '',
                styleGuides : action.payload.results || []
            }
        case 'SAVE_APP_STYLE_GUIDE_LOADING':
            return {
                ...state,
                saveStyleGuideLoading : true,
                saveStyleGuideError : false,
                saveStyleGuideErrorMessage : '',
            }
        case 'SAVE_APP_STYLE_GUIDE_ERROR':
            return {
                ...state,
                saveStyleGuideLoading : false,
                saveStyleGuideError : true,
                saveStyleGuideErrorMessage : action.payload.errorMessage || '',
            }
        case 'SAVE_APP_STYLE_GUIDE_SUCCESS':
            return {
                ...state,
                saveStyleGuideLoading : false,
                saveStyleGuideError : false,
                saveStyleGuideErrorMessage : '',
            }
        case 'DELETE_APP_STYLE_GUIDE_LOADING':
            return {
                ...state,
                deleteStyleGuideLoading : true,
                deleteStyleGuideError : false,
                deleteStyleGuideErrorMessage : ''
            }
        case 'DELETE_APP_STYLE_GUIDE_ERROR':
            return {
                ...state,
                deleteStyleGuideLoading : false,
                deleteStyleGuideError : true,
                deleteStyleGuideErrorMessage : action.payload.errorMessage || ''
            }
        case 'DELETE_APP_STYLE_GUIDE_SUCCESS':
            return {
                ...state,
                deleteStyleGuideLoading : false,
                deleteStyleGuideError : false,
                deleteStyleGuideErrorMessage : ''
            }
        case 'LOG_EXTERNAL_USER_EDITOR_ACCESS_LOADING':
            return {
                ...state,
                logExternalUserEditorAccessLoading : true,
                logExternalUserEditorAccessError : false,
                logExternalUserEditorAccessErrorMessage : ''
            }
        case 'LOG_EXTERNAL_USER_EDITOR_ACCESS_ERROR':
            return {
                ...state,
                logExternalUserEditorAccessLoading : false,
                logExternalUserEditorAccessError : true,
                logExternalUserEditorAccessErrorMessage : action.payload.errorMessage || ''
            }
        case 'LOG_EXTERNAL_USER_EDITOR_ACCESS_SUCCESS':
            return {
                ...state,
                logExternalUserEditorAccessLoading : false,
                logExternalUserEditorAccessError : false,
                logExternalUserEditorAccessErrorMessage : ''
            }
        case 'GET_SAVED_APP_ELEMENTS_SUCCESS':
            return {
                ...state,
                getSavedAppElementsLoading : false,
                getSavedAppElementsError : false,
                getSavedAppElementsErrorMessage : '',
                savedAppElements : action.payload.append ?
                [...state.savedAppElements, ...action.payload.results]
                :
                action.payload.results
            }
        case 'SET_BUILDER_WEB_COMPONENT':
            return {
                ...state,
                webComponentCache : {
                    ...state.webComponentCache,
                    [action.payload.appId] : action.payload.app
                }
            }
        default:
            return state;
    }
}