const initialState = {
    portalMetadata : {},
    getMetadataLoading : false,
    getMetadataError : false,
    getMetadataErrorMessage : '',
    passwordLoginLoading : false,
    passwordLoginError : false,
    passwordLoginErrorMessage : '',
    requestPasswordLoading : false,
    requestPasswordError : false,
    requestPasswordErrorMessage : '',
    getCustomerLoading : false,
    getCustomerError : false,
    getCustomerErrorMessage : '',
    user : {},
    portalView : '',
    portalLogoutLoading : false,
    portalLogoutError : false,
    portalLogoutErrorMessage : '',
    getPaymentSubscriberLoading : false,
    getPaymentSubscriberError : false,
    getPaymentSubscriberErrorMessage : '',
    paymentSubscriber : {},
    getPaymentSubscribersLoading : false,
    getPaymentSubscribersError : false,
    getPaymentSubscribersErrorMessage : '',
    paymentSubscribers : [],
    paymentSubscribersTotalCount : 0,
    paymentSubscriberInFocus : {},
    getSubscriberInFocusLoading : false,
    getSubscriberInFocusError : false,
    getSubscriberInFocusErrorMessage : '',
    editSubscriberLoading : false,
    editSubscriberError : false,
    editSubscriberErrorMessage : '',
    newSubscribers : [],
    newSubscriberCount : 0,
    addSubscriberLoading : false,
    addSubscriberError : false,
    addSubscriberErrorMessage : '',
    cancelSubscriptionsLoading : false,
    cancelSubscriptionsError : false,
    cancelSubscriptionsErrorMessage : '',
    terminateSubscriptionPaymentLoading : false,
    terminateSubscriptionPaymentError : false,
    terminateSubscriptionPaymentErrorMessage : '',
    offSchedulePaymentLoading : false,
    offSchedulePaymentError : false,
    offSchedulePaymentErrorMessage : '',
    pastDueSubscriptionPaymentLoading : false,
    pastDueSubscriptionPaymentError : false,
    pastDueSubscriptionPaymentErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_CUSTOMER_PORTAL_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_CUSTOMER_PORTAL_REDUCER':
            return initialState;
        default:
            return state;
    }
}