import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';
/* eslint-disable-next-line */
import fetchFunctionWorker from 'workerize-loader!../../worker/fetchFunction.worker.js';

export const setInhouzSignReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : data
    });
}

export const setInhouzSignDataInReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_DATA_IN_REDUCER',
        payload : data
    });
}

export const resetInhouzSignReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_SIGN_REDUCER'
    });
}

export const updateInhouzSignDocumentData = (payload={}) => dispatch => {
    dispatch({
        type : 'UPDATE_INHOUZ_SIGN_DOCUMENT_DATA',
        payload
    });
}

export const manageInhouzSignRequiredFields = (payload={}) => dispatch => {
    dispatch({
        type : 'MANAGE_INHOUZ_SIGN_REQUIRED_FIELDS',
        payload
    });
}

export const getInhouzSignTemplates = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignTemplatesLoading : true,
            getInhouzSignTemplatesError : false,
            getInhouzSignTemplatesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/template/gettemplates`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignTemplatesLoading : false,
                getInhouzSignTemplatesError : false,
                getInhouzSignTemplatesErrorMessage : '',
                templates : request.results || [],
                templateTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignTemplatesLoading : false,
                getInhouzSignTemplatesError : true,
                getInhouzSignTemplatesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createInhouzSignTemplate = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            createTemplateLoading : true,
            createTemplateError : false,
            createTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/template/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : false,
                createTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : true,
                createTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createInhouzSignTemplateFromClone = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            createTemplateLoading : true,
            createTemplateError : false,
            createTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/template/createfromclone`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : false,
                createTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : true,
                createTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignTemplate = (id='') => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignTemplateLoading : true,
            getInhouzSignTemplateError : false,
            getInhouzSignTemplateErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/inhouzsign/template/gettemplate/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignTemplateLoading : false,
                getInhouzSignTemplateError : false,
                getInhouzSignTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignTemplateLoading : false,
                getInhouzSignTemplateError : true,
                getInhouzSignTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editInhouzSignTemplate = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            editInhouzSignTemplateLoading : true,
            editInhouzSignTemplateError : false,
            editInhouzSignTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/template/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                editInhouzSignTemplateLoading : false,
                editInhouzSignTemplateError : false,
                editInhouzSignTemplateErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                editInhouzSignTemplateLoading : false,
                editInhouzSignTemplateError : true,
                editInhouzSignTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const setInhouzSignDocumentDelta = (data={}, isTemplate=false) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_DOCUMENT_DELTA',
        payload : {
            data,
            isTemplate
        }
    });
}

export const deleteInhouzSignTemplate = (id) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            deleteInhouzSignTemplateLoading : true,
            deleteInhouzSignTemplateError : false,
            deleteInhouzSignTemplateErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/inhouzsign/template/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                deleteInhouzSignTemplateLoading : false,
                deleteInhouzSignTemplateError : false,
                deleteInhouzSignTemplateErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                deleteInhouzSignTemplateLoading : false,
                deleteInhouzSignTemplateError : true,
                deleteInhouzSignTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignGlobalApprovalWorkflow = () => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getGlobalApprovalWorkflowLoading : true,
            getGlobalApprovalWorkflowError : false,
            getGlobalApprovalWorkflowErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/inhouzsign/approvalworkflow/getglobalworkflow`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getGlobalApprovalWorkflowLoading : false,
                getGlobalApprovalWorkflowError : false,
                getGlobalApprovalWorkflowErrorMessage : '',
                globalApprovalWorkflow : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getGlobalApprovalWorkflowLoading : false,
                getGlobalApprovalWorkflowError : true,
                getGlobalApprovalWorkflowErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updateInhouzSignGlobalApprovalWorkflow = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            updateApprovalWorkflowLoading : true,
            updateGlobalApprovalWorkflowError : false,
            updateGlobalApprovalWorflowErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/approvalworkflow/update`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                updateApprovalWorkflowLoading : false,
                updateGlobalApprovalWorkflowError : false,
                updateGlobalApprovalWorflowErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                updateApprovalWorkflowLoading : false,
                updateGlobalApprovalWorkflowError : true,
                updateGlobalApprovalWorflowErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignDocuments = (data={}, customerPortalView=false) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignDocumentsLoading : true,
            getInhouzSignDocumentsError : false,
            getInhouzSignDocumentsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/${customerPortalView ? 'getportaldocuments' : 'getdocuments'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignDocumentsLoading : false,
                getInhouzSignDocumentsError : false,
                getInhouzSignDocumentsErrorMessage : '',
                documents : request.results || [],
                documentsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignDocumentsLoading : false,
                getInhouzSignDocumentsError : true,
                getInhouzSignDocumentsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createInhouzDocument = (data={}, fromTemplate=false) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            createInhouzDocumentLoading : true,
            createInhouzDocumentError : false,
            createInhouzDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/${fromTemplate ? 'createfromtemplate' : 'create'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createInhouzDocumentLoading : false,
                createInhouzDocumentError : false,
                createInhouzDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                createInhouzDocumentLoading : false,
                createInhouzDocumentError : true,
                createInhouzDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignDocumentLoading : true,
            getInhouzSignDocumentError : false,
            getInhouzSignDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/getdocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignDocumentLoading : false,
                getInhouzSignDocumentError : false,
                getInhouzSignDocumentErrorMessage : '',
                documentInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignDocumentLoading : false,
                getInhouzSignDocumentError : true,
                getInhouzSignDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            deleteInhouzSignDocumentLoading : true,
            deleteInhouzSignDocumentError : false,
            deleteInhouzSignDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                deleteInhouzSignDocumentLoading : false,
                deleteInhouzSignDocumentError : false,
                deleteInhouzSignDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                deleteInhouzSignDocumentLoading : false,
                deleteInhouzSignDocumentError : true,
                deleteInhouzSignDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const sendInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            sendInhouzSignDocumentLoading : true,
            sendInhouzSignDocumentError : false,
            sendInhouzSignDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsign/document/senddocument`,
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                sendInhouzSignDocumentLoading : false,
                sendInhouzSignDocumentError : false,
                sendInhouzSignDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                sendInhouzSignDocumentLoading : false,
                sendInhouzSignDocumentError : true,
                sendInhouzSignDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            editInhouzSignDocumentLoading : true,
            editInhouzSignDocumentError : false,
            editInhouzSignDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsign/document/edit`,
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                editInhouzSignDocumentLoading : false,
                editInhouzSignDocumentError : false,
                editInhouzSignDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                editInhouzSignDocumentLoading : false,
                editInhouzSignDocumentError : true,
                editInhouzSignDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignEncryptedDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignDocumentLoading : true,
            getInhouzSignDocumentError : false,
            getInhouzSignDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsign/document/getencrypteddocument`,
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error && 
        request['encryptedDocument']
    ){
        const {
            encryptedDocument=''
        } = request;

        let documentInFocus;
        let decryptedPayload = encryptDecrypt(encryptedDocument, false);
        if(decryptedPayload){
            let parsedData = JSON.parse(decryptedPayload);
            if(new Date().getTime() <= parsedData['expirationTimestamp']){
                documentInFocus = parsedData['document'];
            }
        }

        if(documentInFocus){
            dispatch({
                type : 'SET_INHOUZ_SIGN_REDUCER',
                payload : {
                    getInhouzSignDocumentLoading : false,
                    getInhouzSignDocumentError : false,
                    getInhouzSignDocumentErrorMessage : '',
                    documentInFocus
                }
            });
        }else{
            dispatch({
                type : 'SET_INHOUZ_SIGN_REDUCER',
                payload : {
                    getInhouzSignDocumentLoading : false,
                    getInhouzSignDocumentError : true,
                    getInhouzSignDocumentErrorMessage : 'Invalid document.'
                }
            })
        }
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignDocumentLoading : false,
                getInhouzSignDocumentError : true,
                getInhouzSignDocumentErrorMessage : request.errorMessage || 'Failed to find document'
            }
        })
    }
}

export const getContractAppBuild = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            contractAppBuildLoading : true,
            contractAppBuildError : false,
            contractAppBuildErrorMessage : ''
        }
    });
    let workerInstance = fetchFunctionWorker();
    let options = {
        method : 'POST',
        headers:{
            'Accept':"application/json",
            'Content-Type':"application/json",
            'Cache': 'no-cache'
        },
        credentials: 'omit',
        mode: 'cors',
        body:JSON.stringify(data)
    }
    let request = await workerInstance.fetchFunction(
        `${config.devServerProxy}/api/inhouzsign/document/getcontractbuild`,
        options
    )
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                contractAppBuildLoading : false,
                contractAppBuildError : false,
                contractAppBuildErrorMessage : '',
                contractAppBuild : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                contractAppBuildLoading : false,
                contractAppBuildError : true,
                contractAppBuildErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignApprovalRequests = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getApprovalRequestsLoading : true,
            getApprovalRequestsError : false,
            getApprovalRequestsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/approvalworkflow/getapprovalrequests`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalRequestsLoading : false,
                getApprovalRequestsError : false,
                getApprovalRequestsErrorMessage : '',
                approvalRequests : request.results || [],
                approvalRequestsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalRequestsLoading : false,
                getApprovalRequestsError : true,
                getApprovalRequestsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignApprovalRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getApprovalRequestLoading : true,
            getApprovalRequestError : false,
            getApprovalRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/approvalworkflow/getapprovalrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalRequestLoading : false,
                getApprovalRequestError : false,
                getApprovalRequestErrorMessage : '',
                approvalRequest : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalRequestLoading : false,
                getApprovalRequestError : true,
                getApprovalRequestErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignApprovalBreakdown = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getApprovalBreakdownLoading : true,
            getApprovalBreakdownError : false,
            getApprovalBreakdownErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/approvalworkflow/getapprovalbreakdown`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalBreakdownLoading : false,
                getApprovalBreakdownError : false,
                getApprovalBreakdownErrorMessage : '',
                approvalBreakdown : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getApprovalBreakdownLoading : false,
                getApprovalBreakdownError : true,
                getApprovalBreakdownErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const submitInhouzSignApproval = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            documentApprovalLoading : true,
            documentApprovalError : false,
            documentApprovalErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/workflowapproval`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                documentApprovalLoading : false,
                documentApprovalError : false,
                documentApprovalErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                documentApprovalLoading : false,
                documentApprovalError : true,
                documentApprovalErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignAuditLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getAuditLogsLoading : true,
            getAuditLogsError : false,
            getAuditLogsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/getauditlogs`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getAuditLogsLoading : false,
                getAuditLogsError : false,
                getAuditLogsErrorMessage : '',
                auditLogs : request.results || [],
                auditLogsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getAuditLogsLoading : false,
                getAuditLogsError : true,
                getAuditLogsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const distributeInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            distributeDocumentLoading : true,
            distributeDocumentError : false,
            distributeDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/distribute`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                distributeDocumentLoading : false,
                distributeDocumentError : false,
                distributeDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                distributeDocumentLoading : false,
                distributeDocumentError : true,
                distributeDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const signInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            signDocumentLoading : true,
            signDocumentError : false,
            signDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/signdocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                signDocumentLoading : false,
                signDocumentError : false,
                signDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                signDocumentLoading : false,
                signDocumentError : true,
                signDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const generateInhouzSignPdf = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            generateInhouzSignPdfLoading : true,
            generateInhouzSignPdfError : false,
            generateInhouzSignPdfErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/uploadgenerateddocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                generateInhouzSignPdfLoading : false,
                generateInhouzSignPdfError : false,
                generateInhouzSignPdfErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                generateInhouzSignPdfLoading : false,
                generateInhouzSignPdfError : true,
                generateInhouzSignPdfErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignPdfBase64 = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getInhouzSignPdfLoading : true,
            getInhouzSignPdfError : false,
            getInhouzSignPdfErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/getgeneratedinhouzsignfile`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignPdfLoading : false,
                getInhouzSignPdfError : false,
                getInhouzSignPdfErrorMessage : '',
                inhouzSignPdfBase64 : request['base64'] || ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getInhouzSignPdfLoading : false,
                getInhouzSignPdfError : true,
                getInhouzSignPdfErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const cancelInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            cancelDocumentLoading : true,
            cancelDocumentError : false,
            cancelDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/canceldocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                cancelDocumentLoading : false,
                cancelDocumentError : false,
                cancelDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                cancelDocumentLoading : false,
                cancelDocumentError : true,
                cancelDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const declineInhouzSignDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            declineDocumentLoading : true,
            declineDocumentError : false,
            declineDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/declinedocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                declineDocumentLoading : false,
                declineDocumentError : false,
                declineDocumentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                declineDocumentLoading : false,
                declineDocumentError : true,
                declineDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const generateInhouzSignerUrl = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            generateInhouzSignUrlLoading : true,
            generateInhouzSignUrlError : false,
            generateInhouzSignUrlErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/generatesignerurl`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                generateInhouzSignUrlLoading : false,
                generateInhouzSignUrlError : false,
                generateInhouzSignUrlErrorMessage : '',
                inhouzSignerUrl : request['url']
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                generateInhouzSignUrlLoading : false,
                generateInhouzSignUrlError : true,
                generateInhouzSignUrlErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getIdentityVerificationLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getIdentityVerificationLogsLoading : true,
            getIdentityVerificationLogsError : false,
            getIdentityVerificationLogsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/getidentityverificationlogs`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getIdentityVerificationLogsLoading : false,
                getIdentityVerificationLogsError : false,
                getIdentityVerificationLogsErrorMessage : '',
                identityVerificationLogs : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getIdentityVerificationLogsLoading : false,
                getIdentityVerificationLogsError : true,
                getIdentityVerificationLogsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzSignAttachmentList = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            getAttachmentListLoading : true,
            getAttachmentListError : false,
            getAttachmentListErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/attachment/getattachments`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getAttachmentListLoading : false,
                getAttachmentListError : false,
                getAttachmentListErrorMessage : '',
                attachmentList : request['results'] || [],
                attachmentListTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                getAttachmentListLoading : false,
                getAttachmentListError : true,
                getAttachmentListErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const sendInhouzSignPaymentNotification = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            inhouzSignPaymentNotificationLoading : true,
            inhouzSignPaymentNotificationError : false,
            inhouzSignPaymentNotificationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/initinhouzsignpayment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                inhouzSignPaymentNotificationLoading : false,
                inhouzSignPaymentNotificationError : false,
                inhouzSignPaymentNotificationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                inhouzSignPaymentNotificationLoading : false,
                inhouzSignPaymentNotificationError : true,
                inhouzSignPaymentNotificationErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const markInhouzSignDocAsComplete = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SIGN_REDUCER',
        payload : {
            markAsCompleteLoading : true,
            markAsCompleteError : false,
            markAsCompleteErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsign/document/markascomplete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                markAsCompleteLoading : false,
                markAsCompleteError : false,
                markAsCompleteErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SIGN_REDUCER',
            payload : {
                markAsCompleteLoading : false,
                markAsCompleteError : true,
                markAsCompleteErrorMessage : request.errorMessage || ''
            }
        })
    }
}