import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import deleteRequest from '../../utils/requests/deleteRequest';

export const saveElementStyles = (data={}) => async dispatch => {
    dispatch({
        type : 'SAVE_ELEMENT_STYLES_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/savedstyles/${data['_id'] ? 'edit' : 'create'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SAVE_ELEMENT_STYLES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SAVE_ELEMENT_STYLES_ERROR',
            payload : request
        })
    }
}

export const savedElementNameCheck = (data={}) => async dispatch => {
    dispatch({
        type : 'SAVED_ELEMENT_NAMECHECK_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/savedstyles/namecheck`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SAVED_ELEMENT_NAMECHECK_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SAVED_ELEMENT_NAMECHECK_ERROR',
            payload : request
        })
    }
}

export const getSavedElementStyles = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SAVED_ELEMENT_STYLES_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/savedstyles/getstyles`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_SAVED_ELEMENT_STYLES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_SAVED_ELEMENT_STYLES_ERROR',
            payload : request
        })
    }
}

export const deleteSavedElementStyles = (appId='', styleId='') => async dispatch => {
    dispatch({
        type : 'DELETE_SAVED_ELEMENT_STYLES_LOADING'
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/appbuilder/savedstyles/delete/${appId}/${styleId}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'DELETE_SAVED_ELEMENT_STYLES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'DELETE_SAVED_ELEMENT_STYLES_ERROR',
            payload : request
        })
    }
}

export const getAppStyleGuides = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_APP_STYLE_GUIDES_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/styleguide/getstyleguide`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_STYLE_GUIDES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_APP_STYLE_GUIDES_ERROR',
            payload : request
        })
    }
}

export const saveAppStyleGuide = (data={}) => async dispatch => {
    dispatch({
        type : 'SAVE_APP_STYLE_GUIDE_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/styleguide/${data['_id'] ? 'edit' : 'create'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SAVE_APP_STYLE_GUIDE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SAVE_APP_STYLE_GUIDE_ERROR',
            payload : request
        })
    }
}

export const deleteAppStyleGuide = (appId='', styleGuideId='') => async dispatch => {
    dispatch({
        type : 'DELETE_APP_STYLE_GUIDE_LOADING'
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/appbuilder/styleguide/delete/${appId}/${styleGuideId}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'DELETE_APP_STYLE_GUIDE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'DELETE_APP_STYLE_GUIDE_ERROR',
            payload : request
        })
    }
}