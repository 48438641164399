import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const SetAppMetricsReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : data
    });
}

export const resetAppMetricsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_APP_METRICS_REDUCER'
    });
}

export const getAppMetricsTopSection = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            topSectionLoading : true,
            topSectionError : false,
            topSectionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/gettopsection`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : false,
                topSectionErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : true,
                topSectionErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getAppVisitTimeline = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            appVisitsLoading : true,
            appVisitsError : false,
            appVisitsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getappvisitlist`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                appVisitsLoading : false,
                appVisitsError : false,
                appVisitsErrorMessage : '',
                appVisitsTimeline : request.metrics || []
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                appVisitsLoading : false,
                appVisitsError : true,
                appVisitsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getWebPieMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            webPieMetricsLoading : true,
            webPieMetricsError : false,
            webPieMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getpiemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                webPieMetricsLoading : false,
                webPieMetricsError : false,
                webPieMetricsErrorMessage : '',
                pieMetrics : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                webPieMetricsLoading : false,
                webPieMetricsError : true,
                webPieMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getBasicApiMetrics = (data={}, alt=false) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            basicApiMetricsLoading : true,
            basicApiMetricsError : false,
            basicApiMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getBasicApiMetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        let field = alt ? 'altBasicApiMetrics' : 'basicApiMetrics';
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                basicApiMetricsLoading : false,
                basicApiMetricsError : false,
                basicApiMetricsErrorMessage : '',
                [field] : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                basicApiMetricsLoading : false,
                basicApiMetricsError : true,
                basicApiMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getAverageApiTime = (data={}, alt=false) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            averageApiTimeLoading : true,
            averageApiTimeError : false,
            averageApiTimeErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getaverageapitime`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        let field = alt ? 'altAverageApiTime' : 'averageApiTime';
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                averageApiTimeLoading : false,
                averageApiTimeError : false,
                averageApiTimeErrorMessage : '',
                [field] : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                averageApiTimeLoading : false,
                averageApiTimeError : true,
                averageApiTimeErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getApiTraffic = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            apiTrafficLoading : true,
            apiTrafficError : false,
            apiTrafficErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getapitraffic`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                apiTrafficLoading : false,
                apiTrafficError : false,
                apiTrafficErrorMessage : '',
                apiTraffic : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                apiTrafficLoading : false,
                apiTrafficError : true,
                apiTrafficErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getApiUsageSummary = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_METRICS_REDUCER',
        payload : {
            apiUsageSummaryLoading : true,
            apiUsageSummaryError : false,
            apiUsageSummaryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appmetrics/getapiusagesummary`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                apiUsageSummaryLoading : false,
                apiUsageSummaryError : false,
                apiUsageSummaryErrorMessage : '',
                apiUsageSummary : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_METRICS_REDUCER',
            payload : {
                apiUsageSummaryLoading : false,
                apiUsageSummaryError : true,
                apiUsageSummaryErrorMessage : request.errorMessage || ''
            }
        })
    }
}