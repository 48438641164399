import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const getPaymentInvoices = (data={}, subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_TRANSACTION_REDUCER',
        payload : {
            paymentIntentLoading : true,
            paymentIntentError : false,
            paymentIntentErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/${subscriptionPayment ? 'subscriptionpayment' : 'payment'}/getpaymentintents`, 
        'POST', 
        data,
        {},
        subscriptionPayment ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                paymentIntentLoading : false,
                paymentIntentError : false,
                paymentIntentErrorMessage : '',
                paymentIntents : request['results'] || [],
                paymentIntentsTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                paymentIntentLoading : false,
                paymentIntentError : true,
                paymentIntentErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}

export const getPaymentCharges = (data={}, subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_TRANSACTION_REDUCER',
        payload : {
            paymentChargeLoading : true,
            paymentChargeError : false,
            paymentChargeErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/${subscriptionPayment ? 'subscriptionpayment' : 'payment'}/getpaymentcharges`, 
        'POST', 
        data,
        {},
        subscriptionPayment ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                paymentChargeLoading : false,
                paymentChargeError : false,
                paymentChargeErrorMessage : '',
                paymentCharges : request['results'] || [],
                paymentChargesTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                paymentChargeLoading : false,
                paymentChargeError : true,
                paymentChargeErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}

export const setPaymentTransactionReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_TRANSACTION_REDUCER',
        payload : data
    });
}

export const resetPaymentTransactionReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_TRANSACTION_REDUCER'
    });
}

export const triggerQuickPay = (data={}, fromAccountSettings=false) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_TRANSACTION_REDUCER',
        payload : {
            quickPayLoading : true,
            quickPayError : false,
            quickPayErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/latepayment/quickpay`, 
        'POST', 
        data,
        {},
        fromAccountSettings ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                quickPayLoading : false,
                quickPayError : false,
                quickPayErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_TRANSACTION_REDUCER',
            payload : {
                quickPayLoading : false,
                quickPayError : true,
                quickPayErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}