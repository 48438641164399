import getCsrf from "../cookies/getCsrf";

export default async function (url, cookieName=''){
    const request = await fetch(url, {
        method : 'DELETE',
        headers : {
            'x-csrfToken' : getCsrf(cookieName)
        },
        credentials : 'include'
    })
    .then(async res => {
        let jsonResponse = await res.json();
        if(jsonResponse.error || jsonResponse.hasError){
            return {
                hasError : true,
                errorPayload : (
                    jsonResponse['error'] && 
                    jsonResponse['error']['errorPayload'] ?
                    typeof jsonResponse['error']['errorPayload'] !== 'string' ?
                    JSON.stringify(jsonResponse['error']['errorPayload']) : 
                    jsonResponse['error']['errorPayload']
                    :
                    ''
                ) || '',
                errorMessage : (
                    jsonResponse['error'] && 
                    jsonResponse['error']['message']
                ) || ''
            }
        }
        return jsonResponse;
    })
    .catch((error) => {
        if(error){
            return {
                hasError : true,
                errorPayload : error
            };
        }
    })

    return request;
}