import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';

export const setPdfGeneratorReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : data
    });
}

export const resetPdfGeneratorReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PDF_GENERATOR_REDUCER'
    });
}

export const getPdfGeneratorTemplates = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            getPdfGeneratorTemplatesLoading : true,
            getPdfGeneratorTemplatesError : false,
            getPdfGeneratorTemplatesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/template/gettemplates`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGeneratorTemplatesLoading : false,
                getPdfGeneratorTemplatesError : false,
                getPdfGeneratorTemplatesErrorMessage : '',
                templates : request.results || [],
                templateTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGeneratorTemplatesLoading : false,
                getPdfGeneratorTemplatesError : true,
                getPdfGeneratorTemplatesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPdfGeneratorTemplate = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            createTemplateLoading : true,
            createTemplateError : false,
            createTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/template/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : false,
                createTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : true,
                createTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPdfGeneratorTemplateFromClone = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            createTemplateLoading : true,
            createTemplateError : false,
            createTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/template/createfromclone`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : false,
                createTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                createTemplateLoading : false,
                createTemplateError : true,
                createTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPdfGeneratorTemplate = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            getPdfGeneratorTemplateLoading : true,
            getPdfGeneratorTemplateError : false,
            getPdfGeneratorTemplateErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/pdfgenerator/template/gettemplate/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGeneratorTemplateLoading : false,
                getPdfGeneratorTemplateError : false,
                getPdfGeneratorTemplateErrorMessage : '',
                templateInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGeneratorTemplateLoading : false,
                getPdfGeneratorTemplateError : true,
                getPdfGeneratorTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editPdfGeneratorTemplate = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            editPdfGeneratorTemplateLoading : true,
            editPdfGeneratorTemplateError : false,
            editPdfGeneratorTemplateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/template/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                editPdfGeneratorTemplateLoading : false,
                editPdfGeneratorTemplateError : false,
                editPdfGeneratorTemplateErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                editPdfGeneratorTemplateLoading : false,
                editPdfGeneratorTemplateError : true,
                editPdfGeneratorTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deletePdfGeneratorTemplate = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            deletePdfGeneratorTemplateLoading : true,
            deletePdfGeneratorTemplateError : false,
            deletePdfGeneratorTemplateErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/pdfgenerator/template/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                deletePdfGeneratorTemplateLoading : false,
                deletePdfGeneratorTemplateError : false,
                deletePdfGeneratorTemplateErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                deletePdfGeneratorTemplateLoading : false,
                deletePdfGeneratorTemplateError : true,
                deletePdfGeneratorTemplateErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPdfGenerationPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            getPdfGenerationPackageLoading : true,
            getPdfGenerationPackageError : false,
            getPdfGenerationPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/document/getpdfgenerationpackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGenerationPackageLoading : false,
                getPdfGenerationPackageError : false,
                getPdfGenerationPackageErrorMessage : '',
                templateBuild : request['appBuild'] || {},
                documentInFocus : request['document'] || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getPdfGenerationPackageLoading : false,
                getPdfGenerationPackageError : true,
                getPdfGenerationPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const uploadPdfGeneratedFile = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            uploadPdfFileLoading : true,
            uploadPdfFileError : false,
            uploadPdfFileErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/document/uploadpdffile`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                uploadPdfFileLoading : false,
                uploadPdfFileError : false,
                uploadPdfFileErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                uploadPdfFileLoading : false,
                uploadPdfFileError : true,
                uploadPdfFileErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getGeneratedPdfDocuments = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            getGeneratedDocumentsLoading : true,
            getGeneratedDocumentsError : false,
            getGeneratedDocumentsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/document/getgenerateddocuments`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getGeneratedDocumentsLoading : false,
                getGeneratedDocumentsError : false,
                getGeneratedDocumentsErrorMessage : '',
                generatedDocuments : request.results || [],
                generatedDocumentsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getGeneratedDocumentsLoading : false,
                getGeneratedDocumentsError : true,
                getGeneratedDocumentsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getGeneratedPdfDocument = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_REDUCER',
        payload : {
            getGeneratedDocumentLoading : true,
            getGeneratedDocumentError : false,
            getGeneratedDocumentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/document/getgenerateddocument`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getGeneratedDocumentLoading : false,
                getGeneratedDocumentError : false,
                getGeneratedDocumentErrorMessage : '',
                documentInFocus : request.document || {},
                documentBase64 : request.fileBase64 || ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_REDUCER',
            payload : {
                getGeneratedDocumentLoading : false,
                getGeneratedDocumentError : true,
                getGeneratedDocumentErrorMessage : request.errorMessage || ''
            }
        })
    }
}