const SET_DRAWER_STATE = 'SET_DRAWER_STATE';

export default function(state={}, action){
    switch(action.type){
        case SET_DRAWER_STATE:
            return {
                ...state,
                [action.payload.drawerName] : action.payload.drawerState
            }
        default:
            return state
    }
}