
export function postLoginCredentials(values){
    const request = fetch(`/api/signin`, {
        method:'POST',
        headers:{
            'Accept':"application/json",
            'Content-Type':"application/json"
        },
        credentials: 'same-origin',
        body:JSON.stringify(values)
    }).then(res => {
        if(res.status === 200 && !res.error){
            return res.json();
        }
        return {hasError:true};
    })
    .catch(function(error){
        if(error){
            return {hasError:true};
        }
    })

    return{
        type:'POST_LOGIN_CREDENTIALS',
        payload: request
    }
}

export function logoutServer(){
    const request = fetch(`/api/logout`)
        .then(res=>res.json())
        .catch(err=>console.log(err))
    
    return{
        type:'LOGOUT_SERVER',
        payload:request
    }
}
