import postRequest from '../../../utils/requests/postRequest';
import config from '../../../config/config';

export const getSubscribers = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIBERS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscribers`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_SUBSCRIBERS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_SUBSCRIBERS_ERROR',
            payload : request
        });
    }
}

export const getSubscriptions = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
        payload : {
            subscriptionsLoading : true,
            subscriptionsError : false,
            subscriptionsErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscriptions`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscriptions : request.results || [],
                subscriptionsCount : request.total_count || 0,
                subscriptionsLoading : false,
                subscriptionsError : false,
                subscriptionsErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscriptionsLoading : false,
                subscriptionsError : true,
                subscriptionsErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getSubscriptionUsageLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIPTION_LOGS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getserviceusagelogs`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_SUBSCRIPTION_LOGS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_SUBSCRIPTION_LOGS_ERROR',
            payload : request
        });
    }
}

export const getSubscriberActionLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIBER_ACTION_LOGS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscriberactionlogs`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_SUBSCRIBER_ACTION_LOGS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_SUBSCRIBER_ACTION_LOGS_ERROR',
            payload : request
        });
    }
}

export const setSubscriptionMetricsReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
        payload : data
    });
}

export const resetSubscriptionMetricsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_SUBSCRIPTION_METRICS_REDUCER'
    });
}

export const getSubscriberCount = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIBER_COUNT_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscribercount`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_SUBSCRIBER_COUNT_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'GET_SUBSCRIBER_COUNT_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getDailyActiveUserCount = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_DAILY_ACTIVE_USER_COUNT_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getdailyactiveusercount`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_DAILY_ACTIVE_USER_COUNT_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'GET_DAILY_ACTIVE_USER_COUNT_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getRecurringRevenue = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_RECURRING_REVENUE_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getrecurringrevenue`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_RECURRING_REVENUE_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'GET_RECURRING_REVENUE_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getChurnData = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_CHURN_DATA_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getchurndata`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_CHURN_DATA_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'GET_CHURN_DATA_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getLTV = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_LTV_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getlifetimevalue`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_LTV_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'GET_LTV_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getSubscriptionDailyRevenue = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'SUBSCRIPTION_DAILY_REVENUE_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/payment/revenue/getdailyrevenue`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SUBSCRIPTION_DAILY_REVENUE_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'SUBSCRIPTION_DAILY_REVENUE_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getSubscriberCountChartData = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'SUBSCRIBER_COUNT_CHART_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/subscribercountchart`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SUBSCRIBER_COUNT_CHART_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'SUBSCRIBER_COUNT_CHART_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getDailyRecurringRevenues = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_LOADING',
        componentId
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getdailyrecurringrevenues`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_SUCCESS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_ERROR',
            payload : request,
            componentId
        });
    }
}

export const getSubscribedPackages = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
        payload : {
            subscribedPackagesLoading : true,
            subscribedPackagesError : false,
            subscribedPackagesErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscribedpackages`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedPackagesLoading : false,
                subscribedPackagesError : false,
                subscribedPackagesErrorMessage : '',
                subscribedPackages : request['results'] || [],
                subscribedPackagesTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedPackagesLoading : false,
                subscribedPackagesError : true,
                subscribedPackagesErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}

export const getSubscribedServices = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
        payload : {
            subscribedServicesLoading : true,
            subscribedServicesError : false,
            subscribedServicesErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getSubscribedServices`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedServicesLoading : false,
                subscribedServicesError : false,
                subscribedServicesErrorMessage : '',
                subscribedServices : request['subscribedServices'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedServicesLoading : false,
                subscribedServicesError : true,
                subscribedServicesErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}

export const getSubscribedCompanies = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
        payload : {
            subscribedCompaniesLoading : true,
            subscribedCompaniesError : false,
            subscribedCompaniesErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionmetrics/getsubscribedcompanies`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedCompaniesLoading : false,
                subscribedCompaniesError : false,
                subscribedCompaniesErrorMessage : '',
                subscribedCompanies : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_METRICS_REDUCER',
            payload : {
                subscribedCompaniesLoading : false,
                subscribedCompaniesError : true,
                subscribedCompaniesErrorMessage : request['errorMessage'] || '',
            }
        });
    }
}