import update from 'immutability-helper';

const initialState = {
    inClient : false,
    clientStateObject : {},
    clientStateOptions : [],
    functionList : [],
    cloneFunctionsList : [],
    cloneFunctionsLoading : false,
    cloneFunctionsError : false,
    cloneFunctionsErrorMessage : '',
    primaryFunctionId : '',
    functionInFocusId : '',
    functionInFocus : {},
    workflowInFocusId : '',
    workflowInFocus : {},
    showWorkflowInput : false,
    functionListMode : true,
    edited : false,
    testListMode : false,
    testListButtonHover : false,
    functionListButtonHover : false,
    runButtonHover : false,
    primaryFunctionButtonHover : false,
    functionSearchText : '',
    functionSearchMode : false,
    functionSearchSortType : 'dateDescending',
    limit : 20,
    skip : 0,
    getFunctionsLoading : false,
    getFunctionsError : false,
    listIndex : -1,
    scrollHit : 0,
    functionMainDisplay : 'workflow', //workflow, parameters
    saveButtonHover : false,
    submitIsLoading : false,
    submitError : false,
    createFunctionMode : false,
    createFunctionBottonHover : false,
    parameterOptions : [],
    variableOptions : [],
    stateOptions : [],
    mappedFunctions : {},
    functionVersions : {},
    changeVersionErrorMessage : '',
    changeVersionError : false,
    changeVersionLoading : false,
    deployFunctionLoading : false,
    deployFunctionError : false,
    deployFunctionErrorMessage : '',
    deleteFunctionLoading : false,
    deleteFunctionError : false,
    deleteFunctionErrorMessage : ''
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_FUNCTION_BUILDER':
            return {
                ...state,
                [action.field] : action.data
            }
        case 'GET_FUNCTIONS':
            return {
                ...state,
                getFunctionsLoading : true,
                getFunctionsError : false,
                scrollHit : 1
            }
        case 'SET_FUNCTIONS_ERROR':
            return {
                ...state,
                getFunctionsLoading : false,
                getFunctionsError : true,
                scrollHit : 0
            }
        case 'SET_FUNCTIONS':
            return {
                ...state,
                getFunctionsLoading : false,
                getFunctionsError : false,
                functionList : [
                    ...state.functionList, 
                    ...action.payload
                ],
                skip : state.skip + state.limit,
                scrollHit : 0
            }
        case 'SET_FUNCTION':
            return {
                ...state,
                getFunctionsLoading : false,
                getFunctionsError : false,
                functionInFocus : action.payload
            }
        case 'SET_FUNCTIONS_VERSIONS':
            return {
                ...state,
                getFunctionsLoading : false,
                getFunctionsError : false,
                functionVersions : action.payload
            }
        case 'CHANGE_FUNCTION_VERSION_SUCCESS':
            return {
                ...state,
                changeVersionError : false,
                changeVersionLoading : false,
                functionInFocus : action.payload
            }
        case 'CHANGE_FUNCTION_VERSION_LOADING':
            return {
                ...state,
                changeVersionError : false,
                changeVersionLoading : true
            }
        case 'CHANGE_FUNCTION_VERSION_ERROR':
            return {
                ...state,
                changeVersionLoading : false,
                changeVersionError : true
            }
        case 'DEPLOY_FUNCTION_VERSION_SUCCESS':
            return {
                ...state,
                deployFunctionError : false,
                deployFunctionErrorMessage : '',
                deployFunctionLoading : false
            }
        case 'DEPLOY_FUNCTION_VERSION_LOADING':
            return {
                ...state,
                deployFunctionError : false,
                deployFunctionErrorMessage : '',
                deployFunctionLoading : true
            }
        case 'DEPLOY_FUNCTION_VERSION_ERROR':
            return {
                ...state,
                deployFunctionError : true,
                deployFunctionErrorMessage : action.payload.errorMessage,
                deployFunctionLoading : false
            }
        case 'SET_FUNCTION_BUILDER_OBJECT':
            return {
                ...state,
                ...action.payload
            }
        case 'SYNC_SET_FUNCTION_BUILDER_OBJECT':
            return {
                ...state,
                ...action.payload
            }
        case 'SUBMIT_FUNCTION_START':
            return {
                ...state,
                submitIsLoading : true,
                submitError : false
            }
        case 'SUBMIT_FUNCTION_SUCCESS':
            let index;
            for (let i = 0; i < state.functionList.length; i++){
                let functionObj = state.functionList[i];
                if(
                    functionObj['functionId'] === action.payload['functionId']
                ){
                    index = i;
                    break
                }
            }
            return {
                ...state,
                submitIsLoading : false,
                submitError : false,
                functionInFocus : action.payload,
                functionInFocusId : action.payload['functionId'],
                functionList : typeof index === 'number' ? 
                update(state.functionList, {$splice : [[index, 1, action.payload]]}) 
                : 
                update(state.functionList, {$splice : [[0, 0, action.payload]]}),
                edited : false
            }
        case 'SUBMIT_FUNCTION_ERROR':
            return {
                ...state,
                submitIsLoading : false,
                submitError : true
            }
        case 'GET_MAPPED_FUNCTIONS':
            return {
                ...state,
                mappedFunctions : {
                    ...state.mappedFunctions,
                    [action.componentId] : {
                        getFunctionsLoading : true,
                        getFunctionsError : false,
                        functionList : []
                    }
                }
            }
        case 'SET_MAPPED_FUNCTIONS_ERROR':
            return {
                ...state,
                mappedFunctions : {
                    ...state.mappedFunctions,
                    [action.componentId] : {
                        getFunctionsLoading : false,
                        getFunctionsError : true,
                        functionList : []
                    }
                }
            }
        case 'SET_MAPPED_FUNCTIONS':
            return {
                ...state,
                mappedFunctions : {
                    ...state.mappedFunctions,
                    [action.componentId] : {
                        getFunctionsLoading : false,
                        getFunctionsError : false,
                        functionList : action.payload,
                        functionInFocus : state.mappedFunctions[action.payload] ? 
                        state.mappedFunctions[action.payload]['functionInFocus'] : {}
                    }
                }
            }
        case 'SET_MAPPED_FUNCTION':
            return {
                ...state,
                mappedFunctions : {
                    ...state.mappedFunctions,
                    [action.componentId] : {
                        getFunctionsLoading : false,
                        getFunctionsError : false,
                        functionInFocus : action.payload,
                        functionList : state.mappedFunctions[action.payload] ? 
                        state.mappedFunctions[action.payload]['functionList'] : []
                    }
                }
            }
        case 'CLEAR_MAPPED_FUNCTIONS':
            return {
                ...state,
                mappedFunctions : {
                    ...state.mappedFunctions,
                    [action.componentId] : {
                        getFunctionsLoading : false,
                        getFunctionsError : false,
                        functionList : [],
                        functionInFocus : {}
                    }
                }
            }
        case 'RESET_FUNCTION_BUILDER':
            return {
                ...initialState,
                mappedFunctions : state.mappedFunctions
            }
        default:
            return state;
    }
}