import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';

export const getAppHubApps = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_APP_HUB_APPS_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getapps`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_HUB_APPS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_APP_HUB_APPS_ERROR',
            payload : request
        })
    }
}

export const getAppFolders = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_APP_FOLDERS_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/appfolder/getfolders`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_FOLDERS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_APP_FOLDERS_ERROR',
            payload : request
        });
    }
}

export const getAppFolder = (id='') => async dispatch => {
    dispatch({
        type : 'GET_APP_FOLDER_LOADING'
    });
    let request = await getRequest(`${config.devServerProxy}/api/appbuilder/appfolder/getfolder/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_APP_FOLDER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_APP_FOLDER_ERROR',
            payload : request
        });
    }
}

export const deleteAppFolder = (id='') => async dispatch => {
    dispatch({
        type : 'DELETE_APP_FOLDER_LOADING'
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/appbuilder/appfolder/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'DELETE_APP_FOLDER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'DELETE_APP_FOLDER_ERROR',
            payload : request
        });
    }
}

export const checkAppFolderNameAvailability = (data={}) => async dispatch => {
    dispatch({
        type : 'CHECK_APP_FOLDER_NAME_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/appfolder/foldernamecheck`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'CHECK_APP_FOLDER_NAME_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'CHECK_APP_FOLDER_NAME_ERROR',
            payload : request
        });
    }
}

export const saveAppFolder = (data={}) => async dispatch => {
    dispatch({
        type : 'SAVE_APP_FOLDER_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/appfolder/${data['_id'] ? 'edit' : 'create'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SAVE_APP_FOLDER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SAVE_APP_FOLDER_ERROR',
            payload : request
        })
    }
}

export const setAppHubReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_HUB_REDUCER',
        payload : data
    });
}

export const resetAppHubReducer = () => dispatch => {
    dispatch({
        type : 'RESET_APP_HUB_REDUCER'
    });
}

export const appFieldNameAvailability = (data={}) => async dispatch => {
    dispatch({
        type : 'FIELD_NAME_CHECK_LOADING',
        meta : data
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/fieldnamecheck`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'FIELD_NAME_CHECK_SUCCESS',
            payload : request,
            meta : data
        });
    }else{
        dispatch({
            type : 'FIELD_NAME_CHECK_ERROR',
            payload : request,
            meta : data
        });
    }
}

export const createInhouzApp = (data={}) => async dispatch => {
    dispatch({
        type : 'CREATE_INHOUZ_APP_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/createapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'CREATE_INHOUZ_APP_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'CREATE_INHOUZ_APP_ERROR',
            payload : request
        })
    }
}

export const createAppFromClone = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_HUB_REDUCER',
        payload : {
            cloneAppLoading : true,
            cloneAppError : false,
            cloneAppErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/cloneapp`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_HUB_REDUCER',
            payload : {
                cloneAppLoading : false,
                cloneAppError : false,
                cloneAppErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_HUB_REDUCER',
            payload : {
                cloneAppLoading : false,
                cloneAppError : true,
                cloneAppErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getInhouzAppByName = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_APP_HUB_REDUCER',
        payload : {
            getAppByNameLoading : true,
            getAppByNameError : false,
            getAppByNameErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/appbuilder/getappsbyname`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_APP_HUB_REDUCER',
            payload : {
                getAppByNameLoading : false,
                getAppByNameError : false,
                getAppByNameErrorMessage : '',
                getAppByNameApps : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_APP_HUB_REDUCER',
            payload : {
                getAppByNameLoading : false,
                getAppByNameError : true,
                getAppByNameErrorMessage : request.errorMessage || ''
            }
        });
    }
}