import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';

export const setPaymentCategoryReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : data
    });
}

export const resetPaymentCategoryReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_CATEGORY_REDUCER'
    });
}

export const getPaymentCategories = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : {
            getProductCategorysLoading : true,
            getProductCategorysError : false,
            getProductCategorysErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/category/getcategories`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                getProductCategorysLoading : false,
                getProductCategorysError : false,
                getProductCategorysErrorMessage : '',
                productCategories : request.results || [],
                productCategoryTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                getProductCategorysLoading : false,
                getProductCategorysError : true,
                getProductCategorysErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : {
            createProductCategoryLoading : true,
            createProductCategoryError : false,
            createProductCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/category/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                createProductCategoryLoading : false,
                createProductCategoryError : false,
                createProductCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                createProductCategoryLoading : false,
                createProductCategoryError : true,
                createProductCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updatePaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : {
            updateProductCategoryLoading : true,
            updateProductCategoryError : false,
            updateProductCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/category/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                updateProductCategoryLoading : false,
                updateProductCategoryError : false,
                updateProductCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                updateProductCategoryLoading : false,
                updateProductCategoryError : true,
                updateProductCategoryErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deletePaymentCategory = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : {
            deleteProductCategoryLoading : true,
            deleteProductCategoryError : false,
            deleteProductCategoryErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/inhouzcommerce/category/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                deleteProductCategoryLoading : false,
                deleteProductCategoryError : false,
                deleteProductCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                deleteProductCategoryLoading : false,
                deleteProductCategoryError : true,
                deleteProductCategoryErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentCategory = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_CATEGORY_REDUCER',
        payload : {
            getProductCategoryLoading : true,
            getProductCategoryError : false,
            getProductCategoryErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/inhouzcommerce/category/get/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                getProductCategoryLoading : false,
                getProductCategoryError : false,
                getProductCategoryErrorMessage : '',
                productCategoryInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_CATEGORY_REDUCER',
            payload : {
                getProductCategoryLoading : false,
                getProductCategoryError : true,
                getProductCategoryErrorMessage : request.errorMessage || ''
            }
        });
    }
}