import postRequest from '../../../utils/requests/postRequest';
import config from '../../../config/config';
import formDataPostRequest from '../../../utils/requests/formDataPostRequest';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';

export const getSubscriptionServices = (data={}, pagination=true) => async dispatch => {
    dispatch({
        type : 'SUBSCRIPTION_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getsubscriptionservices`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICES_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_ERROR',
            payload : request
        });
    }
}

export const getConnectedSubscriptionServices = (data={}, fromAccountSettings=false) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            getConnectedServicesLoading : true,
            getConnectedServicesError : false,
            getConnectedServicesErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/${fromAccountSettings ? 'getconnectedsubscriptionservices' : 'getsubscriptionservices'}`,
        'POST',
        data,
        {},
        fromAccountSettings ? 'x-react' : ''
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getConnectedServicesLoading : false,
                getConnectedServicesError : false,
                getConnectedServicesErrorMessage : '',
                connectedSubscriptionServices : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getConnectedServicesLoading : false,
                getConnectedServicesError : true,
                getConnectedServicesErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const getSubscriptionService = (data={}) => async dispatch => {
    dispatch({
        type : 'SUBSCRIPTION_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getsubscription`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_ERROR',
            payload : request
        });
    }
}

export const saveSubscriptionService = (data={}) => async dispatch => {
    dispatch({
        type : 'SUBSCRIPTION_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/${data['_id'] ? 'edit' : 'create'}`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_ERROR',
            payload : request
        });
    }
}

export const setSubscriptionReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : data
    });
}

export const resetSubscriptionReducer = () => dispatch => {
    dispatch({
        type : 'RESET_SUBSCRIPTIONS_REDUCER'
    })
}

export const getSubscriptionServiceVersions = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIPTION_SERVICE_TRACKER'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getsubscriptionserviceversions`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_TRACKER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_TRACKER_ERROR',
            payload : request
        });
    }
}

export const changeSubscriptionServiceVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_SUBSCRIPTION_SERVICE_TRACKER'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/setversion`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'CHANGE_SUBSCRIPTION_SERVICE_VERSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_TRACKER_ERROR',
            payload : request
        });
    }
}

export const deploySubscriptionServiceVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'SUBSCRIPTION_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/deploy`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'DEPLOY_SUBSCRIPTION_SERVICE_VERSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SERVICE_ERROR',
            payload : request
        });
    }
}

export const getSignupSubscriptionService = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            signupSubscriptionServiceLoading : true,
            signupSubscriptionServiceError : false,
            signupSubscriptionServiceErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/signup/getsubscriptionservice`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupSubscriptionServiceLoading : false,
                signupSubscriptionServiceError : false,
                signupSubscriptionServiceErrorMessage : '',
                signupSubscriptionService : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupSubscriptionServiceLoading : false,
                signupSubscriptionServiceError : true,
                signupSubscriptionServiceErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const processSubscriptionUserCSV = (data) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            signupCSVUsersLoading : true,
            signupCSVUsersError : false,
            signupCSVUsersErrorMessage : '',
        }
    });
    let request = await formDataPostRequest(
        `${config.devServerProxy}/api/fileoperations/subcriptionusercsv`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupCSVUsersLoading : false,
                signupCSVUsersError : false,
                signupCSVUsersErrorMessage : '',
                processedSignupCSVUsers : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupCSVUsersLoading : false,
                signupCSVUsersError : true,
                signupCSVUsersErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const processSubscriptionSignup = (data={}, expansion=false) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            signupLoading : true,
            signupError : false,
            signupErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscribe/${expansion ? 'expansionsignup' : 'signup'}`,
        'POST',
        data,
        {},
        expansion ? 'x-react' : ''
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupLoading : false,
                signupError : false,
                signupErrorMessage : '',
                signupSuccessData : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                signupLoading : false,
                signupError : true,
                signupErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const subscriptionSetupIntent = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            getSetupIntentLoading : true,
            getSetupIntentError : false,
            getSetupIntentErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzpay/stripe/createsetupintent`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : false,
                getSetupIntentErrorMessage : '',
                stripeSetupIntentId : request['setupIntentId'],
                stripeSetupIntentSecret : encryptDecrypt(request['encyptedKey'], false),
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : true,
                getSetupIntentErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const createSubscriptionMigration = (data={}, fromAccountSettings=false) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            createSubscriptionMigrationLoading : true,
            createSubscriptionMigrationError : false,
            createSubscriptionMigrationErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/servicemigration/${fromAccountSettings ? 'subscriber' : 'assetowner'}/create`,
        'POST',
        data,
        {},
        fromAccountSettings ? 'x-react' : ''
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                createSubscriptionMigrationLoading : false,
                createSubscriptionMigrationError : false,
                createSubscriptionMigrationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                createSubscriptionMigrationLoading : false,
                createSubscriptionMigrationError : true,
                createSubscriptionMigrationErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const getSubscriptionUserAllowList = (data) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            getAllowListLoading : true,
            getAllowListError : false,
            getAllowListErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getuserrestrictionlist`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getAllowListLoading : false,
                getAllowListError : false,
                getAllowListErrorMessage : '',
                userAllowList : request['results'],
                userAllowListTotalCount : request['total_count']
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getAllowListLoading : false,
                getAllowListError : true,
                getAllowListErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const getSubscriptionUserBlockList = (data) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            getBlockListLoading : true,
            getBlockListError : false,
            getBlockListErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getuserrestrictionlist`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getBlockListLoading : false,
                getBlockListError : false,
                getBlockListErrorMessage : '',
                userBlockList : request['results'],
                userBlockListTotalCount : request['total_count']
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getBlockListLoading : false,
                getBlockListError : true,
                getBlockListErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const addSubscriptionUserRestriction = (data, restrictionType) => async dispatch => {
    let loadingField = restrictionType === 'userAllowList' ? 'addAllowListLoading' : 'addBlockListLoading';
    let errorField = restrictionType === 'userAllowList' ? 'addAllowListError' : 'addBlockListError';
    let errorMessageField = restrictionType === 'userAllowList' ? 'addAllowListErrorMessage' : 'addBlockListErrorMessage';
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            [loadingField] : true,
            [errorField] : false,
            [errorMessageField] : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/manageuserrestriction`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                [loadingField] : false,
                [errorField] : false,
                [errorMessageField] : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                [loadingField] : false,
                [errorField] : true,
                [errorMessageField] : request.errorMessage || '',
            }
        });
    }
}

export const deleteSubscriptionUserRestriction = (data, restrictionType) => async dispatch => {
    let loadingField = restrictionType === 'userAllowList' ? 'deleteAllowListLoading' : 'deleteBlockListLoading';
    let errorField = restrictionType === 'userAllowList' ? 'deleteAllowListError' : 'deleteBlockListError';
    let errorMessageField = restrictionType === 'userAllowList' ? 'deleteAllowListErrorMessage' : 'deleteBlockListErrorMessage';
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            [loadingField] : true,
            [errorField] : false,
            [errorMessageField] : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/manageuserrestriction`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                [loadingField] : false,
                [errorField] : false,
                [errorMessageField] : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                [loadingField] : false,
                [errorField] : true,
                [errorMessageField] : request.errorMessage || '',
            }
        });
    }
}

export const getSubscriptionServiceList = (data) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            getSubscriptionServiceListLoading : true,
            getSubscriptionServiceListError : false,
            getSubscriptionServiceListErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getsubscriptionservices`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getSubscriptionServiceListLoading : false,
                getSubscriptionServiceListError : false,
                getSubscriptionServiceListErrorMessage : '',
                subscriptionServiceList : request['results'],
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                getSubscriptionServiceListLoading : false,
                getSubscriptionServiceListError : true,
                getSubscriptionServiceListErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const cloneSubscriptionService = (data) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_REDUCER',
        payload : {
            cloneSubscriptionServiceLoading : true,
            cloneSubscriptionServiceError : false,
            cloneSubscriptionServiceErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/clone`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                cloneSubscriptionServiceLoading : false,
                cloneSubscriptionServiceError : false,
                cloneSubscriptionServiceErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_REDUCER',
            payload : {
                cloneSubscriptionServiceLoading : false,
                cloneSubscriptionServiceError : true,
                cloneSubscriptionServiceErrorMessage : request.errorMessage || '',
            }
        });
    }
}