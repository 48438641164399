const initialState = {
    metricsQuery : {
        dateRangeType : 'month',
        startDate : 0,
        endDate : 0,
        templateId : '',
        environment : 'production'
    },
    topSectionLoading : false,
    topSectionError : false,
    topSectionErrorMessage : '',
    topSectionMetrics : {},
    topSectionDeltaMetrics : {},
    documentTimelineMetricsLoading : false,
    documentTimelineMetricsError : false,
    documentTimelineMetricsErrorMessage : '',
    documentTimelineMetrics : {}
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_PDF_GENERATOR_METRICS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PDF_GENERATOR_METRICS_REDUCER':
            return initialState;
        default:
            return state;
    }
}