import dotNotationObjectUpdate from "../../utils/data/dotNotationObjectUpdate";

const initialState = {
    viewInFocus : '',
    templateInFocus : {},
    templates : [],
    appElements : [],
    appPages : [],
    templateTotalCount : 0,
    getPdfGeneratorTemplatesLoading : false,
    getPdfGeneratorTemplatesError : false,
    getPdfGeneratorTemplatesErrorMessage : '',
    createTemplateLoading : false,
    createTemplateError : false,
    createTemplateErrorMessage : '',
    uploadedFiles : [],
    getPdfGeneratorTemplateLoading : false,
    getPdfGeneratorTemplateError : false,
    getPdfGeneratorTemplateErrorMessage : '',
    editPdfGeneratorTemplateLoading : false,
    editPdfGeneratorTemplateError : false,
    editPdfGeneratorTemplateErrorMessage : '',
    deletePdfGeneratorTemplateLoading : false,
    deletePdfGeneratorTemplateError : false,
    deletePdfGeneratorTemplateErrorMessage : '',
    templateBuild : {},
    getPdfGenerationPackageLoading : false,
    getPdfGenerationPackageError : false,
    getPdfGenerationPackageErrorMessage : '',
    uploadPdfFileLoading : false,
    uploadPdfFileError : false,
    uploadPdfFileErrorMessage : '',
    getGeneratedDocumentsLoading : false,
    getGeneratedDocumentsError : false,
    getGeneratedDocumentsErrorMessage : '',
    generatedDocuments : [],
    generatedDocumentsTotalCount : 0,
    getGeneratedDocumentLoading : false,
    getGeneratedDocumentError : false,
    getGeneratedDocumentErrorMessage : '',
    documentInFocus : {},
    documentBase64 : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PDF_GENERATOR_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PDF_GENERATOR_REDUCER':
            return initialState;
        default:
            return state;
    }
}