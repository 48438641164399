import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setInhouzProjectCategoryReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : data
    });
}

export const resetInhouzProjectCategoryReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_PROJECT_CATEGORY_REDUCER'
    });
}

export const createInhouzProjectCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            createProjectCategoryLoading : true,
            createProjectCategoryError : false,
            createProjectCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                createProjectCategoryLoading : false,
                createProjectCategoryError : false,
                createProjectCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                createProjectCategoryLoading : false,
                createProjectCategoryError : true,
                createProjectCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editInhouzProjectCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            editProjectCategoryLoading : true,
            editProjectCategoryError : false,
            editProjectCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                editProjectCategoryLoading : false,
                editProjectCategoryError : false,
                editProjectCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                editProjectCategoryLoading : false,
                editProjectCategoryError : true,
                editProjectCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzProjectCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            getProjectCategoryLoading : true,
            getProjectCategoryError : false,
            getProjectCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/getprojectcategory`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoryLoading : false,
                getProjectCategoryError : false,
                getProjectCategoryErrorMessage : '',
                projectCategoryInFocus : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoryLoading : false,
                getProjectCategoryError : true,
                getProjectCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzProjectCategories = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            getProjectCategoriesLoading : true,
            getProjectCategoriesError : false,
            getProjectCategoriesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/getprojectcategories`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoriesLoading : false,
                getProjectCategoriesError : false,
                getProjectCategoriesErrorMessage : '',
                projectCategories : request['results'] || [],
                projectCategoryTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoriesLoading : false,
                getProjectCategoriesError : true,
                getProjectCategoriesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteInhouzProjectCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            deleteProjectCategoryLoading : true,
            deleteProjectCategoryError : false,
            deleteProjectCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                deleteProjectCategoryLoading : false,
                deleteProjectCategoryError : false,
                deleteProjectCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                deleteProjectCategoryLoading : false,
                deleteProjectCategoryError : true,
                deleteProjectCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzProjectCategoryStatusList = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
        payload : {
            getProjectCategoryStatusListLoading : true,
            getProjectCategoryStatusListError : false,
            getProjectCategoryStatusListErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/projectcategory/getstatuslist`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoryStatusListLoading : false,
                getProjectCategoryStatusListError : false,
                getProjectCategoryStatusListErrorMessage : '',
                projectCategoryStatusList : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER',
            payload : {
                getProjectCategoryStatusListLoading : false,
                getProjectCategoryStatusListError : true,
                getProjectCategoryStatusListErrorMessage : request.errorMessage || ''
            }
        })
    }
}