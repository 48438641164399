import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const getApiList = (data={}, pagination=false) => async dispatch => {
    dispatch({
        type : 'GET_FLEX_APIS',
        pagination
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getflexapis`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_FLEX_APIS_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'SET_FLEX_APIS_ERROR',
            payload : request
        })
    }
}

export const getMappedApiList = (data={}, mapId='') => async dispatch => {
    dispatch({
        type : 'GET_MAPPED_FLEX_APIS',
        mapId
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getflexapis`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MAPPED_FLEX_APIS_SUCCESS',
            payload : request,
            mapId
        });
    }else{
        dispatch({
            type : 'SET_MAPPED_FLEX_APIS_ERROR',
            payload : request
        })
    }
}

export const getFlexApi = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_FLEX_API'
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getflexapi`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_ERROR',
            payload : request
        })
    }
}

export const setFlexApiReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : data
    });
}

export const saveFlexApi = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_FLEX_API'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/flexapi/${data['flexApiId'] ? 'edit' : 'create'}`, 
        'POST', 
        {
            flexApiObject : data
        }
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_ERROR',
            payload : request
        })
    }
}

export const getApiVersionTracker = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_API_VERSION_TRACKER'
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getapitracker`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_API_VERSION_TRACKER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_API_VERSION_TRACKER_ERROR',
            payload : request
        })
    }
}

export const changeFlexApiVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_API_VERSION_TRACKER'
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/setflexapiversion`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'CHANGE_API_VERSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_API_VERSION_TRACKER_ERROR',
            payload : request
        })
    }
}

export const deployApiVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            apiDeploymentLoading : true,
            apiDeploymentError : false,
            apiDeploymentErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/deploy`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDeploymentLoading : false,
                apiDeploymentError : false,
                apiDeploymentErrorMessage : '',
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDeploymentLoading : false,
                apiDeploymentError : true,
                apiDeploymentErrorMessage : request.errorMessage || '',
            }
        })
    }
}


export const mergeApiDeployment = (data={}, environment='') => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            apiMergeLoading : true,
            apiMergeError : false,
            apiMergeErrorMessage : '',
            activeMergeFromEnvironment : environment
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/mergedeployments`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiMergeLoading : false,
                apiMergeError : false,
                apiMergeErrorMessage : '',
                activeMergeFromEnvironment : environment
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiMergeLoading : false,
                apiMergeError : true,
                apiMergeErrorMessage : request.errorMessage || '',
                activeMergeFromEnvironment : environment
            }
        })
    }
}

export const getApiDocumentations = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            apiDocumentationsLoading : true,
            apiDocumentationsError : false,
            apiDocumentationsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/documentationlist`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDocumentationsLoading : false,
                apiDocumentationsError : false,
                apiDocumentationsErrorMessage : '',
                apiDocumentationList : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDocumentationsLoading : false,
                apiDocumentationsError : true,
                apiDocumentationsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getApiDocumentation = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            apiDocumentationLoading : true,
            apiDocumentationError : false,
            apiDocumentationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/documentation`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDocumentationLoading : false,
                apiDocumentationError : false,
                apiDocumentationErrorMessage : '',
                apiDocumentation : request
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiDocumentationLoading : false,
                apiDocumentationError : true,
                apiDocumentationErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getDynamicSeoApis = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            getDynamicSeoApisLoading : true,
            getDynamicSeoApisError : false,
            getDynamicSeoApisErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getdynamicseoapis`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                getDynamicSeoApisLoading : false,
                getDynamicSeoApisError : false,
                getDynamicSeoApisErrorMessage : '',
                dynamicSeoApis : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                getDynamicSeoApisLoading : false,
                getDynamicSeoApisError : true,
                getDynamicSeoApisErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const resetFlexApiReducer = () => dispatch => {
    dispatch({
        type : 'RESET_FLEX_API_REDUCER'
    })
}

export const undeployApiEndpoint = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            endpointUndeploymentLoading : true,
            endpointUndeploymentError : false,
            endpointUndeploymentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/undeploy/endpoint`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                endpointUndeploymentLoading : false,
                endpointUndeploymentError : false,
                endpointUndeploymentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                endpointUndeploymentLoading : false,
                endpointUndeploymentError : true,
                endpointUndeploymentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const undeployApiEnvironment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            apiEnvironmentUndeployLoading : true,
            apiEnvironmentUndeployError : false,
            apiEnvironmentUndeployErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/undeploy/environment`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiEnvironmentUndeployLoading : false,
                apiEnvironmentUndeployError : false,
                apiEnvironmentUndeployErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                apiEnvironmentUndeployLoading : false,
                apiEnvironmentUndeployError : true,
                apiEnvironmentUndeployErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteFlexApi = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            deleteApiLoading : true,
            deleteApiError : false,
            deleteApiErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/delete`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                deleteApiLoading : false,
                deleteApiError : false,
                deleteApiErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                deleteApiLoading : false,
                deleteApiError : true,
                deleteApiErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getDeployedApiEndpoints = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FLEX_API_REDUCER',
        payload : {
            deployedEndpointsLoading : true,
            deployedEndpointsError : false,
            deployedEndpointsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/flexapi/getdeployedendpoints`, 'POST', data);
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                deployedEndpointsLoading : false,
                deployedEndpointsError : false,
                deployedEndpointsErrorMessage : '',
                deployedApiEndpoints : request.results || []
            }
        });
    }else{
        dispatch({
            type : 'SET_FLEX_API_REDUCER',
            payload : {
                deployedEndpointsLoading : false,
                deployedEndpointsError : true,
                deployedEndpointsErrorMessage : request.errorMessage || ''
            }
        })
    }
}