import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import formDataPostRequest from '../../utils/requests/formDataPostRequest';
import config from '../../config/config';

export const setUserManagementReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_USER_MANAGEMENT_REDUCER',
        payload : data
    });
}

export const editUserUM = (data) => async dispatch => {
    let newUser = data['_id'] ? false : true;
    if(!newUser && Object.keys().includes('notifyNewUser')){
        delete data['notifyNewUser'];
    }
    dispatch({
        type : 'EDIT_USER_UM_LOADING'
    });
    let request = await postRequest(
        newUser ?
        `${config.devServerProxy}/api/users/addUsers`
        :
        `${config.devServerProxy}/api/users/edituser`, 
        'POST', 
        newUser ? {users : [data]} : data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'EDIT_USER_UM_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'EDIT_USER_UM_ERROR',
            payload : request
        })
    }
}

export const userCsvUpload = (data) => async dispatch => {
    dispatch({
        type : 'EDIT_USER_UM_LOADING'
    });
    let request = await formDataPostRequest(
        `${config.devServerProxy}/api/fileoperations/adduserscsv`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'EDIT_USER_UM_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'EDIT_USER_UM_ERROR',
            payload : request
        })
    }
}

export const getCompanyUserSessions = (skip=0, limit=0) => async dispatch => {
    dispatch({
        type : 'COMPANY_USER_SESSIONS_LOADING'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/users/getsessions/${skip}/${limit}`
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_COMPANY_USER_SESSIONS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'COMPANY_USER_SESSIONS_ERROR',
            payload : request
        })
    }
}

export const endCompanyUserSession = (data) => async dispatch => {
    dispatch({
        type : 'COMPANY_USER_SESSIONS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/users/endsession`,
        'POST',
        data 
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'END_COMPANY_USER_SESSIONS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'COMPANY_USER_SESSIONS_ERROR',
            payload : request
        })
    }
}

export const deactivateUserAccount = (data) => async dispatch => {
    dispatch({
        type : 'SET_USER_MANAGEMENT_REDUCER',
        payload : {
            deactivateUserLoading : true,
            deactivateUserError : false,
            deactivateUserErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/accounttermination/useraccounttermination`,
        'POST',
        data 
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_USER_MANAGEMENT_REDUCER',
            payload : {
                deactivateUserLoading : false,
                deactivateUserError : false,
                deactivateUserErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_USER_MANAGEMENT_REDUCER',
            payload : {
                deactivateUserLoading : false,
                deactivateUserError : true,
                deactivateUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}