const initialState = {
    productCategories : [],
    productCategoryTotalCount : 0,
    productCategoryInFocus : {},
    getProductCategoryLoading : false,
    getProductCategoryError : false,
    getProductCategoryErrorMessage : '',
    getProductCategorysLoading : false,
    getProductCategorysError : false,
    getProductCategorysErrorMessage : '',
    createProductCategoryLoading : false,
    createProductCategoryError : false,
    createProductCategoryErrorMessage : '',
    updateProductCategoryLoading : false,
    updateProductCategoryError : false,
    updateProductCategoryErrorMessage : '',
    deleteProductCategoryLoading : false,
    deleteProductCategoryError : false,
    deleteProductCategoryErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_CATEGORY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_CATEGORY_REDUCER':
            return initialState;
        default:
            return state;
    }
}