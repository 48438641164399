const initialState = {
    createModuleData : {},
    createModuleIsLoading : false,
    createModuleError : false,
    moduleData : {},
    moduleDataError : false,
    moduleDataIsLoading : false,
    editModuleLoading : false,
    editModuleError : false,
    collectionNameExists : false,
    collectionNameCheckLoading : false,
    collectionNameCheckError : false,
    moduleNameExists : false,
    moduleNameCheckLoading : false,
    moduleNameCheckError : false
}

export default function (state=initialState, action){
    switch(action.type){
        case 'CREATE_FLEX_MODULE_LOADING':
            return{...state, createModuleIsLoading : true}
        case 'CREATE_FLEX_MODULE_DATA':
            return {
                ...state,
                createModuleIsLoading : false,
                createModuleError : false,
                createModuleData : action.payload
            }
        case 'CREATE_FLEX_MODULE_ERROR':
            return {
                ...state,
                createModuleIsLoading : false,
                createModuleError : true,
                createModuleData : {},
            }
        case 'RESET_FLEX_MODULE':
            return {
                ...state,
                createModuleData : {},
                createModuleIsLoading : false,
                createModuleError : false
            }
        case 'GET_MODULE_LOADING':
            return {
                ...state,
                moduleDataIsLoading : true,
                moduleDataError : false
            }
        case 'GET_MODULE_ERROR':
            return {
                ...state,
                moduleDataIsLoading : false,
                moduleDataError : true
            }
        case 'GET_MODULE_DATA':
            return {
                ...state,
                moduleDataIsLoading : false,
                moduleDataError : false,
                moduleData : action.payload,
                editModuleLoading : false,
                editModuleError : false,
            }
        case 'EDIT_MODULE_LOADING':
            return {...state, editModuleLoading : true}
        case 'EDIT_MODULE_ERROR':
            return {
                ...state,
                editModuleError : true,
                editModuleLoading : false
            }
        case 'COLLECTION_NAME_EXISTS_CHECK_LOADING':
            return {
                ...state,
                collectionNameCheckLoading : true
            }
        case 'COLLECTION_NAME_EXISTS_CHECK_DATA':
            return {
                ...state,
                collectionNameCheckLoading : false,
                collectionNameCheckError : false,
                collectionNameExists : action.payload.exists
            }
        case 'COLLECTION_NAME_EXISTS_CHECK_ERROR':
            return {
                ...state,
                collectionNameCheckError : true,
                collectionNameCheckLoading : false
            }
        case 'MODULE_NAME_EXISTS_CHECK_LOADING':
            return {
                ...state,
                moduleNameCheckLoading : true
            }
        case 'MODULE_NAME_EXISTS_CHECK_ERROR':
            return {
                ...state,
                moduleNameCheckLoading : false,
                moduleNameCheckError : true
            }
        case 'MODULE_NAME_EXISTS_CHECK_DATA':
            return {
                ...state,
                moduleNameCheckLoading : false,
                moduleNameCheckError : false,
                moduleNameExists : action.payload.exists
            }
        default:
            return state;
    }
}