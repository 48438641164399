import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import formDataPostRequest from '../../utils/requests/formDataPostRequest';

export const setPaymentProductReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : data
    });
}

export const resetPaymentProductReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_PRODUCT_REDUCER'
    });
}

export const getPaymentProducts = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            getProductsLoading : true,
            getProductsError : false,
            getProductsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/product/getproducts`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                getProductsLoading : false,
                getProductsError : false,
                getProductsErrorMessage : '',
                products : request.results || [],
                productTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                getProductsLoading : false,
                getProductsError : true,
                getProductsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentProduct = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            createProductLoading : true,
            createProductError : false,
            createProductErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/product/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                createProductLoading : false,
                createProductError : false,
                createProductErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                createProductLoading : false,
                createProductError : true,
                createProductErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updatePaymentProduct = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            updateProductLoading : true,
            updateProductError : false,
            updateProductErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzcommerce/product/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                updateProductLoading : false,
                updateProductError : false,
                updateProductErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                updateProductLoading : false,
                updateProductError : true,
                updateProductErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deletePaymentProduct = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            deleteProductLoading : true,
            deleteProductError : false,
            deleteProductErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/inhouzcommerce/product/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                deleteProductLoading : false,
                deleteProductError : false,
                deleteProductErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                deleteProductLoading : false,
                deleteProductError : true,
                deleteProductErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentProduct = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            getProductLoading : true,
            getProductError : false,
            getProductErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/inhouzcommerce/product/get/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                getProductLoading : false,
                getProductError : false,
                getProductErrorMessage : '',
                productInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                getProductLoading : false,
                getProductError : true,
                getProductErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const paymentProductCsvUpload = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_PRODUCT_REDUCER',
        payload : {
            bulkProductUploadLoading : true,
            bulkProductUploadError : false,
            bulkProductUploadErrorMessage : ''
        }
    });
    let request = await formDataPostRequest(`${config.devServerProxy}/api/fileoperations/bulkproductupload`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                bulkProductUploadLoading : false,
                bulkProductUploadError : false,
                bulkProductUploadErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_PRODUCT_REDUCER',
            payload : {
                bulkProductUploadLoading : false,
                bulkProductUploadError : true,
                bulkProductUploadErrorMessage : request.errorMessage || ''
            }
        });
    }
}