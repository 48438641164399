import postRequest from '../../../utils/requests/postRequest';
import config from '../../../config/config';

export const setSubscriptionSettingsReducer = (data) => dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
        payload : data
    });
}

export const resetSubscriptionSettingsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_SUBSCRIPTION_SETTINGS_REDUCER'
    });
}

export const updateSubscriberProfile = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
        payload : {
            updateSubscriberProfileLoading : true,
            updateSubscriberProfileError : false,
            updateSubscriberProfileErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionsettings/updateprofile`,
        'POST',
        data,
        {},
        'x-react'
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
            payload : {
                updateSubscriberProfileLoading : false,
                updateSubscriberProfileError : false,
                updateSubscriberProfileErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
            payload : {
                updateSubscriberProfileLoading : false,
                updateSubscriberProfileError : true,
                updateSubscriberProfileErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}

export const getSubscriberProfile = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
        payload : {
            getSubscriberProfileLoading : true,
            getSubscriberProfileError : false,
            getSubscriberProfileErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionsettings/getsubscriberprofile`,
        'POST',
        data,
        {},
        'x-react'
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
            payload : {
                getSubscriberProfileLoading : false,
                getSubscriberProfileError : false,
                getSubscriberProfileErrorMessage : '',
                subscriberProfileInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_SETTINGS_REDUCER',
            payload : {
                getSubscriberProfileLoading : false,
                getSubscriberProfileError : true,
                getSubscriberProfileErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}