import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const getUsersRolesAndPermissions = (data={}, pagination=false) => async dispatch => {
    dispatch({
        type : 'USER_ROLES_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/userroles/getusersroles`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_USERS_ROLES_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'USER_ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const saveUsersRoles = (data={}) => async dispatch => {
    dispatch({
        type : 'USER_ROLES_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/userroles/updateuserroles`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_USER_ROLES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'USER_ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const getUserRolesAndPermissions = (id='') => async dispatch => {
    dispatch({
        type : 'USER_ROLES_REQUEST_SENT'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/userroles/getuserroles`
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_USER_ROLES_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'USER_ROLES_REQUEST_ERROR',
            payload : request
        })
    }
}

export const setUserRoleReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_USER_ROLE_REDUCER',
        payload : data
    });
}