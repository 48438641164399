import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const getReferralCodeUsageLogs = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_REFERRAL_CODE_REDUCER',
        payload : {
            getReferralUsageLogsLoading : true,
            getReferralUsageLogsError : false,
            getReferralUsageLogsErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsubscription/getdiscountcodeusagelog`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_REFERRAL_CODE_REDUCER',
            payload : {
                getReferralUsageLogsLoading : false,
                getReferralUsageLogsError : false,
                getReferralUsageLogsErrorMessage : '',
                referralCodeUsageLogs : request['results'] || [],
                referralCodeUsageLogTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_REFERRAL_CODE_REDUCER',
            payload : {
                getReferralUsageLogsLoading : false,
                getReferralUsageLogsError : true,
                getReferralUsageLogsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const resetReferralCodeReducer = () => dispatch => {
    dispatch({
        type : 'RESET_REFERRAL_CODE_REDUCER'
    });
}

export const setReferralCodeReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_REFERRAL_CODE_REDUCER',
        payload : data
    });
}