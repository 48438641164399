export default function(state={}, action){
    if(action.componentId && !state[action.componentId]){
        state[action.componentId] = {
            loading : false,
            clientToken : '',
            hasError : false,
            errorMessage : '',
            transactionResponse : {}
        }
    }
    switch(action.type){
        case 'BRAINTREE_LOADING':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : true,
                    hasError : false,
                    errorMessage : ''
                }
            }
        case 'BRAINTREE_ERROR':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : false,
                    hasError : true,
                    errorMessage : (action.payload && action.payload.errorMessage) || ''
                }
            }
        case 'GET_BRAINTREE_CLIENT_TOKEN_SUCCESS':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    loading : false,
                    hasError : false,
                    errorMessage : '',
                    clientToken : action.payload['clientToken']
                }
            }
        case 'GET_BRAINTREE_CLIENT_TRANSACTION_SUCCESS':
            return {
                ...state,
                [action.componentId] : {
                    loading : false,
                    hasError : false,
                    errorMessage : '',
                    transactionResponse : action.payload
                }
            }
        case 'SET_BRAINTREE_REDUCER':
            return {
                ...state,
                [action.componentId] : {
                    ...state[action.componentId],
                    ...action.payload
                }
            }
        default:
            return state;
    }
}