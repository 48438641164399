import getRequest from '../../utils/requests/getRequest';
import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setSystemUserReducer = (data={}) => dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : data
    });
}

export const resetSystemUserReducer = (data={}) => dispatch => {
    dispatch ({
        type : 'RESET_SYSTEM_USER_REDUCER',
        payload : data
    });
}

export const getSystemUsers = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            getSystemUsersLoading : true,
            getSystemUsersError : false,
            getSystemUsersErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/getsystemusers`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                total_system_user_count : request.total_count,
                systemUsers : request.results,
                getSystemUsersLoading : false,
                getSystemUsersError : false,
                getSystemUsersErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getSystemUsersLoading : false,
                getSystemUsersError : true,
                getSystemUsersErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getSystemUser = (id='') => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            getSystemUserLoading : true,
            getSystemUserError : false,
            getSystemUserErrorMessage : ''
        }
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/systemuser/getsystemuser/${id}`
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                systemUser : request,
                getSystemUserLoading : false,
                getSystemUserError : false,
                getSystemUserErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getSystemUserLoading : false,
                getSystemUserError : true,
                getSystemUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteSystemUser = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            deleteSystemUserLoading : true,
            deleteSystemUserError : false,
            deleteSystemUserErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/delete`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                deleteSystemUserLoading : false,
                deleteSystemUserError : false,
                deleteSystemUserErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                deleteSystemUserLoading : false,
                deleteSystemUserError : true,
                deleteSystemUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createSystemUser = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            createSystemUserLoading : true,
            createSystemUserError : false,
            createSystemUserErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/create`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                createSystemUserLoading : false,
                createSystemUserError : false,
                createSystemUserErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                createSystemUserLoading : false,
                createSystemUserError : true,
                createSystemUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editSystemUser = (data={}, fieldUpdate) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            editSystemUserLoading : true,
            editSystemUserError : false,
            editSystemUserErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/${fieldUpdate ? 'editfield' : 'edit'}`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                editSystemUserLoading : false,
                editSystemUserError : false,
                editSystemUserErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                editSystemUserLoading : false,
                editSystemUserError : true,
                editSystemUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const validateSystemUserName = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            userNameValidationLoading : true,
            userNameValidationError : false,
            userNameValidationErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/validateusername`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                userNameValidationLoading : false,
                userNameValidationError : false,
                userNameValidationErrorMessage : '',
                systemUserNameAvailable : true
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                userNameValidationLoading : false,
                userNameValidationError : true,
                userNameValidationErrorMessage : request.errorMessage || '',
                systemUserNameAvailable : false
            }
        })
    }
}

export const getSystemUserApiKey = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            getApiKeyLoading : true,
            getApiKeyError : false,
            getApiKeyErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/getapikey`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getApiKeyLoading : false,
                getApiKeyError : false,
                getApiKeyErrorMessage : '',
                apiKeyMap : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getApiKeyLoading : false,
                getApiKeyError : true,
                getApiKeyErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getAppBuilderSystemUsers = (data={}) => async dispatch => {
    dispatch ({
        type : 'SET_SYSTEM_USER_REDUCER',
        payload : {
            getAppBuilderSystemUsersLoading : true,
            getAppBuilderSystemUsersError : false,
            getAppBuilderSystemUsersErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/systemuser/getappbuildersystemusers`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getAppBuilderSystemUsersLoading : false,
                getAppBuilderSystemUsersError : false,
                getAppBuilderSystemUsersErrorMessage : '',
                appBuilderSystemUsers : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_SYSTEM_USER_REDUCER',
            payload : {
                getAppBuilderSystemUsersLoading : false,
                getAppBuilderSystemUsersError : true,
                getAppBuilderSystemUsersErrorMessage : request.errorMessage || ''
            }
        })
    }
}