const initialState = {
    apiList : [],
    isLoading : false,
    hasError : false,
    errorMessage : '', 
    errorPayload : '',
    flexApi : {},
    flexApiTracker : {},
    flexApiDetailView : 'details',
    apiNameIsAvailable : true,
    subUrlPathIsAvailable : true,
    apiFunction : {},
    paymentTierInFocus : {},
    featureInFocus : {},
    mappedApiList : {},
    trackerLoading : false,
    trackerError : false,
    trackerErrorMessage : '',
    endpointInFocusId : '',
    apiDeploymentLoading : false,
    apiDeploymentError : false,
    apiDeploymentErrorMessage : '',
    apiMergeLoading : false,
    apiMergeError : false,
    apiMergeErrorMessage : '',
    activeMergeFromEnvironment : '',
    apiDocumentationList : [],
    apiDocumentationsLoading : false,
    apiDocumentationsError : false,
    apiDocumentationsErrorMessage : '',
    apiDocumentation : {},
    apiDocumentationLoading : false,
    apiDocumentationError : false,
    apiDocumentationErrorMessage : '',
    getDynamicSeoApisLoading : false,
    getDynamicSeoApisError : false,
    getDynamicSeoApisErrorMessage : '',
    dynamicSeoApis : [],
    endpointUndeploymentLoading : false,
    endpointUndeploymentError : false,
    endpointUndeploymentErrorMessage : '',
    apiEnvironmentUndeployLoading : false,
    apiEnvironmentUndeployError : false,
    apiEnvironmentUndeployErrorMessage : '',
    deleteApiLoading : false,
    deleteApiError : false,
    deleteApiErrorMessage : '',
    deployedEndpointsLoading : false,
    deployedEndpointsError : false,
    deployedEndpointsErrorMessage : '',
    deployedApiEndpoints : []
}

export default function(state=initialState, action){
    switch(action.type){
        case 'GET_FLEX_APIS':
            return {
                ...state,
                isLoading : true,
                hasError : false,
                errorMessage : '',
                errorPayload : ''
            }
        case 'SET_FLEX_APIS_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                apiList : action.pagination ? 
                [...state.apiList, ...action.payload] : 
                action.payload
            } 
        case 'SET_FLEX_APIS_ERROR':
            return {
                ...state,
                isLoading : false,
                hasError : true,
                errorMessage : action.payload.errorMessage,
                errorPayload : action.payload.errorPayload
            }
        case 'GET_MAPPED_FLEX_APIS':
            return {
                ...state,
                mappedApiList : {
                    ...state.mappedApiList,
                    [action.mapId] : {
                        isLoading : true,
                        hasError : false,
                        errorMessage : '',
                        errorPayload : '',
                        apiList : state['mappedApiList'][action.mapId] && 
                        state['mappedApiList'][action.mapId]['apiList'] || []
                    }
                }
            }
        case 'SET_MAPPED_FLEX_APIS_SUCCESS':
            return {
                ...state,
                mappedApiList : {
                    ...state.mappedApiList,
                    [action.mapId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        errorPayload : '',
                        apiList : action.payload
                    }
                }
            } 
        case 'SET_MAPPED_FLEX_APIS_ERROR':
            return {
                ...state,
                mappedApiList : {
                    ...state.mappedApiList,
                    [action.mapId] : {
                        isLoading : false,
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        errorPayload : action.payload.errorPayload,
                        apiList : state['mappedApiList'][action.mapId] && 
                        state['mappedApiList'][action.mapId]['apiList'] || []
                    }
                }
            }
        case 'GET_FLEX_API':
            return {
                ...state,
                isLoading : true,
                hasError : false,
                errorMessage : '',
                errorPayload : ''
            }
        case 'SET_FLEX_API_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                flexApi : action.payload
            }
        case 'DEPLOY_API_VERSION_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
            }
        case 'SET_FLEX_API_ERROR':
            return {
                ...state,
                isLoading : false,
                hasError : true,
                errorMessage : action.payload.errorMessage,
                errorPayload : action.payload.errorPayload
            }
        case 'SET_FLEX_API_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_API_VERSION_TRACKER':
            return {
                ...state,
                trackerLoading : true,
                trackerError : false,
                trackerErrorMessage : ''
            }
        case 'SET_API_VERSION_TRACKER_ERROR':
            return {
                ...state,
                trackerLoading : false,
                trackerError : true,
                trackerErrorMessage : action.payload.errorMessage
            }
        case 'SET_API_VERSION_TRACKER_SUCCESS':
            return {
                ...state,
                trackerLoading : false,
                trackerErrorMessage : '',
                trackerError : false,
                flexApiTracker : action.payload
            }
        case 'CHANGE_API_VERSION_SUCCESS':
            return {
                ...state,
                trackerLoading : false,
                trackerErrorMessage : '',
                trackerError : false,
            }
        case 'RESET_FLEX_API_REDUCER':
            return initialState;
        default:
            return state;
    }
}