const initialState = {
    serviceDiscountIsLoading : false,
    serviceDiscountError : false,
    serviceDiscounts : [],
    serviceDiscountsTotalCount : 0,
    serviceDiscount : {},
    serviceDiscountErrorMessage : '',
    discountCodeIsAvailable : true,
    serviceDiscountUsageLog : [],
    serviceDiscountUsageLogCount : 0,
    validateDiscountCodeResponse : {}
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SERVICE_DISCOUNT_LOADING':
            return {
                ...state,
                serviceDiscountIsLoading : true,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : ''
            }
        case 'SERVICE_DISCOUNT_ERROR':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : true,
                serviceDiscountErrorMessage : action.payload.errorMessage
            }
        case 'SET_SERVICE_DISCOUNTS_SUCCESS':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : '',
                serviceDiscounts : action.payload['results'],
                serviceDiscountsTotalCount : action.payload['total_count']
            }
        case 'VALIDATE_DISCOUNT_CODE_SUCCESS':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : '',
                validateDiscountCodeResponse : action.payload
            }
        case 'SET_SERVICE_DISCOUNT_SUCCESS':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : '',
                serviceDiscount : action.payload
            }
        case 'SET_SERVICE_DISCOUNT_USAGE_SUCCESS':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : '',
                serviceDiscountUsageLog : action.payload['results'] || [],
                serviceDiscountUsageLogCount : action.payload['total_count'] || 0
            }
        case 'SAVE_SERVICE_DISCOUNTS_SUCCESS':
            return {
                ...state,
                serviceDiscountIsLoading : false,
                serviceDiscountError : false,
                serviceDiscountErrorMessage : '',
                serviceDiscount : action.create ? action.payload : 
                state.serviceDiscount
            }
        case 'SET_SERVICE_DISCOUNT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}