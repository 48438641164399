const initialState = {
    getInhouzContactsLoading : false,
    getInhouzContactsError : false,
    getInhouzContactsErrorMessage : '',
    inhouzContacts : [],
    inhouzContactsTotalCount : 0,
    getInhouzContactLoading : false,
    getInhouzContactError : false,
    getInhouzContactErrorMessage : '',
    inhouzContact : {},
    editInhouzContactLoading : false,
    editInhouzContactError : false,
    editInhouzContactErrorMessage : '',
    createInhouzContactLoading : false,
    createInhouzContactError : false,
    createInhouzContactErrorMessage : '',
    deleteInhouzContactLoading : false,
    deleteInhouzContactError : false,
    deleteInhouzContactErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_CONTACT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_CONTACT_REDUCER':
            return initialState;
        default:
            return state;
    }
}