import postRequest from '../../../utils/requests/postRequest';
import getRequest from '../../../utils/requests/getRequest';
import config from '../../../config/config';

export const getServiceDiscounts = (data={}) => async dispatch => {
    dispatch({
        type : 'SERVICE_DISCOUNT_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/servicediscount/getservicediscounts`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SERVICE_DISCOUNTS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SERVICE_DISCOUNT_ERROR',
            payload : request
        });
    }
}

export const getServiceDiscount = (id='') => async dispatch => {
    dispatch({
        type : 'SERVICE_DISCOUNT_LOADING'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/servicediscount/getservicediscount/${id}`
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SERVICE_DISCOUNT_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SERVICE_DISCOUNT_ERROR',
            payload : request
        });
    }
}

export const saveServiceDiscount = (data={}, create=false) => async dispatch => {
    dispatch({
        type : 'SERVICE_DISCOUNT_LOADING'
    });
    let request = await postRequest(
        data['_id'] ?
        `${config.devServerProxy}/api/servicediscount/edit`
        :
        `${config.devServerProxy}/api/servicediscount/create`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SAVE_SERVICE_DISCOUNTS_SUCCESS',
            payload : request,
            create
        });
    }else{
        dispatch({
            type : 'SERVICE_DISCOUNT_ERROR',
            payload : request
        });
    }
}

export const setServiceDiscountReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_SERVICE_DISCOUNT_REDUCER',
        payload : data
    });
}

export const getServiceDiscountUsage = (data={}) => async dispatch => {
    dispatch({
        type : 'SERVICE_DISCOUNT_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/servicediscount/usage`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SERVICE_DISCOUNT_USAGE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SERVICE_DISCOUNT_ERROR',
            payload : request
        });
    }
}

export const validateDiscountCode = (data={}) => async dispatch => {
    dispatch({
        type : 'SERVICE_DISCOUNT_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/servicediscount/verifyservicediscount`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'VALIDATE_DISCOUNT_CODE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SERVICE_DISCOUNT_ERROR',
            payload : request
        });
    }
}