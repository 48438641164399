// import Loadable from 'react-loadable';
import Loadable from '@loadable/component';


export const Login = Loadable(() => import('./authentication/login/login'))


export const TwoFactor = Loadable(() => import('./authentication/login/twoFactor'))


export const ResetPassword = Loadable(() => import('./authentication/login/resetPassword'))


export const SignUp = Loadable(() => import('./authentication/signup/signup'))


export const LeftMenuDrawer = Loadable(() => import('./navigation/leftMenuDrawer/leftMenuDrawer'))


export const Dashboard = Loadable(() => import('./dashboard/dashboard'))

// export const FlexFormConstructor = Loadable({
//     loader: () => import('./flexform/flexFormConstructor'),
//     loading: () => {return null}
// });

// export const CreateForm = Loadable({
//     loader: () => import('./flexform/formUi/createForm'),
//     loading: () => {return null}
// });

// export const FlexFormList = Loadable({
//     loader: () => import('./flexform/formUi/flexformList'),
//     loading: () => {return null}
// });

// export const EditFlexForm = Loadable({
//     loader: () => import('./flexform/formUi/editFlexForm'),
//     loading: () => {return null}
// });

// export const TableFormConstructor = Loadable({
//     loader: () => import('./flexform/tableform/tableFormConstructor'),
//     loading: () => {return null}
// });

// export const FlexFormParser = Loadable({
//     loader: () => import('./flexFormParser/flexFormParser'),
//     loading: () => {return null}
// });

// export const FormInstantiator = Loadable({
//     loader : () => import('./FormInstantiator/FormInstantiator'),
//     loading : () => {return null}
// });

// export const FileSearch = Loadable({
//     loader : () => import('./fileSearch/fileSearch'),
//     loading : () => {return null}
// });

// export const CreateModule = Loadable({
//     loader : () => import('./customizationHub/module/createModule'),
//     loading : () => {return null}
// })

// export const ModuleExtend = Loadable({
//     loader : () => import('./customizationHub/module/moduleExtend'),
//     loading : () => {return null}
// });

// export const CollectionExtend = Loadable({
//     loader : () => import('./customizationHub/collection/collectionHub/collectionExtend'),
//     loading : () => {return null}
// });

// export const CollectionExtend = Loadable(() => import('./customizationHub/collection/collectionHub/collectionExtend'))

// export const FlexCollections = Loadable({
//     loader : () => import('./customizationHub/collection/flexCollections'),
//     loading : () => {return null}
// });

// export const FlexCollections = Loadable(() => import('./customizationHub/collection/flexCollections'))


export const Databases = Loadable(() => import ('./customizationHub/database/databases'))

// export const DataRuleConstructor = Loadable({
//     loader : () => import('./customizationHub/collection/collectionHub/dataRule/dataRuleConstructor'),
//     loading : () => {return null}
// });

// export const ScheduledTaskConstructor = Loadable({
//     loader : () => import('./customizationHub/collection/collectionHub/scheduledTask/scheduledTaskConstructor'),
//     loading : () => {return null}
// });

export const ApiHub = Loadable(() => import('./customizationHub/apiHub/flexApiHub'))


export const ApiDetails = Loadable(() => import('./customizationHub/apiHub/apiDetails'))


export const SecretKeys = Loadable(() => import('./customizationHub/secrets/secretKeys'))


export const ScheduledTask = Loadable(() => import('./customizationHub/scheduledTask/scheduledTask'))


export const ScheduledTaskBase = Loadable(() => import('./customizationHub/scheduledTask/scheduledTaskBase'))


export const RolesAndPermissions = Loadable(() => import('./customizationHub/rolesAndPermissions/index'))


export const FunctionHub = Loadable(() => import('./customizationHub/functions/functionHub'))


export const UserProfile = Loadable(() => import('./customizationHub/userManagement/userProfile/userProfile'))


export const UserManagement = Loadable(() => import('./customizationHub/userManagement/userManagement/userManagement'))


export const SubscriptionServiceHub = Loadable(() => import('./customizationHub/subscriptionService/subscription/subscriptionServiceHub'))


export const SubscriptionServicesBase = Loadable(() => import('./customizationHub/subscriptionService/subscription/subscriptionServicesBase'))


export const DocumentationDetailWrapper = Loadable(() => import('./customizationHub/documentation/documentationDetailWrapper'))


export const DocumentationTypeParser = Loadable(() => import('./customizationHub/documentation/documentationTypeParser'))


export const DocumentationMenu = Loadable(() => import('./customizationHub/documentation/documentationMenu'))


export const SubscriptionLanding = Loadable(() => import('./customizationHub/subscriptionService/subscription/landing/subscriptionLanding'))

// export const SubscriptionAction = Loadable({
//     loader: () => import('./customizationHub/subscriptionService/subscription/landing/subscriptionAction'),
//     loading: () => {return null}
// });


export const SubscriberBase = Loadable(() => import('./customizationHub/subscriptionService/subscriber/SubscriberBase'))


export const ExternalSubscriptionsBase = Loadable(() => import('./customizationHub/subscriptionService/externalSubscriptions/externalSubscriptionsBase'))


export const CompanySettings = Loadable(() => import('./customizationHub/companySettings/companySettings'))


export const FileStorageServices = Loadable(() => import('./customizationHub/fileStorageServices/fileStorageServices'))


export const CreatePayoutAccount = Loadable(() => import('./customizationHub/companySettings/payment/createPayoutAccount'))


export const PayoutAccountDetails = Loadable(() => import('./customizationHub/companySettings/payment/payoutAccountDetails'))


export const AppBuilder = Loadable(() => import('./customComponents/appBuilder/appBuilder'))

export const AppHub = Loadable(() => import('./customizationHub/appHub/appHub'))

export const SystemUserBase = Loadable(() => import('./customizationHub/systemUser/systemUserBase'))

export const PlanSignupWrapper = Loadable(() => import('./customizationHub/subscriptionService/subscriptionSignup/planSignup/planSignupWrapper'))

export const SubscriptionAccountSettingsBase = Loadable(() => import('./customizationHub/subscriptionService/accountSettings/subscriptionAccountSettingsBase'))

export const MetricsHub = Loadable(() => import('./customizationHub/metricsHub/metricsHub'))

export const InhouzPayHub = Loadable(() => import('./customizationHub/inhouzPay/inhouzPayHub'))

export const InhouzPayDetails = Loadable(() => import('./customizationHub/inhouzPay/inhouzPayment/inhouzPayDetails'))

export const PaymentDistributionDetails = Loadable(() => import('./customizationHub/inhouzPay/paymentDistribution/paymentDistributionDetails'))

export const DistributionAccountRequestWrapper = Loadable(() => import('./customizationHub/inhouzPay/paymentDistribution/distributionAccountRequestWrapper'))

export const InhouzCreateDistributionAccountWrapper = Loadable(() => import('./customizationHub/inhouzPay/paymentDistribution/inhouzCreateDistributionAccountWrapper'))

export const PaymentLinkForm = Loadable(() => import('./customizationHub/inhouzPay/paymentLink/paymentLinkForm'));

export const PaymentLinkViewWrapper = Loadable(() => import('./customizationHub/inhouzPay/paymentLink/paymentLinkViewer/paymentLinkViewWrapper'));

export const PaymentPageDesign = Loadable(() => import('./customizationHub/inhouzPay/branding/paymentPageDesign'));

export const CustomerPortalWrapper = Loadable(() => import('./customizationHub/customerPortal/customerPortalWrapper'));

export const InhouzSignHub = Loadable(() => import('./customizationHub/inhouzSign/inhouzSignHub'));

export const InhouzSignDocumentViewer = Loadable(() => import('./customizationHub/inhouzSign/document/inhouzSignDocumentViewer'));

export const InhouzSignerInterface = Loadable(() => import('./customizationHub/inhouzSign/document/inhouzSignerInterface'));

export const InhouzProjectWrapper = Loadable(() => import('./customizationHub/inhouzProject/inhouzProjectWrapper'));

export const InhouzProjectDetailWrapper = Loadable(() => import('./customizationHub/inhouzProject/details/inhouzProjectDetailWrapper'));

export const GeneratePdfFile = Loadable(() => import('./customizationHub/pdfGenerator/generatePdfFile'));

export const InhouzContactList = Loadable(() => import('./customizationHub/inhouzContact/inhouzContactList'));

export const PdfGeneratorHub = Loadable(() => import('./customizationHub/pdfGenerator/pdfGeneratorHub'));

export const GeneratedPdfDocument = Loadable(() => import('./customizationHub/pdfGenerator/document/generatedPdfDocument'));