import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import Auth from './authReducer';
import FlexForm from './flexFormReducer';
import Drawer from './drawerReducer';
import FormInstantiator from './formInstantiatorReducer';
import Company from './companyReducer';
import FileSearch from './fileSearchReducer';
import SavedFormElementStyles from './savedFormElementStyleReducer';
import FlexModule from './customizationHub/module/flexModuleReducer';
import NavBar from './navigation/navbarReducer';
import CreateFlexCollection from './customizationHub/collection/createCollectionReducer';
import GetFlexCollection from './customizationHub/collection/getCollectionReducer';
import EditFlexCollection from './customizationHub/collection/editCollectionReducer';
import Database from './customizationHub/database/databaseReducer';
import ViewportDisplay from './display/viewportDisplayReducer';
import SimpleDrawer from './simpleDrawer/simpleDrawerReducer';
import QueryBuilder from './queryBuilder/queryReducer';
import FunctionBuilder from './functionBuilder/functionBuilderReducer';
import FlexApi from './apiHub/flexApiReducer';
import Secret from './secrets/secretKeyReducer';
import ScheduledTask from './scheduledTask/scheduledTaskReducer';
import Permission from './rolesAndPermissions/permissionsReducer';
import Role from './rolesAndPermissions/rolesReducer';
import UserRoles from './rolesAndPermissions/userRolesReducer';
import UserManagement from './userManagement/userManagementReducer';
import SubscriptionService from './subscriptionService/subscriptionServiceReducer';
import ServiceDiscount from './subscriptionService/serviceDiscountReducer';
import Subscriber from './subscriptionService/subscriberReducer';
import SubscriptionMetrics from './subscriptionService/subscriptionMetricsReducer';
import FileStorageService from './fileStorageService/fileStorageServiceReducer';
import Multimedia from './multimedia/multimediaReducer';
import Braintree from './braintreePayment/braintreeReducer';
import PaymentSettings from './payment/paymentSettingsReducer';
import PayoutAccount from './payment/payoutAccountReducer';
import AppBuilder from './appBuilder/appBuilderReducer';
import WebComponent from './appBuilder/webComponentReducer';
import AppHub from './appHub/appHubReducer';
import AppViewer from './appViewer/appViewerReducer';
import SystemUser from './systemUsers/systemUserReducer';
import SubscriptionAuth from './subscriptionService/subscriptionAuthReducer';
import PaymentTransaction from './payment/paymentTransactionReducer';
import SubscriptionSettings from './subscriptionService/subscriptionSettingsReducer';
import InhouzFileUploader from './inhouzFileUploader/inhouzFileUploaderReducer';
import ReferralCode from './referralSystem/referralReducer';
import AppMetrics from './metricsHub/appMetricsReducer';
import PaymentMetrics from './metricsHub/paymentMetricsReducer';
import InhouzSignMetrics from './metricsHub/inhouzSignMetricsReducer';
import InhouzPay from './inhouzPay/inhouzPayReducer';
import PaymentGateway from './inhouzPay/paymentGatewayReducer';
import TaxRule from './inhouzPay/taxRuleReducer';
import PaymentProduct from './inhouzPay/paymentProductReducer';
import PaymentCategory from './inhouzPay/paymentCategoryReducer';
import PaymentLink from './inhouzPay/paymentLinkReducer';
import CustomerPortal from './customerPortal/customerPortalReducer';
import InhouzSign from './inhouzSign/inhouzSignReducer';
import InhouzProject from './inhouzProject/inhouzProjectReducer';
import InhouzProjectCategory from './inhouzProject/inhouzProjectCategoryReducer';
import InhouzSecurity from './inhouzSecurity/inhouzSecurityReducer';
import InhouzContact from './inhouzContacts/inhouzContactReducer';
import PdfGenerator from './pdfGenerator/pdfGeneratorReducer';
import PdfGeneratorMetrics from './metricsHub/pdfGeneratorMetricsReducer';
import PaymentBranding from './inhouzPay/paymentBrandingReducer';

const rootreducer = combineReducers({
    form:formReducer,
    Auth,
    FlexForm,
    Drawer,
    FormInstantiator,
    Company,
    FileSearch,
    SavedFormElementStyles,
    FlexModule,
    NavBar,
    CreateFlexCollection,
    GetFlexCollection,
    EditFlexCollection,
    Database,
    ViewportDisplay,
    SimpleDrawer,
    QueryBuilder,
    FunctionBuilder,
    UserRoles,
    FlexApi,
    Secret,
    ScheduledTask,
    Permission,
    Role,
    UserManagement,
    SubscriptionService,
    ServiceDiscount,
    Subscriber,
    SubscriptionMetrics,
    FileStorageService,
    Multimedia,
    Braintree,
    PaymentSettings,
    PayoutAccount,
    AppBuilder,
    AppHub,
    AppViewer,
    SystemUser,
    SubscriptionAuth,
    PaymentTransaction,
    SubscriptionSettings,
    WebComponent,
    InhouzFileUploader,
    ReferralCode,
    AppMetrics,
    InhouzPay,
    PaymentGateway,
    TaxRule,
    PaymentProduct,
    PaymentCategory,
    PaymentLink,
    CustomerPortal,
    PaymentMetrics,
    InhouzSign,
    InhouzProject,
    InhouzSecurity,
    InhouzSignMetrics,
    InhouzContact,
    InhouzProjectCategory,
    PdfGenerator,
    PdfGeneratorMetrics,
    PaymentBranding
})

export default rootreducer;