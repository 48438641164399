const initialState = {
    scheduledTaskList : [],
    isLoading : false,
    hasError : false,
    errorMessage : '', 
    errorPayload : '',
    scheduledTask : {},
    mappedScheduledTask : {},
    scheduledTaskBaseView : '',
    scheduledTaskTotalCount : 0,
    scheduledTaskLogTotalCount : 0,
    scheduledTaskLogList : [],
    getScheduledTaskLogsLoading : false,
    getScheduledTaskLogsError : false,
    getScheduledTaskLogsErrorPayload : '',
    runScheduledTaskLoading : false,
    runScheduledTaskError : false,
    runScheduledTaskErrorMessage : ''
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SCHEDULED_TASK_REQUEST_SENT':
            return {
                ...state,
                isLoading : true,
                hasError : false,
                errorMessage : '',
                errorPayload : ''
            }
        case 'SET_SCHEDULED_TASKS_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                scheduledTaskList : action.pagination ? 
                [...state.scheduledTaskList, ...action.payload['results']] : 
                action.payload['results'],
                scheduledTaskTotalCount : action.payload['total_count']
            }
        case 'SET_SCHEDULED_TASK_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                scheduledTask : action.payload
            }
        case 'SCHEDULED_TASK_RESPONSE_ERROR':
            return {
                ...state,
                isLoading : false,
                hasError : true,
                errorMessage : action.payload.errorMessage,
                errorPayload : action.payload.errorPayload
            }
        case 'SAVE_SCHEDULED_TASK_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                scheduledTask : action.payload
            }
        case 'SET_SCHEDULED_TASK_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}