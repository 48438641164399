const initialState = {
    getProjectCategoriesLoading : false,
    getProjectCategoriesError : false,
    getProjectCategoriesErrorMessage : '',
    projectCategories : [],
    projectCategoryTotalCount : 0,
    getProjectCategoryLoading : false,
    getProjectCategoryError : false,
    getProjectCategoryErrorMessage : '',
    projectCategoryInFocus : {},
    deleteProjectCategoryLoading : false,
    deleteProjectCategoryError : false,
    deleteProjectCategoryErrorMessage : '',
    editProjectCategoryLoading : false,
    editProjectCategoryError : false,
    editProjectCategoryErrorMessage : '',
    createProjectCategoryLoading : false,
    createProjectCategoryError : false,
    createProjectCategoryErrorMessage : '',
    getProjectCategoryStatusListLoading : false,
    getProjectCategoryStatusListError : false,
    getProjectCategoryStatusListErrorMessage : '',
    projectCategoryStatusList : []
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_PROJECT_CATEGORY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_PROJECT_CATEGORY_REDUCER':
            return initialState;
        default:
            return state;
    }
}