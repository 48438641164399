const initialState = {
    userRoles : [],
    isLoading : false,
    hasError : false,
    errorMessage : '',
    errorPayload : '',
    userRole : {}
}

export default function(state = initialState, action){
    switch(action.type){
        case 'USER_ROLES_REQUEST_SENT':
            return {
                ...state,
                isLoading : true,
                hasError : false,
                errorMessage : '',
                errorPayload : ''
            }
        case 'GET_USERS_ROLES_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                userRoles : action.pagination ? 
                [...state.userRoles, ...action.payload['results']] : 
                action.payload['results']
            }
        case 'USER_ROLES_REQUEST_ERROR':
            return {
                ...state,
                isLoading : false,
                hasError : true,
                errorMessage : action.payload.errorMessage,
                errorPayload : action.payload.errorPayload
            }
        case 'GET_USER_ROLES_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                userRole : action.payload
            }
        case 'SET_USER_ROLE_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:    
            return state;
    }
}