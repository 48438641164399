export default function(state={}, action){
    switch(action.type){
        case 'FILE_SEARCH_PRE_SEARCH':
            return {...state, preSearchResults : action.payload}
        case 'GET_FILE_DOCUMENTS':
            return {...state, fileSearchResults : action.payload}
        case 'GET_FILE_DOCUMENT':
            return {...state, fileData : action.payload}
        case 'FILE_UPDATE_SUCCESS': //formInstanceActions
            return {...state, fileData : action.payload}
        case 'CLEAR_FILE_DOCUMENTS':
            return {...state, fileSearchResults : action.payload}
        case 'CLEAR_FILE_DOCUMENT':
            return {...state, fileData : action.payload}
        case 'CLEAR_FILESEARCH_PRESEARCH':
            return {...state, preSearchResults : action.payload}
        case 'SET_QUERY_OBJECT':
            return {...state, queryObject : action.payload}
        case 'SET_QUERY_OBJECT_TEMP':
            return {...state, queryObjectTemp : action.payload}
        default:
            return state;
    }
}