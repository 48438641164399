const initialState = {
    paymentPageDesigns : [],
    paymentPageDesignsTotalCount : 0,
    getPaymentPageDesignsLoading : false,
    getPaymentPageDesignsError : false,
    getPaymentPageDesignsErrorMessage : '',
    paymentPageDesignInFocus : {},
    getPaymentPageDesignLoading : false,
    getPaymentPageDesignError : false,
    getPaymentPageDesignErrorMessage : '',
    deletePaymentPageDesignLoading : false,
    deletePaymentPageDesignError : false,
    deletePaymentPageDesignErrorMessage : '',
    updatePaymentPageDesignLoading : false,
    updatePaymentPageDesignError : false,
    updatePaymentPageDesignErrorMessage : '',
    createPaymentPageDesignLoading : false,
    createPaymentPageDesignError : false,
    createPaymentPageDesignErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_BRANDING_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_BRANDING_REDUCER':
            return initialState;
        default:
            return state;
    }
}