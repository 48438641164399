import React, {Component} from 'react';
import { BrowserRouter, Route} from 'react-router-dom';
import Header from './navigation/header';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import config from '../config/config';
import {
    getTeamMembers, setWindowDisplayMode, 
    webLogout, setAuthReducer, getDeployedWebComponents
} from '../actions';
import {isEqual} from 'lodash';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import verifyUser from '../utils/authentication/verifyUser';
import moment from 'moment';
import UserAgent from 'express-useragent';
import cookie from 'react-cookies';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import $ from 'jquery';
// /* eslint-disable-next-line */
// import calculateListKeyTrackerWorker from 'workerize-loader?inline!../worker/calculateListKeyTracker.worker.js';
/* eslint-disable-next-line */
import attributeWorker from 'workerize-loader?inline!../worker/attributeFunction.worker.js';
/* eslint-disable-next-line */
import getWebComponentWorker from 'workerize-loader?inline!../worker/getWebComponent.worker.js';
// /* eslint-disable-next-line */
// import appDataChangeWorker from 'workerize-loader?inline!../worker/appDataChangeLifecycle.worker.js';

// import UserManagement from './customizationHub/userManagement/userManagement/userManagement'

import {
    LeftMenuDrawer, 
    Dashboard,
    // FlexFormConstructor, 
    // CreateForm,
    // FlexFormList, 
    // EditFlexForm,
    // TableFormConstructor, 
    // FlexFormParser,
    // FormInstantiator, 
    // FileSearch, 
    // CreateModule,
    // ModuleExtend, 
    // CollectionExtend, 
    Databases, 
    ApiHub, 
    ApiDetails,
    SecretKeys, 
    // ScheduledTasks, 
    ScheduledTask,
    // FlexCollections, 
    RolesAndPermissions, 
    FunctionHub,
    Login, 
    SignUp, 
    TwoFactor, 
    ResetPassword, 
    UserProfile,
    UserManagement, 
    SubscriptionServiceHub,
    SubscriptionServicesBase, 
    DocumentationDetailWrapper,
    DocumentationTypeParser, 
    DocumentationMenu,
    SubscriptionLanding, 
    // SubscriptionAction,
    SubscriberBase, 
    ExternalSubscriptionsBase,
    CompanySettings, 
    FileStorageServices,
    CreatePayoutAccount, 
    PayoutAccountDetails,
    AppBuilder, 
    AppHub, 
    // AppViewer, 
    PlanSignupWrapper,
    SubscriptionAccountSettingsBase, 
    ScheduledTaskBase,
    SystemUserBase,
    MetricsHub,
    InhouzPayHub,
    InhouzPayDetails,
    PaymentLinkForm,
    PaymentLinkViewWrapper,
    CustomerPortalWrapper,
    InhouzSignHub,
    InhouzSignDocumentViewer,
    InhouzSignerInterface,
    InhouzProjectWrapper,
    DistributionAccountRequestWrapper,
    InhouzCreateDistributionAccountWrapper,
    PaymentDistributionDetails,
    GeneratePdfFile,
    InhouzContactList,
    InhouzProjectDetailWrapper,
    PdfGeneratorHub,
    GeneratedPdfDocument,
    PaymentPageDesign
} from './loadables';
import getCookie from '../utils/cookies/getCookie';
import encryptDecrypt from '../utils/cryptography/encryptDecrypt';

class App extends Component{
    calculateListKeyTrackerWorkerInstance;
    workerInstance;
    webComponentWorkerInstance;
    appDataChangeWorkerInstance;

    async componentDidMount(){
        const {
            user, 
            getTeamMembers,
            getUserLoading=false,
            setAuthReducer,
            getDeployedWebComponents
        } = this.props;

        if(
            window.location.pathname.startsWith('/dashboard') ||
            window.location.pathname.startsWith('/appbuilder') ||
            window.location.pathname.startsWith('/inhouzsignbuilder/') ||
            window.location.pathname.startsWith('/inhouzsign/sign/') ||
            window.location.pathname.startsWith('inhouzsign/document/') ||
            window.location.pathname.startsWith('/pdfgeneration/') 
        ){
            // this.calculateListKeyTrackerWorkerInstance = calculateListKeyTrackerWorker();
            this.workerInstance = attributeWorker();
            this.webComponentWorkerInstance = getWebComponentWorker();
            // this.appDataChangeWorkerInstance = appDataChangeWorker();
        }

        let userVerified;
        if(!['/', '/login', '/resetpassword'].includes(window.location.pathname)){
            userVerified = await verifyUser(user);
        }
        if(userVerified){
        }else{
            if(
                (
                    !['/', '/login', '/resetpassword'].includes(window.location.pathname) && 
                    !window.location.pathname.startsWith('/signup') &&
                    !window.location.pathname.startsWith('/subscriptionservice/signup/') &&
                    !window.location.pathname.startsWith('/subscriptionservice/accountsettings') && 
                    !window.location.pathname.startsWith('/subscriptionservice/account/') && 
                    !window.location.pathname.startsWith('/pay/') &&
                    !window.location.pathname.startsWith('/customerportal') &&
                    !window.location.pathname.startsWith('/inhouzsign/sign/') &&
                    !window.location.pathname.startsWith('/paymentdistribution/accountrequest/') &&
                    !window.location.pathname.startsWith('/pdfgeneration/')
                ) && 
                !getUserLoading
            ){
                window.location.href = '/login';
            }
        }
        getTeamMembers();
        getDeployedWebComponents();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.setSessionChecker();
        if(setAuthReducer){
            let userAgentObj = UserAgent.parse(window.navigator.userAgent);
            setAuthReducer({
                userAgent : userAgentObj
            });
        }
        this.fingerprintDevice();
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
          })
    }

    async componentDidUpdate(prevProps){
        const {
            user, 
            getUserLoading,
            getTeamMembers
        } = this.props;

        if(
            !isEqual(user, prevProps.user)
        ){
            let userVerified;
            if(
                !['/', '/login', '/resetpassword'].includes(window.location.pathname)
            ){
                userVerified = await verifyUser(user);
            }
            if(userVerified){
                if(!['/myprofile'].includes(window.location.pathname)){
                    getTeamMembers();
                }
            }else{
                if(
                    (
                        !['/', '/login', '/resetpassword'].includes(window.location.pathname) && 
                        !window.location.pathname.startsWith('/subscriptionservice/signup/') &&
                        !window.location.pathname.startsWith('/subscriptionservice/accountsettings') && 
                        !window.location.pathname.startsWith('/subscriptionservice/account/') &&
                        !window.location.pathname.startsWith('/pay/') &&
                        !window.location.pathname.startsWith('/customerportal') &&
                        !window.location.pathname.startsWith('/inhouzsign/sign/') &&
                        !window.location.pathname.startsWith('/paymentdistribution/accountrequest/') &&
                        !window.location.pathname.startsWith('/pdfgeneration/')
                    ) && 
                    !getUserLoading
                ){
                    window.location.href = '/login';
                }
            }
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
        this.removeWebworkers();
    }

    sessionIntervalCheck;
    setSessionChecker = () => {
        let sessionExpiration = getCookie('sessionExpirationTimestamp');
        if(
            sessionExpiration &&
            moment().unix() < Number(sessionExpiration)
        ){
            this.sessionIntervalCheck = setInterval(() => {
                this.timeoutCheck();
            }, 1000)
        }
    }

    timeoutCheck = () => {
        let sessionExpiration = getCookie('sessionExpirationTimestamp');
        let currentTimeStamp = moment().unix();

        if(
            !sessionExpiration ||
            currentTimeStamp > Number(sessionExpiration)
        ){
            if(sessionExpiration){
                const {
                    webLogout
                } = this.props;
                
                if(webLogout){
                    webLogout();
                }
        
            }else{
                const {
                    setAuthReducer
                } = this.props;

                if(setAuthReducer){
                    setAuthReducer({user : {}});
                }
            }
            //set pathname
            let pathName = window.location.pathname;
            if(
                !['/', '/login', '/resetpassword', '/twofactor'].includes(pathName) &&
                !pathName.startsWith('/signup') &&
                !pathName.startsWith('/subscriptionservice/signup/') &&
                !pathName.startsWith('/subscriptionservice/accountsettings') && 
                !pathName.startsWith('/subscriptionservice/account/') &&
                !pathName.startsWith('/pay/') &&
                !pathName.startsWith('/customerportal') && 
                !pathName.startsWith('/inhouzsign/sign/') &&
                !pathName.startsWith('/paymentdistribution/accountrequest/') &&
                !pathName.startsWith('/pdfgeneration/')
            ){
                sessionStorage.setItem('lastPath', pathName);
            }
            clearInterval(this.sessionIntervalCheck);
        }
    }

    handleResize = () => {
        const {setWindowDisplayMode} = this.props;
        let width = window.innerWidth;
        if (width <= 500){
            setWindowDisplayMode('mobile', width);
        }else if(width > 500 && width <= 800){
            setWindowDisplayMode('tablet', width);
        }else if(width > 800){
            setWindowDisplayMode('desktop', width);
        }
    }

    fingerprintDevice = async () => {
        let encryptedFingerprint = cookie.load('inhouz_df');
        if(!encryptedFingerprint){
            const fpPromise = FingerprintJS.load();
            const fp = await fpPromise
            const result = await fp.get();
            let fingerprint = result.visitorId;
            if(fingerprint){
                const location = window.location;
                const {
                    hostname=''
                } = location;
                let cookieDomain = `.` + hostname.split('.').slice(-2).join('.');
                cookie.save(
                    'inhouz_df', 
                    encryptDecrypt(fingerprint, true).replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l'), 
                    {
                        domain : cookieDomain,
                        httpOnly : false,
                        path : '/',
                        maxAge : 360 * 24 * 60 * 60
                    }
                );
            }
        }
    }

    getPrimaryWorkerInstance = () => {
        if(!this.workerInstance){
            this.workerInstance = attributeWorker();
        }
        return this.workerInstance;
    }

    getListUpdateTrackerWorkerInstance = () => {
        // if(!this.calculateListKeyTrackerWorkerInstance){
        //     this.calculateListKeyTrackerWorkerInstance = calculateListKeyTrackerWorker();
        // }
        return this.calculateListKeyTrackerWorkerInstance;
    }

    getWebComponentWorkerInstance = () => {
        if(!this.webComponentWorkerInstance){
            this.webComponentWorkerInstance = getWebComponentWorker();
        }
        return this.webComponentWorkerInstance;
    }

    getAppDataChangeWorkerInstance = () => {
        // if(!this.appDataChangeWorkerInstance){
        //     this.appDataChangeWorkerInstance = appDataChangeWorker();
        // }
        return this.appDataChangeWorkerInstance;
    }

    removeWebworkers = () => {
        if(
            this.workerInstance && 
            this.workerInstance.terminate
        ){
            this.workerInstance.terminate();
        }
        if(
            this.webComponentWorkerInstance && 
            this.webComponentWorkerInstance.terminate
        ){
            this.webComponentWorkerInstance.terminate();
        }
        if(
            this.appDataChangeWorkerInstance && 
            this.appDataChangeWorkerInstance.terminate
        ){
            this.appDataChangeWorkerInstance.terminate();
        }
        // if(
        //     this.calculateListKeyTrackerWorkerInstance && 
        //     this.calculateListKeyTrackerWorkerInstance.terminate
        // ){
        //     this.calculateListKeyTrackerWorkerInstance.terminate();
        // }
      }
    
    render(){
        let path = (
            window.location && 
            window.location.pathname && 
            window.location.pathname.split('/')[1]
        ) || ''
        let hideHeader = config.hideHeaderPaths.includes(path.toLowerCase());
        return(
            <MuiThemeProvider>
                <BrowserRouter>
                    <div>
                        {
                            !hideHeader && 
                            <Header />
                        }
                        <LeftMenuDrawer />
                        <Route exact path={'/'} component={Login} />
                        <Route exact path={'/login'} component={Login} />
                        <Route exact path={'/signup'} component={SignUp} />
                        <Route exact path={'/signup/:planName/:billingType'} component={SignUp} />
                        <Route exact path={'/twofactor'} component={TwoFactor} />
                        <Route exact path={'/resetpassword'} component={ResetPassword} />
                        <Route exact path={'/myprofile'} component={UserProfile} />
                        <Route exact path={'/usermanagement'} component={UserManagement} />
                        {/* <Route exact path={'/collections'} component={FlexCollections} /> */}
                        {/* <Route exact path={'/collections/:collectionId'} component={CollectionExtend} /> */}
                        <Route exact path={'/databases'} component={Databases} />
                        <Route exact path={'/flexapihub'} component={ApiHub} />
                        <Route exact path={'/flexapihub/:formType'} component={ApiDetails} />
                        <Route exact path={'/flexapihub/:formType/:flexApiId'} component={ApiDetails} />
                        <Route exact path={'/scheduledtasks'} component={ScheduledTaskBase} />
                        <Route exact path={'/scheduledtasks/:formType'} component={ScheduledTask} />
                        <Route exact path={'/scheduledtasks/:formType/:scheduledTaskId'} component={ScheduledTask} />
                        <Route exact path={'/rolesandpermissions'} component={RolesAndPermissions} />
                        <Route exact path={'/functions'} component={FunctionHub} />
                        <Route exact path={'/secretkeys'} component={SecretKeys} />
                        <Route exact path={'/systemusers'} component={SystemUserBase} />
                        <Route exact path={'/apphub'} component={AppHub} />
                        <Route exact path={'/subscriptionservice/signup/:signupSlug/:paymentTierName'} component={PlanSignupWrapper} />
                        <Route exact path={'/subscriptionservice/account/login/:assetId/:externalCompanyId'} component={SubscriptionAccountSettingsBase} />
                        <Route exact path={'/subscriptionservice/accountsettings/:accountSettingsSlug'} component={SubscriptionAccountSettingsBase} />
                        <Route exact path={'/companysettings'} component={CompanySettings} />
                        <Route exact path={'/filestorageservices'} component={FileStorageServices} />
                        <Route exact path={'/subscriptionservices'} component={SubscriptionServicesBase} />
                        <Route exact path={'/subscriptionservice/signup/:signupSlug'} component={SubscriptionLanding} />
                        <Route exact path={'/subscriptionservices/form/:formType'} component={SubscriptionServiceHub} />
                        <Route exact path={'/subscriptionservices/form/:formType/:subscriptionServiceId'} component={SubscriptionServiceHub} />
                        <Route exact path={'/subscriptionservices/subscriber/:userId'} component={SubscriberBase} />
                        <Route exact path={'/documentation'} component={DocumentationMenu} />
                        <Route exact path={'/documentation/:assetType/:assetId'} component={DocumentationDetailWrapper} />
                        <Route exact path={'/documentation/:assetType'} component={DocumentationTypeParser} />
                        <Route exact path={'/externalsubscriptions'} component={ExternalSubscriptionsBase} />
                        <Route 
                            exact 
                            path={'/dashboard'} 
                            // component={Dashboard} 
                            render={(props) => <Dashboard 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                />
                            }
                        />
                        <Route 
                            path={'/appbuilder/:appId'} 
                            // component={AppBuilder} 
                            render={(props) => <AppBuilder 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                />
                            }
                        />
                        <Route 
                            path={'/inhouzsignbuilder/:documentType/:documentId/:appId'} 
                            // component={AppBuilder} 
                            render={(props) => <AppBuilder 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                    inhouzModule='inhouzSign'
                                />
                            }
                        />
                        <Route 
                            path={'/pdfgeneratorbuilder/:documentType/:documentId/:appId'} 
                            // component={AppBuilder} 
                            render={(props) => <AppBuilder 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                    inhouzModule='pdfGenerator'
                                />
                            }
                        />
                        <Route exact path={'/metricshub'} component={MetricsHub} />
                        <Route exact path={'/inhouzpayments'} component={InhouzPayHub} />
                        <Route exact path={'/inhouzpayments/payoutaccount/create'} component={CreatePayoutAccount} />
                        <Route exact path={'/inhouzpayments/payoutaccount/create/:payoutAccountId'} component={CreatePayoutAccount} />
                        <Route exact path={'/inhouzpayments/payoutaccount/details/:payoutAccountId'} component={PayoutAccountDetails} />
                        <Route exact path={'/inhouzpayment/payment/:inhouzPaymentId'} component={InhouzPayDetails} />
                        <Route exact path={'/inhouzpayment/paymentlink/:formType'} component={PaymentLinkForm} />
                        <Route exact path={'/inhouzpayment/paymentlink/:formType/:paymentLinkId'} component={PaymentLinkForm} />
                        <Route exact path={'/inhouzpayment/paymentdistribution/:viewType/:paymentDistributionId'} component={PaymentDistributionDetails} />
                        <Route exact path={'/inhouzpayment/paymentpage/design/:formType'} component={PaymentPageDesign} />
                        <Route exact path={'/inhouzpayment/paymentpage/design/:formType/:paymentPageDesignId'} component={PaymentPageDesign} />
                        <Route exact path={'/pay/:paymentMode/:slug'} component={PaymentLinkViewWrapper} />
                        <Route exact path={'/customerportal'} component={CustomerPortalWrapper} />
                        <Route exact path={'/inhouzsign'} component={InhouzSignHub} />
                        <Route 
                            exact 
                            path={'/inhouzsign/sign/:cookieId/:encryptedKey'} 
                            // component={InhouzSignerInterface} 
                            render={(props) => <InhouzSignerInterface 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                />
                            }
                        />
                        <Route 
                            exact 
                            path={'/inhouzsign/document/:viewType/:documentId'} 
                            // component={InhouzSignDocumentViewer} 
                            render={(props) => <InhouzSignDocumentViewer 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                />
                            }
                        />
                        <Route exact path={'/inhouzproject'} component={InhouzProjectWrapper} />
                        <Route exact path={'/inhouzproject/:inhouzProjectId'} component={InhouzProjectDetailWrapper} />
                        <Route exact path={'/paymentdistribution/accountrequest/:requestId'} component={DistributionAccountRequestWrapper} />
                        <Route exact path={'/paymentdistribution/account/create'} component={InhouzCreateDistributionAccountWrapper} />
                        <Route 
                            exact 
                            path={'/pdfgeneration/:moduleName/:encryptedKey'} 
                            // component={GeneratePdfFile} 
                            render={(props) => <GeneratePdfFile 
                                    {...props} 
                                    calculateListKeyTrackerWorkerInstanceProp={this.getListUpdateTrackerWorkerInstance}
                                    workerInstanceProp={this.getPrimaryWorkerInstance}
                                    webComponentWorkerInstanceProp={this.getWebComponentWorkerInstance}
                                    appDataChangeWorkerInstanceProp={this.getAppDataChangeWorkerInstance}
                                />
                            }
                        />
                        <Route exact path={'/inhouzcontacts'} component={InhouzContactList} />
                        <Route exact path={'/pdfgenerator'} component={PdfGeneratorHub} />
                        <Route exact path={'/pdfgenerator/document/:documentId'} component={GeneratedPdfDocument} />

                        {/*<Route path={'/appviewer'} component={AppViewer} /> */}
                        {/* 
                        <Route exact path={'/flexforms'} component={FlexFormList} />
                        <Route exact path={'/customizationhub/create/module'} component={CreateModule} />
                        <Route exact path={'/customizationhub/module/extend/:moduleId'} component={ModuleExtend} />
                        <Route exact path={'/flexform/:formId'} component={FlexFormConstructor} />
                        <Route exact path={'/create/form'} component={CreateForm} />
                        <Route exact path={'/flexform/table/:formId'} component={TableFormConstructor} />
                        <Route exact path={'/edit/form/:formId'} component={EditFlexForm} />
                        <Route exact path={'/flexform/view/:formId'} component={FlexFormParser} />
                        <Route exact path={'/flexform/view/:formId/:module/:fileId'} component={FlexFormParser} />
                        <Route exact path={'/forms/custom/:module'} component={FormInstantiator} />
                        <Route exact path={'/filesearch'} component={FileSearch} /> 
                        */}
                    </div>
                </BrowserRouter>
            </MuiThemeProvider>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.Auth.user,
        getUserLoading : state.Auth.getUserLoading,
        logoutIsLoading : state.Auth.logoutIsLoading
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getTeamMembers, setWindowDisplayMode,
        webLogout, setAuthReducer, getDeployedWebComponents
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
