const initialState = {
    leftMenuDrawer : false
}

export default function (state = initialState, action){
    switch(action.type){
        case 'TOGGLE_LEFT_MENU_DRAWER':
            return {...state, leftMenuDrawer : !state.leftMenuDrawer}
        default: 
            return state;
    }
}