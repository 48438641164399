import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';

export const setInhouzSecurityReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : data
    });
}

export const resetInhouzSecurityReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_SECURITY_REDUCER'
    });
}

export const inhouzSecurityPasswordLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            passwordLoginLoading : true,
            passwordLoginError : false,
            passwordLoginErrorMessage : '',
            requestPasswordError : false,
            requestPasswordErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/passwordlogin`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : false,
                passwordLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : true,
                passwordLoginErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const inhouzSecurityRequestPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            requestPasswordLoading : true,
            requestPasswordError : false,
            requestPasswordErrorMessage : '',
            passwordLoginError : false,
            passwordLoginErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/requestuserpassword`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : false,
                requestPasswordErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : true,
                requestPasswordErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const inhouzSecurityQuestionnaireAuth = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            questionnaireAuthLoading : true,
            questionnaireAuthError : false,
            questionnaireAuthErrorMessage : '',
            questionnaireValidationMap : {}
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/questionnaireauth`, 'POST', data);
    const {
        encryptedResponse=''
    } = request;
    let extra={};
    if(encryptedResponse){
        let decryptedData = encryptDecrypt(encryptedResponse, false);
        let parsed = JSON.parse(decryptedData);
        const {
            payload={}, expirationTimestamp=0
        } = parsed;
        if(expirationTimestamp >= new Date().getTime()){
            let {
                validationMap={}, success=false
            } = payload;
            extra['questionnaireValidationMap'] = validationMap;
            if(!success){
                extra['questionnaireAuthError'] = true;
                extra['questionnaireAuthErrorMessage'] = 'Validation failed. please try again.';
            }
        }else{
            extra = {
                questionnaireAuthError : true,
                questionnaireAuthErrorMessage : 'Access key is expired. Refresh the page.'
            }
        }
    }
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                questionnaireAuthLoading : false,
                questionnaireAuthError : false,
                questionnaireAuthErrorMessage : '',
                ...extra
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                questionnaireAuthLoading : false,
                questionnaireAuthError : true,
                questionnaireAuthErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const inhouzSecurityGetDocumentVerificationSecret = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            getDocumentVerificationKeyLoading : true,
            getDocumentVerificationKeyError : false,
            getDocumentVerificationKeyErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/getdocumentverificationclientsecret`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        const {
            encryptedResponse=''
        } = request;
        let decryptedData = encryptDecrypt(encryptedResponse, false);
        let parsed = JSON.parse(decryptedData);
        const {
            payload={}, expirationTimestamp=0
        } = parsed;
        let extra={};
        if(expirationTimestamp >= new Date().getTime()){
            extra['documentVerificationClientSecret'] = payload['clientSecret'];
            extra['identityPlatformKey'] = payload['identityPlatformKey'];
        }else{
            extra = {
                getDocumentVerificationKeyError : true,
                getDocumentVerificationKeyErrorMessage : 'Access key is expired. Refresh the page.'
            }
        }
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                getDocumentVerificationKeyLoading : false,
                getDocumentVerificationKeyError : false,
                getDocumentVerificationKeyErrorMessage : '',
                ...extra
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                getDocumentVerificationKeyLoading : false,
                getDocumentVerificationKeyError : true,
                getDocumentVerificationKeyErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const inhouzSecurityRegisterDocumentVerification = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            registerDocumentVerificationLoading : true,
            registerDocumentVerificationError : false,
            registerDocumentVerificationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/registerdocumentverification`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                registerDocumentVerificationLoading : false,
                registerDocumentVerificationError : false,
                registerDocumentVerificationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                registerDocumentVerificationLoading : false,
                registerDocumentVerificationError : true,
                registerDocumentVerificationErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const inhouzSecurityDocumentVerificationLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_SECURITY_REDUCER',
        payload : {
            documentVerificationLoginLoading : true,
            documentVerificationLoginError : false,
            documentVerificationLoginErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzsecurity/documentverificationlogin`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                documentVerificationLoginLoading : false,
                documentVerificationLoginError : false,
                documentVerificationLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_SECURITY_REDUCER',
            payload : {
                documentVerificationLoginLoading : false,
                documentVerificationLoginError : true,
                documentVerificationLoginErrorMessage : request.errorMessage || ''
            }
        });
    }
}