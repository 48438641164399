import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';

export const SetInhouzPayHubReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : data
    });
}

export const resetInhouzPayHubReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_PAY_REDUCER'
    });
}

export const getInhouzPayments = (data={}, viewUrl='') => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            inhouzPaymentsLoading : true,
            inhouzPaymentsError : false,
            inhouzPaymentsErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzpay/payment/${viewUrl ? viewUrl : 'getpayments'}`, 
        'POST', 
        data,
        {},
        viewUrl === 'getsubscriptionrelatedpayments' ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                inhouzPaymentsLoading : false,
                inhouzPaymentsError : false,
                inhouzPaymentsErrorMessage : '',
                inhouzPayments : request.results || [],
                inhouzPaymentsTotalCount : request.totalCount || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                inhouzPaymentsLoading : false,
                inhouzPaymentsError : true,
                inhouzPaymentsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzPayment = (id='', urlPath='') => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            getInhouzPaymentLoading : true,
            getInhouzPaymentError : false,
            getInhouzPaymentErrorMessage : ''
        }
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/inhouzpay/payment/${urlPath ? urlPath : 'getpayment'}/${id}`,
        urlPath === 'getsubscriptionrelatedpayment' ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                getInhouzPaymentLoading : false,
                getInhouzPaymentError : false,
                getInhouzPaymentErrorMessage : '',
                inhouzPayment : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                getInhouzPaymentLoading : false,
                getInhouzPaymentError : true,
                getInhouzPaymentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const refundInhouzPayment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            paymentRefundLoading : true,
            paymentRefundError : false,
            paymentRefundErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/refund`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                paymentRefundLoading : false,
                paymentRefundError : false,
                paymentRefundErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                paymentRefundLoading : false,
                paymentRefundError : true,
                paymentRefundErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentPaymentMethods = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            getPaymentPaymentMethodsLoading : true,
            getPaymentPaymentMethodsError : false,
            getPaymentPaymentMethodsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentmethod/getpaymentmethods`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                getPaymentPaymentMethodsLoading : false,
                getPaymentPaymentMethodsError : false,
                getPaymentPaymentMethodsErrorMessage : '',
                paymentPaymentMethods : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                getPaymentPaymentMethodsLoading : false,
                getPaymentPaymentMethodsError : true,
                getPaymentPaymentMethodsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentLinkMetadata = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            paymentLinkMetadataLoading : true,
            paymentLinkMetadataError : false,
            paymentLinkMetadataErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/getpaymentlinkmetadata`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                paymentLinkMetadataLoading : false,
                paymentLinkMetadataError : false,
                paymentLinkMetadataErrorMessage : '',
                paymentLinkMetaData : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                paymentLinkMetadataLoading : false,
                paymentLinkMetadataError : true,
                paymentLinkMetadataErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const changeInhouzPayPaymentMethod = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            changePaymentMethodLoading : true,
            changePaymentMethodError : false,
            changePaymentMethodErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentmethod/changepaymentmethod`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                changePaymentMethodLoading : false,
                changePaymentMethodError : false,
                changePaymentMethodErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                changePaymentMethodLoading : false,
                changePaymentMethodError : true,
                changePaymentMethodErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const cachePaymentMetadata = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PAY_REDUCER',
        payload : {
            cachePaymentMetadataLoading : true,
            cachePaymentMetadataError : false,
            cachePaymentMetadataErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/cachepaymentmetadata`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                cachePaymentMetadataLoading : false,
                cachePaymentMetadataError : false,
                cachePaymentMetadataErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PAY_REDUCER',
            payload : {
                cachePaymentMetadataLoading : false,
                cachePaymentMetadataError : true,
                cachePaymentMetadataErrorMessage : request.errorMessage || ''
            }
        })
    }
}