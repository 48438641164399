import getRequest from '../../utils/requests/getRequest';
import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setSubscriptionAuthReducer = (data={}) => dispatch => {
    dispatch ({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : data
    });
}

export const resetSubscriptionAuthReducer = () => dispatch => {
    dispatch ({
        type : 'RESET_SUBSCRIPTION_AUTH_REDUCER'
    });
}

export const subscriptionLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            subscriberLoginLoading : true,
            subscriberLoginError : false,
            subscriberLoginErrorMessage : '',
            subscriberDataError: false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/weblogin`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        if(request['twoFactorRequired']){
            dispatch({
                type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
                payload : {
                    subscriberLoginLoading : false,
                    subscriberLoginError : false,
                    subscriberLoginErrorMessage : '',
                    twoFactorRequired : request['twoFactorRequired'],
                    validatorId : request['id']
                }
            });
        }else{
            dispatch({
                type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
                payload : {
                    subscriberLoginLoading : false,
                    subscriberLoginError : false,
                    subscriberLoginErrorMessage : ''
                }
            });
        }
        
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                subscriberLoginLoading : false,
                subscriberLoginError : true,
                subscriberLoginErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionValidateTwoFactor = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            validateTwoFactorLoading : true,
            validateTwoFactorError : false,
            validateTwoFactorErrorMessage : '',
            subscriberDataError : false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/validatetwofactor`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                validateTwoFactorLoading : false,
                validateTwoFactorError : false,
                validateTwoFactorErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                validateTwoFactorLoading : false,
                validateTwoFactorError : true,
                validateTwoFactorErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionResendTwoFactor = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            regenerateTwoFactorLoading : true,
            regenerateTwoFactorError : false,
            regenerateTwoFactorErrorMessage : '',
            subscriberDataError: false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/resendtwofactor`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                regenerateTwoFactorLoading : false,
                regenerateTwoFactorError : false,
                regenerateTwoFactorErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                regenerateTwoFactorLoading : false,
                regenerateTwoFactorError : true,
                regenerateTwoFactorErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionPasswordChangeRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            forgotPasswordLoading : true,
            forgotPasswordError : false,
            forgotPasswordErrorMessage : '',
            subscriberDataError : false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/forgotpassword`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                forgotPasswordLoading : false,
                forgotPasswordError : false,
                forgotPasswordErrorMessage : '',
                passwordChangeInProgress : true
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                forgotPasswordLoading : false,
                forgotPasswordError : true,
                forgotPasswordErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionChangePassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            changePasswordLoading : true,
            changePasswordError : false,
            changePasswordErrorMessage : '',
            subscriberDataError: false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/changepassword`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                changePasswordLoading : false,
                changePasswordError : false,
                changePasswordErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                changePasswordLoading : false,
                changePasswordError : true,
                changePasswordErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionGetUser = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            subcriberDataLoading : true,
            subscriberDataError : false,
            subscriberDataErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthorization/getloggedinuser`,
        'POST',
        data,
        {},
        'x-react'
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                subcriberDataLoading : false,
                subscriberDataError : false,
                subscriberDataErrorMessage : '',
                subscriberData : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                subcriberDataLoading : false,
                subscriberDataError : true,
                subscriberDataErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const subscriptionLogout = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
        payload : {
            logoutIsLoading : true,
            logoutError : false,
            logoutErrorMessage : '',
            subscriberDataError : false
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionauthentication/weblogout`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                logoutIsLoading : false,
                logoutError : false,
                logoutErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIPTION_AUTH_REDUCER',
            payload : {
                logoutIsLoading : false,
                logoutError : true,
                logoutErrorMessage : request.errorMessage || ''
            }
        });
    }
}