const initialState = {
    paymentGateways : [],
    paymentGatewayTotalCount : 0,
    getPaymentGatewaysLoading : false,
    getPaymentGatewaysError : false,
    getPaymentGatewaysErrorMessage : '',
    paymentGatewayInFocus : {},
    getPaymentGatewayLoading : false,
    getPaymentGatewayError : false,
    getPaymentGatewayErrorMessage : '',
    deletePaymentGatewayLoading : false,
    deletePaymentGatewayError : false,
    deletePaymentGatewayErrorMessage : '',
    createPaymentGatewayLoading : false,
    createPaymentGatewayError : false,
    createPaymentGatewayErrorMessage : '',
    updatePaymentGatewayLoading : false,
    updatePaymentGatewayError : false,
    updatePaymentGatewayErrorMessage : '',
    getPaymentDistributionAccountsLoading : false,
    getPaymentDistributionAccountsError : false,
    getPaymentDistributionAccountsErrorMessage : '',
    paymentDistributionAccounts : [],
    paymentDistributionAccountsTotalCount : 0,
    getPaymentDistributionAccountRequestsLoading : false,
    getPaymentDistributionAccountRequestsError : false,
    getPaymentDistributionAccountRequestsErrorMessage : '',
    paymentDistributionAccountRequests : [],
    paymentDistributionAccountRequestsTotalCount : 0,
    getPaymentDistributionAccountLoading : false,
    getPaymentDistributionAccountError : false,
    getPaymentDistributionAccountErrorMessage : '',
    paymentDistributionAccount : {},
    deleteDistributionAccountRequestLoading : false,
    deleteDistributionAccountRequestError : false,
    deleteDistributionAccountRequestErrorMessage : '',
    getPaymentDistributionAccountRequestLoading : false,
    getPaymentDistributionAccountRequestError : false,
    getPaymentDistributionAccountRequestErrorMessage : '',
    paymentDistributionAccountRequest : {},
    createPaymentDistributionAccountRequestLoading : false,
    createPaymentDistributionAccountRequestError : false,
    createPaymentDistributionAccountRequestErrorMessage : '',
    distributionRequestUser : {},
    getRequestPortalUserLoading : false,
    getRequestPortalUserError : false,
    getRequestPortalUserErrorMessage : '',
    getDistributionRequestPasswordLoading : false,
    getDistributionRequestPasswordError : false,
    getDistributionRequestPasswordErrorMessage : false,
    distributionRequestLoginLoading : false,
    distributionRequestLoginError : false,
    distributionRequestLoginErrorMessage : '',
    getDistributionAccountRequestLoading : false,
    getDistributionAccountRequestError : false,
    getDistributionAccountRequestErrorMessage : '',
    paymentDistributionAccountRequest : {},
    createPaymentDistributionAccountLoading : false,
    createPaymentDistributionAccountError : false,
    createPaymentDistributionAccountErrorMessage : '',
    createOnboardingLinkLoading : false,
    createOnboardingLinkError : false,
    createOnBoardingLinkErrorMessage : '',
    stripeAccountLinkUrl : '',
    distributionAccountValidationLoading : false,
    distributionAccountValidationError : false,
    distributionAccountValidationErrorMessage : '',
    deleteDistributionAccountLoading : false,
    deleteDistributionAccountError : false,
    deleteDistributionAccountErrorMessage : '',
    createLoginLinkLoading : false,
    createLoginLinkError : false,
    createLoginLinkErrorMessage : '',
    paymentAccountLoginUrl : '',
    selectedPaymentAccounts : [],
    activeDistribution : {
        currency : 'USD',
        environment : 'production',
        sendScheduleTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    runDistributionLoading : false,
    runDistributionError : false,
    runDistributionErrorMessage : '',
    getPaymentDistributionsLoading : false,
    getPaymentDistributionsError : false,
    getPaymentDistributionsErrorMessage : '',
    paymentDistributions : [],
    paymentDistributionsTotalCount : 0,
    getPaymentDistributionLoading : false,
    getPaymentDistributionError : false,
    getPaymentDistributionErrorMessage : '',
    paymentDistribution : {},
    getDistributionTopupsLoading : false,
    getDistributionTopupsError : false,
    getDistributionTopupsErrorMessage : '',
    distributionTopups : [],
    distributionTopupsTotalCount : 0,
    getDistributionPayoutsLoading : false,
    getDistributionPayoutsError : false,
    getDistributionPayoutsErrorMessage : '',
    distributionPayouts : [],
    distributionPayoutsTotalCount : 0,
    getGlobalApprovalWorkflowLoading : false,
    getGlobalApprovalWorkflowError : false,
    getGlobalApprovalWorkflowErrorMessage : '',
    globalApprovalWorkflow : {},
    updateApprovalWorkflowLoading : false,
    updateApprovalWorkflowError : false,
    updateApprovalWorflowErrorMessage : '',
    getApprovalRequestsLoading : false,
    getApprovalRequestsError : false,
    getApprovalRequestsErrorMessage : '',
    approvalRequests : [],
    approvalRequestsTotalCount : 0,
    getApprovalBreakdownLoading : false,
    getApprovalBreakdownError : false,
    getApprovalBreakdownErrorMessage : '',
    approvalBreakdown : {},
    pendingDistributionPayments : [],
    pendingDistributionPaymentsTotalCount : 0,
    pendingDistributionPaymentsLoading : false,
    pendingDistributionPaymentsError : false,
    pendingDistributionPaymentsErrorMessage : '',
    getApprovalRequestLoading : false,
    getApprovalRequestError : false,
    getApprovalRequestErrorMessage : '',
    approvalRequest : {},
    paymentDistributionApprovalLoading : false,
    paymentDistributionApprovalError : false,
    paymentDistributionApprovalErrorMessage : '',
    getPaymentDistributionFilesLoading : false,
    getPaymentDistributionFilesError : false,
    getPaymentDistributionFilesErrorMessage : '',
    paymentDistributionFiles : [],
    paymentDistributionFilesTotalCount : [],
    uploadPaymentDistributionFileLoading : false,
    uploadPaymentDistributionFileError : false,
    uploadPaymentDistributionFileErrorMessage : '',
    getPaymentDistributionFileLoading : false,
    getPaymentDistributionFileError : false,
    getPaymentDistributionFileErrorMessage : '',
    paymentDistributionFileBase64 : '',
    getOutgoingPaymentCategoriesLoading : false,
    getOutgoingPaymentCategoriesError : false,
    getOutgoingPaymentCategoriesErrorMessage : '',
    outgoingPaymentCategories : [],
    outgoingPaymentCategoriesTotalCount : 0,
    getOutgoingPaymentCategoryLoading : false,
    getOutgoingPaymentCategoryError : false,
    getOutgoingPaymentCategoryErrorMessage : '',
    outgoingPaymentCategoryInFocus : {},
    deleteOutgoingPaymentCategoryLoading : false,
    deleteOutgoingPaymentCategoryError : false,
    deleteOutgoingPaymentCategoryErrorMessage : '',
    createOutgoingPaymentCategoryLoading : false,
    createOutgoingPaymentCategoryError : false,
    createOutgoingPaymentCategoryErrorMessage : '',
    updateOutgoingPaymentCategoryLoading : false,
    updateOutgoingPaymentCategoryError : false,
    updateOutgoingPaymentCategoryErrorMessage : '',
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_GATEWAY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_GATEWAY_REDUCER':
            return initialState;
        default:
            return state;
    }
}