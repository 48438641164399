const initialState = {
    userManagementView : '',
    userInFocus : {notifyNewUser : true},
    userInFocusEdited : false,
    editUserLoading : false,
    editUserError : false,
    editUserErrorMessage : false,
    csvUploadResponse : {},
    sessionsLoading : false,
    sessionsError : false,
    sessionsErrorMessage : '',
    sessions : [],
    totalSessions : 0,
    deactivateUserLoading : false,
    deactivateUserError : false,
    deactivateUserErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_USER_MANAGEMENT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'EDIT_USER_UM_LOADING':
            return {
                ...state,
                editUserLoading : true,
                editUserError : false,
                editUserErrorMessage : '',
                csvUploadResponse : {}
            }
        case 'EDIT_USER_UM_ERROR':
            return {
                ...state,
                editUserLoading : false,
                editUserError : true,
                editUserErrorMessage : action.payload.errorMessage
            }
        case 'EDIT_USER_UM_SUCCESS':
            return {
                ...state,
                editUserLoading : false,
                editUserError : false,
                editUserErrorMessage : '',
                csvUploadResponse : action.payload
            }
        case 'COMPANY_USER_SESSIONS_LOADING':
            return {
                ...state,
                sessionsLoading : true,
                sessionsError : false,
                sessionsErrorMessage : ''
            }
        case 'COMPANY_USER_SESSIONS_ERROR':
            return {
                ...state,
                sessionsLoading : false,
                sessionsError : true,
                sessionsErrorMessage : action.payload.errorMessage
            }
        case 'GET_COMPANY_USER_SESSIONS_SUCCESS':
            return {
                ...state,
                sessionsLoading : false,
                sessionsError : false,
                sessionsErrorMessage : '',
                totalSessions : action.payload['total_count'] || 0,
                sessions : action.payload['results'] ? 
                [...state.sessions, ...action.payload['results']] 
                : 
                state.sessions
            }
        case 'END_COMPANY_USER_SESSIONS_SUCCESS':
            return {
                ...state,
                sessionsLoading : false,
                sessionsErrorMessage : '',
                sessionsError : false
            }
        default:
            return state;
    }
}