const initialState = {
    productionSchema : [],
    productionDataRules : [],
    productionTasks : [],
    devSchema : [],
    devDataRules : [],
    devTasks : [],
    schemaDefinition : {},
    editedSchemas : {},
    newSchemas : {},
    deletedSchemas : {},
    schemaEditIsNotSaved : false,
    editCollectionView : 'schema',
    schemaEditView : 'dev',
    dataRuleEditView : 'dev',
    scheduledTaskEditView : 'dev',
    submitEditCollectionLoading : false,
    submitEditCollectionError : false,
    submitEditCollectionBreakdown : {},
    collectionDescription : '',
    collectionName : '',
    collectionId : '',
    getDataRuleLoading : false,
    getDataRuleError : false,
    dataRule : {},
    scheduledTask : {},
    dataRuleDetailView : 'details',
    dataRuleEditIsNotSaved : false,
    dataRuleDeletePayload : {},
    dataRuleDeployPayload : {},
    schemaDeployPayload : {},
    scheduledTaskDeployPayload : {},
    scheduledTaskDeletePayload : {},
    permissionConfigurations : {},
    cloudConfigurations : {},
    permissionConfigEditNotSaved : false,
    cloudConfigurationsEditNotSaved : false
}

export default function (state = initialState, action){
    switch(action.type){
        case 'INITIALIZE_EDIT_COLLECTION':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_EDIT_COLLECTION_REDUCER_FIELD':
            return {
                ...state,
                [action.payload.field] : action.payload.value
            }
        case 'SUBMIT_EDITCOLLECTION_LOADING':
            return {
                ...state,
                submitEditCollectionLoading : true
            }
        case 'SUBMIT_EDITCOLLECTION_ERROR':
            return {
                ...state,
                submitEditCollectionLoading : false,
                submitEditCollectionError : true
            }
        case 'SUBMIT_EDITCOLLECTION_SUCCESS':
            return {
                ...state,
                submitEditCollectionLoading : false,
                submitEditCollectionError : false,
                ...action.payload.collection,
                submitEditCollectionBreakdown : action.payload
            }
        case 'GET_DATARULE_LOADING':
            return {
                ...state,
                getDataRuleLoading : true
            }
        case 'GET_DATARULE_ERROR':
            return {
                ...state,
                getDataRuleError : true,
                getDataRuleLoading : false
            }
        case 'GET_DATARULE_DATA':
            return {
                ...state,
                getDataRuleError : false,
                getDataRuleLoading : false,
                dataRule : action.payload
            }
        case 'RESET_EDIT_COLLECTION_REDUCER':
            return initialState;
        default:
            return state;
    }
}