const initialState = {
    navbarVH : 0
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_NAVBAR_VIEWHEIGHT':
         return {...state, navbarVH : action.payload}
        default:
            return state;
    }
}