import getRequest from '../utils/requests/getRequest';
import postRequest from '../utils/requests/postRequest';
import config from '../config/config';

export const getTeamMembers = () => async dispatch => {
    dispatch({
        type : 'GET_TEAM_MEMBERS'
    });
    let request = await getRequest(`${config.devServerProxy}/api/users/getcompanymembers`)
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TEAM_MEMBERS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_TEAM_MEMBERS_ERROR',
            payload : request
        })
    }
}

export const updateCompanySettings = (data={}) => async dispatch => {
    dispatch({
        type : 'COMPANY_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/company/update`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'UPDATE_COMPANY_SETTINGS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'COMPANY_ERROR',
            payload : request
        })
    }
}

export const getCompany = () => async (dispatch) => {
    dispatch({
        type : 'COMPANY_LOADING'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/company/get`
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_COMPANY_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'COMPANY_ERROR',
            payload : request
        })
    }
}

export const setCompanyReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_COMPANY_REDUCER',
        payload : data
    });
}

export const terminateAccount = (data={}, subscriptionService=false) => async dispatch => {
    dispatch({
        type : 'SET_COMPANY_REDUCER',
        payload : {
            accountTerminationLoading : true,
            accountTerminationError : false,
            accountTerminationErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/accounttermination/${subscriptionService ? 'subcriptionservicetermination' : 'inhouzsubscriptiontermination'}`, 
        'POST', 
        data,
        {},
        subscriptionService ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_COMPANY_REDUCER',
            payload : {
                accountTerminationLoading : false,
                accountTerminationError : false,
                accountTerminationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_COMPANY_REDUCER',
            payload : {
                accountTerminationLoading : false,
                accountTerminationError : true,
                accountTerminationErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const changePlanFunction = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_COMPANY_REDUCER',
        payload : {
            planChangeLoading : true,
            planChangeError : false,
            planChangeErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsubscription/changeplan`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_COMPANY_REDUCER',
            payload : {
                planChangeLoading : false,
                planChangeError : false,
                planChangeErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_COMPANY_REDUCER',
            payload : {
                planChangeLoading : false,
                planChangeError : true,
                planChangeErrorMessage : request.errorMessage || ''
            }
        })
    }
}