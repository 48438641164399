const initialState = {
    teamMembers : [],
    teamMembersLoading : false,
    teamMembersError : false,
    teamMembersErrorMessage : '',
    companyLoading : false,
    companyError : false,
    companyErrorMessage : '',
    companyInfo : {},
    companyEdited : false,
    settingsView : '',
    accountTerminationLoading : false,
    accountTerminationError : false,
    accountTerminationErrorMessage : '',
    planChangeLoading : false,
    planChangeError : false,
    planChangeErrorMessage : ''
}

export default function(state=initialState, action){
    switch(action.type){
        case 'GET_TEAM_MEMBERS':
            return {
                ...state,
                teamMembersLoading : true,
                teamMembersError : false,
                teamMembersErrorMessage : ''
            }
        case 'SET_TEAM_MEMBERS_ERROR':
            return {
                ...state,
                teamMembersLoading : false,
                teamMembersError : true,
                teamMembersErrorMessage : action.payload.errorMessage
            }
        case 'SET_TEAM_MEMBERS':
            return {
                ...state,
                teamMembersLoading : false,
                teamMembersError : false,
                teamMembersErrorMessage : '',
                teamMembers : action.payload
            }
        case 'COMPANY_LOADING':
            return {
                ...state,
                companyLoading : true,
                companyError : false,
                companyErrorMessage : '',
            }
        case 'COMPANY_ERROR':
            return {
                ...state,
                companyLoading : false,
                companyError : true,
                companyErrorMessage : action.payload['errorMessage'] || ''
            }
        case 'GET_COMPANY_SUCCESS':
            return {
                ...state,
                companyLoading : false,
                companyError : false,
                companyErrorMessage : '',
                companyInfo : action.payload
            }
        case 'UPDATE_COMPANY_SETTINGS_SUCCESS':
            return {
                ...state,
                companyLoading : false,
                companyError : false,
                companyErrorMessage : '',
            }
        case 'SET_COMPANY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}