const initialState = {
    paymentMethods : [],
    paymentMethodInFocus : {},
    paymentSettingsError : false,
    paymentSettingsErrorMessage : '',
    paymentSettingsLoading : false,
    paymentMethodTotalCount : 0,
    paymentMethodNameIsAvailable : true,
    stripeSetupIntentId : '',
    stripeSetupIntentSecret : '',
    stripePublicKey : '',
    connectedAccountId : '',
    paymentFormComplete : false,
    paymentMethodId : '',
    getSetupIntentLoading : false,
    getSetupIntentError : false,
    getSetupIntentErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'PAYMENT_SETTINGS_LOADING':
            return {
                ...state,
                paymentSettingsLoading : true,
                paymentSettingsError : false,
                paymentSettingsErrorMessage : ''
            }
        case 'PAYMENT_SETTINGS_ERROR':
            return {
                ...state,
                paymentSettingsLoading : false,
                paymentSettingsError : true,
                paymentSettingsErrorMessage : action.payload.errorMessage
            }
        case 'GET_PAYMENT_METHODS_SUCCESS':
            return {
                ...state,
                paymentSettingsLoading : false,
                paymentSettingsError : false,
                paymentSettingsErrorMessage : '',
                paymentMethods : action.payload['results'] || [],
                paymentMethodTotalCount : action.payload['total_count'] || 0
            }
        case 'GET_PAYMENT_METHOD_SUCCESS':
            return {
                ...state,
                paymentSettingsLoading : false,
                paymentSettingsError : false,
                paymentSettingsErrorMessage : '',
                paymentMethodInFocus : action.payload
            }
        case 'PAYMENT_METHODS_SUCCESS':
            return {
                ...state,
                paymentSettingsLoading : false,
                paymentSettingsError : false,
                paymentSettingsErrorMessage : '',
            }
        case 'SET_PAYMENT_SETTINGS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_SETTINGS_REDUCER':
            return initialState;
        default:
            return state;
    }
}