const initialState = {
    products : [],
    productTotalCount : 0,
    productInFocus : {},
    getProductLoading : false,
    getProductError : false,
    getProductErrorMessage : '',
    getProductsLoading : false,
    getProductsError : false,
    getProductsErrorMessage : '',
    createProductLoading : false,
    createProductError : false,
    createProductErrorMessage : '',
    updateProductLoading : false,
    updateProductError : false,
    updateProductErrorMessage : '',
    deleteProductLoading : false,
    deleteProductError : false,
    deleteProductErrorMessage : '',
    bulkProductUploadLoading : false,
    bulkProductUploadError : false,
    bulkProductUploadErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_PRODUCT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_PRODUCT_REDUCER':
            return initialState;
        default:
            return state;
    }
}