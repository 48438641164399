const initialState = {
    getCollectionLoading : false,
    getCollectionError : false,
    getCollectionData : {},
    deployCollectionLoading : false,
    deployCollectionError : false,
    deployCollectionMessage : {},
    secondaryCollection : {},
    secondaryCollectionLoading : false,
    secondaryCollectionError : false,
    getCollectionsLoading : false,
    collections : [],
    getCollectionsError : false,
    collectionsMap : {}
}

export default function (state = initialState, action){
    switch(action.type){
        case 'GET_COLLECTION_LOADING':
            return {...state, getCollectionLoading : true}
        case 'GET_COLLECTION_ERROR':
            return {
                ...state,
                getCollectionError : true,
                getCollectionLoading : false
            }
        case 'GET_COLLECTION_DATA':
            return {
                ...state,
                getCollectionData : action.payload,
                getCollectionError : false,
                getCollectionLoading : false
            }
        case 'DEPLOY_EDITCOLLECTION_LOADING':
            return {
                ...state,
                deployCollectionLoading : true
            }
        case 'DEPLOY_EDITCOLLECTION_ERROR':
            return {
                ...state,
                deployCollectionError : true,
                deployCollectionLoading : false
            }
        case 'DEPLOY_EDITCOLLECTION_SUCCESS':
            return {
                ...state,
                deployCollectionError : false,
                deployCollectionLoading : false,
                getCollectionData : action.payload.Collection,
                deployCollectionMessage : action.payload.deploymentMessage
            }
        case 'GET_COLLECTIONS_LOADING':
            return {...state, getCollectionsLoading : true}
        case 'GET_COLLECTIONS_ERROR':
            return {
                ...state,
                getCollectionsError : true,
                getCollectionsLoading : false
            }
        case 'GET_COLLECTIONS_DATA':
            return {
                ...state,
                collections : action.payload,
                getCollectionsError : false,
                getCollectionsLoading : false
            }
        case 'GET_COLLECTIONS_AND_MAP':
            return {
                ...state,
                collectionsMap : {
                    ...state.collectionsMap,
                    [action.map] : action.payload
                },
                getCollectionsError : false,
                getCollectionsLoading : false
            }
        case 'REMOVE_MAPPED_COLLECTIONS':
            return {
                ...state,
                collectionsMap : {
                    ...state.collectionsMap,
                    [action.map] : []
                }
            }
        case 'GET_SECONDARY_COLLECTION_LOADING':
            return {...state, secondaryCollectionLoading : true}
        case 'GET_SECONDARY_COLLECTION_ERROR':
            return {
                ...state,
                secondaryCollectionError : true,
                secondaryCollectionLoading : false
            }
        case 'GET_SECONDARY_COLLECTION_DATA':
            return {
                ...state,
                secondaryCollection : action.payload,
                secondaryCollectionError : false,
                secondaryCollectionLoading : false
            }
        case 'RESET_GET_COLLECTION_REDUCER':
            return initialState;
        case 'SET_GET_COLLECTION_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}