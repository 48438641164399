export default function (cookieName=''){
    let map = {};
    let cookieString = document.cookie;
    let splitCookies = cookieString.split(';');
    for (let i = 0; i < splitCookies.length; i++){
        let cookieSetString = splitCookies[i];
        let subSplit = cookieSetString.split('=');
        if(
            subSplit.length < 2 ||
            subSplit.length > 2
        ){
            continue;
        }else{
            map[subSplit[0].trim()] = subSplit[1];
        }
    }
    return map[cookieName];
}