import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const uploadInhouzFile = (params={}) => async dispatch => {
    const {
        payload={},
        csrfCookieName='',
        uploadType=''
    } = params;
    dispatch({
        type : 'SET_INHOUZ_FILE_UPLOADER_REDUCER',
        payload : {
            fileUploadLoading : true,
            fileUploadError : false,
            fileUploadErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzfileupload/${uploadType.toLowerCase()}`, 'POST', payload, {}, csrfCookieName);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_FILE_UPLOADER_REDUCER',
            payload : {
                fileUploadLoading : false,
                fileUploadError : false,
                fileUploadErrorMessage : '',
                newFileKey : request['fileKey'],
                newFileUrl : request['signedUrl']
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_FILE_UPLOADER_REDUCER',
            payload : {
                fileUploadLoading : false,
                fileUploadError : true,
                fileUploadErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const resetInhouzFileUploaderReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_FILE_UPLOADER_REDUCER',
    });
}