const initialState = {
    appHubApps : [],
    appHubTotalCount : 0,
    appFolders : [],
    appInFocus : {},
    appFolderInFocus : {},
    getAppsLoading : false,
    getAppsError : false,
    getAppsErrorMessage : '',
    getFoldersLoading : false,
    getFoldersError : false,
    getFoldersErrorMessage : '',
    editAppFolderLoading : false,
    editAppFolderError : false,
    editAppFolderErrorMessage : '',
    folderNameAvailable : false,
    folderNameCheckLoading : false,
    folderNameCheckError : false,
    folderNameCheckErrorMessage : '',
    saveAppFolderLoading : false,
    saveAppFolderError : false,
    saveAppFolderErrorMessage : '',
    getAppFolderLoading : false,
    getAppFolderError : false,
    getAppFolderErrorMessage : '',
    deleteAppFolderLoading : false,
    deleteAppFolderError : false,
    deleteAppFolderErrorMessage : '',
    appInitObject : {},
    saveAppLoading : false,
    saveAppError : false,
    saveAppErrorMessage : '',
    appNameCheckLoading : false,
    appNameCheckError : false,
    appNameCheckErrorMessage : '',
    appNameIsAvailable : false,
    subdomainCheckLoading : false,
    subdomainCheckError : false,
    subdomainCheckErrorMessage : '',
    subdomainIsAvailable : false,
    getAppByNameLoading : false,
    getAppByNameError : false,
    getAppByNameErrorMessage : '',
    getAppByNameApps : [],
    cloneAppLoading : false,
    cloneAppError : false,
    cloneAppErrorMessage : ''
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_APP_HUB_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_APP_HUB_REDUCER':
            return initialState;
        case 'GET_APP_HUB_APPS_LOADING':
            return {
                ...state,
                getAppsLoading : true,
                getAppsError : false,
                getAppsErrorMessage : '',
            }
        case 'GET_APP_HUB_APPS_SUCCESS':
            return {
                ...state,
                getAppsLoading : false,
                getAppsError : false,
                getAppsErrorMessage : '',
                appHubApps : action.payload.results || [],
                appHubTotalCount : action.payload.total_count
            }
        case 'GET_APP_HUB_APPS_ERROR':
            return {
                ...state,
                getAppsError : true,
                getAppsErrorMessage : action.payload.errorMessage || 'Get apps error',
            }
        case 'GET_APP_FOLDERS_LOADING':
            return {
                ...state,
                getFoldersLoading : true,
                getFoldersError : false,
                getFoldersErrorMessage : ''
            }
        case 'GET_APP_FOLDERS_SUCCESS':
            return {
                ...state,
                getFoldersLoading : false,
                getFoldersError : false,
                getFoldersErrorMessage : '',
                appFolders : action.payload.results || []
            }
        case 'GET_APP_FOLDERS_ERROR':
            return {
                ...state,
                getFoldersLoading : false,
                getFoldersError : true,
                getFoldersErrorMessage : action.payload.errorMessage || 'App folders error',
            }
        case 'CHECK_APP_FOLDER_NAME_LOADING':
            return {
                ...state,
                folderNameCheckLoading : true,
                folderNameCheckError : false,
                folderNameCheckErrorMessage : ''
            }
        case 'CHECK_APP_FOLDER_NAME_SUCCESS':
            return {
                ...state,
                folderNameCheckLoading : false,
                folderNameCheckError : false,
                folderNameCheckErrorMessage : '',
                folderNameAvailable : action.payload.available || false
            }
        case 'CHECK_APP_FOLDER_NAME_ERROR':
            return {
                ...state,
                folderNameCheckLoading : false,
                folderNameCheckError : true,
                folderNameCheckErrorMessage : action.payload.errorMessage || 'An error occured',
                folderNameAvailable : false
            }
        case 'SAVE_APP_FOLDER_LOADING':
            return {
                ...state,
                saveAppFolderLoading : true,
                saveAppFolderError : false,
                saveAppFolderErrorMessage : ''
            }
        case 'SAVE_APP_FOLDER_SUCCESS':
            return {
                ...state,
                saveAppFolderLoading : false,
                saveAppFolderError : false,
                saveAppFolderErrorMessage : ''
            }
        case 'SAVE_APP_FOLDER_ERROR':
            return {
                ...state,
                saveAppFolderLoading : false,
                saveAppFolderError : true,
                saveAppFolderErrorMessage : action.payload.errorMessage || ''
            }
        case 'GET_APP_FOLDER_LOADING':
            return {
                ...state,
                getAppFolderLoading : true,
                getAppFolderError : false,
                getAppFolderErrorMessage : ''
            }
        case 'GET_APP_FOLDER_SUCCESS':
            return {
                ...state,
                getAppFolderLoading : false,
                getAppFolderError : false,
                getAppFolderErrorMessage : '',
                appFolderInFocus : action.payload || {}
            }
        case 'GET_APP_FOLDER_ERROR':
            return {
                ...state,
                getAppFolderLoading : false,
                getAppFolderError : true,
                getAppFolderErrorMessage : action.payload.errorMessage || '',
            }
        case 'DELETE_APP_FOLDER_LOADING':
            return {
                ...state,
                deleteAppFolderLoading : true,
                deleteAppFolderError : false,
                deleteAppFolderErrorMessage : ''
            }
        case 'DELETE_APP_FOLDER_SUCCESS':
            return {
                ...state,
                deleteAppFolderLoading : false,
                deleteAppFolderError : false,
                deleteAppFolderErrorMessage : ''
            }
        case 'DELETE_APP_FOLDER_ERROR':
            return {
                ...state,
                deleteAppFolderLoading : false,
                deleteAppFolderError : true,
                deleteAppFolderErrorMessage : action.payload.errorMessage || ''
            }
        case 'FIELD_NAME_CHECK_LOADING':
            return {
                ...state,
                [`${action.meta.field}CheckLoading`] : true,
                [`${action.meta.field}CheckError`] : false,
                [`${action.meta.field}CheckErrorMessage`] : ''
            }
        case 'FIELD_NAME_CHECK_ERROR':
            return {
                ...state,
                [`${action.meta.field}IsAvailable`] : false,
                [`${action.meta.field}CheckLoading`] : false,
                [`${action.meta.field}CheckError`] : true,
                [`${action.meta.field}CheckErrorMessage`] : action.payload.errorMessage || ''
            }
        case 'FIELD_NAME_CHECK_SUCCESS':
            return {
                ...state,
                [`${action.meta.field}IsAvailable`] : action.payload.available || false,
                [`${action.meta.field}CheckLoading`] : false,
                [`${action.meta.field}CheckError`] : false,
                [`${action.meta.field}CheckErrorMessage`] : ''
            }
        case 'CREATE_INHOUZ_APP_LOADING':
            return {
                ...state,
                saveAppLoading : true,
                saveAppError : false,
                saveAppErrorMessage : ''
            }
        case 'CREATE_INHOUZ_APP_ERROR':
            return {
                ...state,
                saveAppLoading : false,
                saveAppError : true,
                saveAppErrorMessage : action.payload.errorMessage || ''
            }
        case 'CREATE_INHOUZ_APP_SUCCESS':
            return {
                ...state,
                saveAppLoading : false,
                saveAppError : false,
                saveAppErrorMessage : '',
                appInitObject : action.payload
            }
        default:
            return state;
    }
}