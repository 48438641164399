export default function(state={}, action){
    switch(action.type){
        case 'GET_MODULE_FORMS':
            return {...state, moduleFormList : action.payload}
        case 'CLEAR_MODULE_FORMS':
            return {...state, moduleFormList : action.payload}
        case 'FORM_INSTANCE_CREATION_SUCCESS':
            return {...state, success : action.payload}
        case 'FORM_INSTANCE_CREATION_START':
            return {...state, isLoading : action.payload}
        case 'FORM_INSTANCE_CREATION_ERROR':
            return {...state, error : action.payload}
        case 'FILE_UPDATE_LOADING':
            return {...state, fileUpdateLoading : action.payload}
        case 'FILE_UPDATE_ERROR':
            return {...state, fileUpdateError : action.payload}
        default:
            return state;
    }
}