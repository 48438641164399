const initialState = {
    getReferralUsageLogsLoading : false,
    getReferralUsageLogsError : false,
    getReferralUsageLogErrorMessage : '',
    referralCodeUsageLogs : [],
    referralCodeUsageLogTotalCount : 0
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_REFERRAL_CODE_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_REFERRAL_CODE_REDUCER':
            return initialState;
        default:
            return state;
    }
}