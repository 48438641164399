import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';

export const SetPaymentBrandingReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : data
    });
}

export const resetPaymentBrandingReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_BRANDING_REDUCER'
    });
}

export const createPaymentPageDesign = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : {
            createPaymentPageDesignLoading : true,
            createPaymentPageDesignError : false,
            createPaymentPageDesignErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/design/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                createPaymentPageDesignLoading : false,
                createPaymentPageDesignError : false,
                createPaymentPageDesignErrorMessage : '',
                paymentPageDesignInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                createPaymentPageDesignLoading : false,
                createPaymentPageDesignError : true,
                createPaymentPageDesignErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const updatePaymentPageDesign = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : {
            updatePaymentPageDesignLoading : true,
            updatePaymentPageDesignError : false,
            updatePaymentPageDesignErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/design/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                updatePaymentPageDesignLoading : false,
                updatePaymentPageDesignError : false,
                updatePaymentPageDesignErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                updatePaymentPageDesignLoading : false,
                updatePaymentPageDesignError : true,
                updatePaymentPageDesignErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deletePaymentPageDesign = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : {
            deletePaymentPageDesignLoading : true,
            deletePaymentPageDesignError : false,
            deletePaymentPageDesignErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/design/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                deletePaymentPageDesignLoading : false,
                deletePaymentPageDesignError : false,
                deletePaymentPageDesignErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                deletePaymentPageDesignLoading : false,
                deletePaymentPageDesignError : true,
                deletePaymentPageDesignErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentPageDesign = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : {
            getPaymentPageDesignLoading : true,
            getPaymentPageDesignError : false,
            getPaymentPageDesignErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/design/getdesign`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                getPaymentPageDesignLoading : false,
                getPaymentPageDesignError : false,
                getPaymentPageDesignErrorMessage : '',
                paymentPageDesignInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                getPaymentPageDesignLoading : false,
                getPaymentPageDesignError : true,
                getPaymentPageDesignErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentPageDesigns = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_BRANDING_REDUCER',
        payload : {
            getPaymentPageDesignsLoading : true,
            getPaymentPageDesignsError : false,
            getPaymentPageDesignsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/design/getdesigns`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                getPaymentPageDesignsLoading : false,
                getPaymentPageDesignsError : false,
                getPaymentPageDesignsErrorMessage : '',
                paymentPageDesigns : request['results'] || [],
                paymentPageDesignsTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_BRANDING_REDUCER',
            payload : {
                getPaymentPageDesignsLoading : false,
                getPaymentPageDesignsError : true,
                getPaymentPageDesignsErrorMessage : request.errorMessage || ''
            }
        });
    }
}