const initialState = {
    screenWidth : 0,
    displayMode : localStorage.getItem('viewPortDisplayMode') || ''
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_WINDOW_DISPLAY_MODE':
            localStorage.setItem('viewPortDisplayMode', action.display);
            return {
                ...state,
                width : action.width,
                displayMode : action.display
            }
        default:
            return state;
    }
}