import getRequest from '../../utils/requests/getRequest';
import postRequest from '../../utils/requests/postRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import config from '../../config/config';

export const saveFileStorageService = (data={}) => async dispatch => {
    dispatch({
        type : 'FILE_STORAGE_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/filestorageservice/${data['_id'] ? 'edit' : 'create'}`,
        'POST',
        data
    )
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'FILE_STORAGE_SERVICE_SAVE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'FILE_STORAGE_SERVICE_ERROR',
            payload : request
        })
    }
}

export const getFileStorageService = (id='') => async dispatch => {
    dispatch({
        type : 'FILE_STORAGE_SERVICE_LOADING'
    });
    let request = await getRequest(`${config.devServerProxy}/api/filestorageservice/getservice/${id}`)
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_STORAGE_SERVICE',
            payload : request
        });
    }else{
        dispatch({
            type : 'FILE_STORAGE_SERVICE_ERROR',
            payload : request
        })
    }
}

export const getFileStorageServices = (data={}) => async dispatch => {
    dispatch({
        type : 'FILE_STORAGE_SERVICE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/filestorageservice/getservices`,
        'POST',
        data
    )
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_STORAGE_SERVICES',
            payload : request
        });
    }else{
        dispatch({
            type : 'FILE_STORAGE_SERVICE_ERROR',
            payload : request
        })
    }
}

export const deleteFileStorageService = (id='') => async dispatch => {
    dispatch({
        type : 'FILE_STORAGE_SERVICE_LOADING'
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/filestorageservice/deleteservice/${id}`)
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'FILE_STORAGE_SERVICE_SAVE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'FILE_STORAGE_SERVICE_ERROR',
            payload : request
        })
    }
}

export const setFileStorageServiceReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_FILE_STORAGE_SERVICE_REDUCER',
        payload : data
    })
}

export const resetFileStorageServiceReducer = () => dispatch => {
    dispatch({
        type : 'RESET_FILE_STORAGE_SERVICE_REDUCER'
    })
}