import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setFunctionBuilder = (field, data) => dispatch => {
    dispatch({
        type : 'SET_FUNCTION_BUILDER',
        field,
        data
    });
}

export const setFunctionBuilderObject = (payload, cb) => dispatch => {
    return new Promise((resolve) => {
        dispatch({
            type : 'SET_FUNCTION_BUILDER_OBJECT',
            payload
        });
        resolve();
    });
}

export const syncSetFunctionBuilderObject = (payload) => dispatch => {
    dispatch({
        type : 'SYNC_SET_FUNCTION_BUILDER_OBJECT',
        payload
    })
} 

export const getFunctions = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_FUNCTIONS'
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getfunctions`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_FUNCTIONS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_FUNCTIONS_ERROR'
        })
    }
}

export const getCloneFunctions = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FUNCTION_BUILDER_OBJECT',
        payload : {
            cloneFunctionsLoading : true,
            cloneFunctionsError : false,
            cloneFunctionsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getfunctions`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_FUNCTION_BUILDER_OBJECT',
            payload : {
                cloneFunctionsLoading : false,
                cloneFunctionsError : false,
                cloneFunctionsErrorMessage : '',
                cloneFunctionsList : request
            }
        });
    }else{
        dispatch({
            type : 'SET_FUNCTION_BUILDER_OBJECT',
            payload : {
                cloneFunctionsLoading : false,
                cloneFunctionsError : true,
                cloneFunctionsErrorMessage : request.errorMessage
            }
        });
    }
}

export const getFunctionVersions = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_FUNCTIONS'
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getFunctionVersions`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_FUNCTIONS_VERSIONS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_FUNCTIONS_ERROR'
        })
    }
}

export const changeFunctionVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'CHANGE_FUNCTION_VERSION_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/setfunctionversion`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'CHANGE_FUNCTION_VERSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'CHANGE_FUNCTION_VERSION_ERROR'
        })
    }
}

export const deployFunctionVersion = (data={}) => async dispatch => {
    dispatch({
        type : 'DEPLOY_FUNCTION_VERSION_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/deploy`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'DEPLOY_FUNCTION_VERSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'DEPLOY_FUNCTION_VERSION_ERROR'
        })
    }
}

export const getFunction = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_FUNCTIONS'
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getfunction`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_FUNCTION',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_FUNCTIONS_ERROR'
        })
    }
}

export const submitFunction = (
    data={}, submitType='', 
    encryptedCompanyId=''
) => async dispatch => {
    dispatch({
        type : 'SUBMIT_FUNCTION_START'
    });
    let request;
    if(submitType === 'create'){
        request = await postRequest(
            `${config.devServerProxy}/api/function/create`, 
            'POST', 
            {functionObject : data, encryptedCompanyId}
        );
    }else{
        request = await postRequest(
            `${config.devServerProxy}/api/function/edit`, 
            'POST', 
            {functionObject : data, encryptedCompanyId}
        );
    }
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SUBMIT_FUNCTION_SUCCESS',
            payload : request,
            submitType
        });
    }else{
        dispatch({
            type : 'SUBMIT_FUNCTION_ERROR'
        });
    }
}

export const getMappedFunctions = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_MAPPED_FUNCTIONS',
        componentId
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getfunctions`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_MAPPED_FUNCTIONS',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'SET_MAPPED_FUNCTIONS_ERROR',
            componentId
        })
    }
}

export const getMappedFunction = (data={}, componentId='') => async dispatch => {
    dispatch({
        type : 'GET_MAPPED_FUNCTIONS',
        componentId
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/getfunction`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_MAPPED_FUNCTION',
            payload : request,
            componentId
        });
    }else{
        dispatch({
            type : 'SET_MAPPED_FUNCTIONS_ERROR',
            componentId
        })
    }
}

export const clearMappedFunctions = (componentId) => dispatch => {
    dispatch ({
        type : 'CLEAR_MAPPED_FUNCTIONS',
        componentId
    });
}

export const resetFunctionBuilder = () => dispatch => {
    dispatch({
        type : 'RESET_FUNCTION_BUILDER'
    })
}

export const deleteInhouzFunction = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_FUNCTION_BUILDER_OBJECT',
        payload : {
            deleteFunctionLoading : true,
            deleteFunctionError : false,
            deleteFunctionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/function/deletefunction`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_FUNCTION_BUILDER_OBJECT',
            payload : {
                deleteFunctionLoading : false,
                deleteFunctionError : false,
                deleteFunctionErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_FUNCTION_BUILDER_OBJECT',
            payload : {
                deleteFunctionLoading : false,
                deleteFunctionError : true,
                deleteFunctionErrorMessage : request.errorMessage || ''
            }
        });
    }
}