import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setPaymentMetricsReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : data
    });
}

export const resetPaymentMetricsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_METRICS_REDUCER'
    });
}

export const getPaymentTopSectionMetrics = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            topSectionLoading : true,
            topSectionError : false,
            topSectionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/gettopsection`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : false,
                topSectionErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : true,
                topSectionErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentTimelineMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            paymentTimelineMetricsLoading : true,
            paymentTimelineMetricsError : false,
            paymentTimelineMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/paymenttimelinemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                paymentTimelineMetricsLoading : false,
                paymentTimelineMetricsError : false,
                paymentTimelineMetricsErrorMessage : '',
                paymentTimelineMetrics : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                paymentTimelineMetricsLoading : false,
                paymentTimelineMetricsError : true,
                paymentTimelineMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentPieMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            paymentPieMetricsLoading : true,
            paymentPieMetricsError : false,
            paymentPieMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/paymentpiemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                paymentPieMetricsLoading : false,
                paymentPieMetricsError : false,
                paymentPieMetricsErrorMessage : '',
                paymentPieMetrics : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                paymentPieMetricsLoading : false,
                paymentPieMetricsError : true,
                paymentPieMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getRecurringRevenueMetrics = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            recurringRevenueRevenueLoading : true,
            recurringRevenueRevenueError : false,
            recurringRevenueRevenueErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/recurringrevenuemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                recurringRevenueRevenueLoading : false,
                recurringRevenueRevenueError : false,
                recurringRevenueRevenueErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                recurringRevenueRevenueLoading : false,
                recurringRevenueRevenueError : true,
                recurringRevenueRevenueErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getRecurringRevenueTimelineMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            recurringRevenueTimelineLoading : true,
            recurringRevenueTimelineError : false,
            recurringRevenueTimelineErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/recurringrevenuetimelinemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                recurringRevenueTimelineLoading : false,
                recurringRevenueTimelineError : false,
                recurringRevenueTimelineErrorMessage : '',
                recurringRevenueTimelineMetrics : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                recurringRevenueTimelineLoading : false,
                recurringRevenueTimelineError : true,
                recurringRevenueTimelineErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getBaseSessionMetrics = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            baseSessionMetricsLoading : true,
            baseSessionMetricsError : false,
            baseSessionMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/basesessionmetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                baseSessionMetricsLoading : false,
                baseSessionMetricsError : false,
                baseSessionMetricsErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                baseSessionMetricsLoading : false,
                baseSessionMetricsError : true,
                baseSessionMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getAvgSessionDurationMetrics = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            avgSessionDurationLoading : true,
            avgSessionDurationError : false,
            avgSessionDurationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/avgsessionduration`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                avgSessionDurationLoading : false,
                avgSessionDurationError : false,
                avgSessionDurationErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                avgSessionDurationLoading : false,
                avgSessionDurationError : true,
                avgSessionDurationErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getSessionTimelineMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            sessionTimelineLoading : true,
            sessionTimelineError : false,
            sessionTimelineErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/sessiontimelinemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                sessionTimelineLoading : false,
                sessionTimelineError : false,
                sessionTimelineErrorMessage : '',
                sessionTimelineMetrics : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                sessionTimelineLoading : false,
                sessionTimelineError : true,
                sessionTimelineErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentSessionPieMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            sessionPieMetricsLoading : true,
            sessionPieMetricsError : false,
            sessionPieMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/sessionpiemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                sessionPieMetricsLoading : false,
                sessionPieMetricsError : false,
                sessionPieMetricsErrorMessage : '',
                sessionPieMetrics : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                sessionPieMetricsLoading : false,
                sessionPieMetricsError : true,
                sessionPieMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentConversionPieMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            conversionPieMetricsLoading : true,
            conversionPieMetricsError : false,
            conversionPieMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/conversionpiemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                conversionPieMetricsLoading : false,
                conversionPieMetricsError : false,
                conversionPieMetricsErrorMessage : '',
                conversionPieMetrics : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                conversionPieMetricsLoading : false,
                conversionPieMetricsError : true,
                conversionPieMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getTaxMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_METRICS_REDUCER',
        payload : {
            getTaxMetricsLoading : true,
            getTaxMetricsError : false,
            getTaxMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpaymetrics/taxmetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                getTaxMetricsLoading : false,
                getTaxMetricsError : false,
                getTaxMetricsErrorMessage : '',
                taxMetrics : request['results'] || []
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_METRICS_REDUCER',
            payload : {
                getTaxMetricsLoading : false,
                getTaxMetricsError : true,
                getTaxMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}