const initialState = {
    updateSubscriberProfileLoading : false,
    updateSubscriberProfileError : false,
    updateSubscriberProfileErrorMessage : '',
    getSubscriberProfileLoading : false,
    getSubscriberProfileError : false,
    getSubscriberProfileErrorMessage : '',
    subscriberProfileInFocus : {}
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_SUBSCRIPTION_SETTINGS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_SUBSCRIPTION_SETTINGS_REDUCER':
            return initialState;
        default:
            return state;
    }
}