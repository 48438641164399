import config from "../../config/config";

const initialState = {
    getMyWebComponentsLoading : false,
    getMyWebComponentsError : false,
    getMyWebComponentsErrorMessage : '',
    myWebComponents : [],
    updateWebComponentConfigLoading : false,
    updateWebComponentConfigError : false,
    updateWebComponentConfigErrorMessage : '',
    getDeployedWebComponentsLoading : false,
    getDeployedWebComponentsError : false,
    getDeployedWebComponentsErrorMessage : '',
    deployedWebComponents : [],
    viisibleComponents : 3
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_WEB_COMPONENT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_WEB_COMPONENT_REDUCER':
            return initialState;
        default:
            return state
    }
}