import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import promiseMiddleware from 'redux-promise';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import brace from 'brace';
import 'bootstrap/dist/js/bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-rangeslider/lib/index.css';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'easymde/dist/easymde.min.css';
import "./css/markDownEditor.css";
import "@carbon/charts/styles.css";
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/python';
import 'brace/mode/xml';
import 'brace/mode/ruby';
import 'brace/mode/sass';
import 'brace/mode/markdown';
import 'brace/mode/mysql';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/handlebars';
import 'brace/mode/golang';
import 'brace/mode/csharp';
import 'brace/mode/coffee';
import 'brace/mode/css';
import 'brace/mode/typescript';
import 'brace/mode/elixir';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/tomorrow';
import 'brace/theme/kuroir';
import 'brace/theme/twilight';
import 'brace/theme/xcode';
import 'brace/theme/textmate';
import 'brace/theme/solarized_dark';
import 'brace/theme/solarized_light';
import 'brace/theme/terminal';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



//components
import App from './components/app';

//import reducer
import reducer from './reducers';

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, thunk)(createStore);
export const store = createStoreWithMiddleware(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
, document.getElementById('root'));