import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import config from '../../config/config';

export const getPayoutAccounts = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/payoutaccount/getaccounts`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_PAYOUT_ACCOUNTS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_ERROR',
            payload : request
        })
    }
}

export const getPayoutAccount = (id='') => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_LOADING'
    });
    let request = await getRequest(`${config.devServerProxy}/api/payoutaccount/get/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_PAYOUT_ACCOUNT_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_ERROR',
            payload : request
        })
    }
}

export const savePayoutAccount = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/payoutaccount/${!data['new'] ? 'edit' : 'create'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYOUT_ACCOUNT_SUCCESS',
            payload : request,
            new : data['_id'] ? false : true
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_ERROR',
            payload : request
        })
    }
}

export const deletePayoutAccount = (id='') => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_LOADING'
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/payoutaccount/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYOUT_ACCOUNT_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_ERROR',
            payload : request
        })
    }
}

export const validatePayoutAccount = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_SANITY_CHECK_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/payoutaccount/sanitycheck`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYOUT_ACCOUNT_SANITY_CHECK_RESPONSE',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_SANITY_CHECK_ERROR',
            payload : request
        })
    }
}

export const generateStripeLink = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYOUT_ACCOUNT_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/payoutaccount/stripe/createaccountlink`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'STRIPE_ACCOUNT_LINK',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_ACCOUNT_ERROR',
            payload : request
        })
    }
}

export const setPayoutAccountReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_ACCOUNT_REDUCER',
        payload : data
    })
}

export const resetPayoutAccountReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PAYOUT_ACCOUNT_REDUCER'
    });
}

export const generateStripeDashboardUrl = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYOUT_STRIPE_DASHBOARD_LOADING'
    });
    let request = await postRequest(`${config.devServerProxy}/api/payoutaccount/stripe/getconnectaccountdashboardurl`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYOUT_STRIPE_DASHBOARD_LINK',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYOUT_STRIPE_DASHBOARD_ERROR',
            payload : request
        })
    }
}