import config from '../../config/config';

export default function(data, encrypt=true){
    try{
        let publicKey = config.NodeRsaPublicKey;
        let parsed;
        if(encrypt){
            parsed = publicKey.encrypt(data, 'base64');
        }else{
            parsed = publicKey.decryptPublic(data, 'utf8');
        }
        return parsed;
    }catch(e){
        console.log('encryptDecrypt error', e)
        return data;
    }
}