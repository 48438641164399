import {isEmpty} from 'lodash';
import {store} from '../../index';
import getCsrf from '../cookies/getCsrf';
import config from '../../config/config';

export default async function (user){
    let hasError = {hasError : true};

    if(user && !isEmpty(user) && !user.hasError && !user.error){
        return true;
    }

    store.dispatch({type : 'GET_LOGGED_IN_USER_LOADING'});
    let request = await fetch(`${config.devServerProxy}/api/authentication/getloggedinuser`, {
        method : 'GET',
        headers : {
            'x-csrfToken' : getCsrf()
        },
        credentials : 'include'
    })
    .then(res => {
        if(res.status === 200){
            return res.json();
        }
        return hasError;
    })
    .catch(e => {
        if(e){
            return hasError;
        }
    });

    if(request && !request.hasError && !request.error){
        store.dispatch({type : 'GET_LOGGED_IN_USER_SUCCESS', payload : request});
        return true;
    }else{
        store.dispatch({type : 'GET_LOGGED_IN_USER_ERROR', payload : {}});
        return false;
    }
}

