import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import config from '../../config/config';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';

export const getPaymentMethods = (data={}, subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'PAYMENT_SETTINGS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/${subscriptionPayment ? 'subscriptionpayment' : 'payment'}/getpaymentmethods`, 
        'POST', 
        data,
        {},
        subscriptionPayment ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_PAYMENT_METHODS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYMENT_SETTINGS_ERROR',
            payload : request
        })
    }
}

export const deletePaymentMethod = (data, subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'PAYMENT_SETTINGS_LOADING'
    });
    let request;
    if(subscriptionPayment){
        request = await postRequest(
            `${config.devServerProxy}/api/subscriptionpayment/deletepaymentmethod`, 
            'POST', 
            data,
            {},
            'x-react'
        );
    }else{
        request = await deleteRequest(
            `${config.devServerProxy}/api/payment/deletepaymentmethod/${data}`
        );
    }
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYMENT_METHODS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYMENT_SETTINGS_ERROR',
            payload : request
        })
    }
}

export const savePaymentMethod = (data={}, subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'PAYMENT_SETTINGS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/${subscriptionPayment ? 'subscriptionpayment' : 'payment'}/stripe/${data['_id'] ? 'editpaymentmethod' : 'addpaymentmethod'}`,
        'POST', 
        data,
        {},
        subscriptionPayment ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'PAYMENT_METHODS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYMENT_SETTINGS_ERROR',
            payload : request
        })
    }
}

export const getPaymentMethod = (id='', subscriptionPayment) => async dispatch => {
    dispatch({
        type : 'PAYMENT_SETTINGS_LOADING'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/${subscriptionPayment ? 'subscriptionpayment' : 'payment'}/getpaymentmethod/${id}`,
        subscriptionPayment ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_PAYMENT_METHOD_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PAYMENT_SETTINGS_ERROR',
            payload : request
        })
    }
}

export const setPaymentSettingsReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_SETTINGS_REDUCER',
        payload : data
    })
}

export const resetPaymentSettingsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_SETTINGS_REDUCER'
    })
}

export const setPrimaryPaymentMethod = (data={}) => async dispatch => {
    dispatch({
        type : 'PAYMENT_SETTINGS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/payment/setaccountprimary`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_SETTINGS_REDUCER',
            payload : {
                paymentSettingsLoading : false,
                paymentSettingsError : false,
                paymentSettingsErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'PAYMENT_SETTINGS_ERROR',
            payload : request
        })
    }
}

export const paymentSettingsSetupIntent = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_SETTINGS_REDUCER',
        payload : {
            getSetupIntentLoading : true,
            getSetupIntentError : false,
            getSetupIntentErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzpay/stripe/createsetupintent`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_SETTINGS_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : false,
                getSetupIntentErrorMessage : '',
                connectedAccountId : request['subMerchantAccountId'],
                stripeSetupIntentId : request['setupIntentId'],
                stripeSetupIntentSecret : encryptDecrypt(request['encyptedKey'], false),
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_SETTINGS_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : true,
                getSetupIntentErrorMessage : request.errorMessage || '',
            }
        });
    }
}