import React,{Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {webLogout, toggleLeftMenuDrawer, setNavbarVH} from '../../actions';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import config from '../../config/config';

class Header extends Component{

    state = {
        navbarProfileLinkDropDown : '',
        inDashboard : false,
        showDropdown : false
    }

    async componentDidMount(){
        if($('.mainNav')){
            this.setNavbarVH()
        }
        this.inDashboardCheck();
        this.unlisten = this.props.history.listen((location, action) => {
            this.inDashboardCheck();
        });
    }

    async componentDidUpdate(prevProps){
        if($('.mainNav')){
            this.setNavbarVH()
        }
    }

    componentWillUnmount(){
        if(this.unlisten){
            this.unlisten();
        }
    }

    handleUpgradeNavigation = () => {
        const {
            history
        } = this.props;
        if(history){
            history.push('/companysettings?view=change_plan');
        }
    }

    inDashboardCheck = () => {
        const {history} = this.props;
        const {location={}} = history;
        this.setState({
            inDashboard : location['pathname'] === '/dashboard'
        });
    }

    setNavbarVH = () => {
        let windowHeight = $(window).height();
        let navbarHeight = $('.mainNav').height();
        let VH = navbarHeight / windowHeight * 100;
        this.props.setNavbarVH(VH);
    }

    handleLogout = () => {
        this.toggleDropdown();
        if(sessionStorage.getItem('lastPath')){
            sessionStorage.removeItem('lastPath');
        }
        this.props.webLogout();
    }

    toggleDropdown = () => {
        this.setState({
            showDropdown : !this.state.showDropdown
        })
    }

    renderHeader = ({user, toggleLeftMenuDrawer}) =>{
        const {inDashboard=false, showDropdown=false} = this.state;
        const pathName = window.location.pathname;
        const userExists = user && user['_id'] ? true : false;
        const {imageUrl='', companyType=''} = user;
        if(
            !config.noVerifyUserRoutes.includes(pathName) && 
            !pathName.startsWith('/subscriptionservice/signup/') && 
            !pathName.startsWith('/subscriptionservice/accountsettings') && 
            !pathName.startsWith('/subscriptionservice/account/') && 
            !pathName.startsWith('/pay/') && 
            !pathName.startsWith('/customerportal') && 
            !pathName.startsWith('/inhouzsign/sign/') &&
            !pathName.startsWith('/paymentdistribution/accountrequest/') &&
            !pathName.startsWith('/pdfgeneration/')
        ){
            return(
                <div className="container-fluid d-flex align-items-center justify-content-between py-1 mainNav">
                    <div 
                        className='d-flex align-items-center'  
                    >
                        {
                            userExists &&
                            !inDashboard &&
                            <i 
                                className='material-icons inline-block cursorPointer text-secondary'
                                onClick={() =>toggleLeftMenuDrawer()}
                            >
                                menu
                            </i>
                        }
                        <Link
                            to='/dashboard'
                            style={{textDecoration:'none'}}
                        >
                            <img
                                src={`https://storage.googleapis.com/inhouz_general/inhouz_light_logo.svg`}
                                alt='Inhouz Cloud Services Logo'
                                className='ml-1'
                                style={{
                                    width : '120px'
                                }}
                            />
                        </Link>
                    </div>

                    {
                        userExists &&
                        <div
                            className='d-flex'
                        >
                            {
                                companyType &&
                                !['smb', 'enterprise'].includes(companyType) && 
                                <button
                                    // className='d-flex align-items-center btn btn-primary'
                                    className='d-flex align-items-center mr-2 p-1 px-3 rounded cursorPointer text-white'
                                    style={{
                                        outline : 'none',
                                        border : 'none',
                                        fontSize : '0.80rem',
                                        background : 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'
                                    }}
                                    onClick={() => this.handleUpgradeNavigation()}
                                >
                                    <i
                                        className='material-icons mr-1'
                                    >
                                        auto_awesome
                                    </i>
                                    <div
                                        style={{
                                            fontWeight : 'bold'
                                        }}
                                    >
                                        Upgrade
                                    </div>
                                </button>
                            }
                            <div 
                                style={{
                                    position : 'relative',
                                    display : 'inline-block'
                                }}
                            >
                                <div
                                    style={{
                                        cursor : 'pointer'
                                    }}
                                >
                                    {
                                        !imageUrl &&
                                        <span 
                                            className="cursorPointer"
                                            title='View profile'
                                            onClick={() => this.toggleDropdown()}
                                        >
                                            {user.email}
                                        </span>
                                    }
                                    {
                                        imageUrl && 
                                        <img 
                                            className="cursorPointer" 
                                            title='View profile'
                                            onClick={() => this.toggleDropdown()}
                                            src={imageUrl}
                                            style={{
                                                width : '30px',
                                                height : '30px',
                                                borderRadius : '50%',
                                                objectFit : 'cover'
                                            }}
                                        />
                                    }
                                </div>
                                {
                                    showDropdown && 
                                    <div
                                        className='bg-white'
                                        style={{
                                            position : 'absolute',
                                            zIndex : 10000000,
                                            right : 0
                                        }}
                                    >
                                        <Link 
                                            className='dropdown-item' to='/myprofile' 
                                            style={{display:'flex',justifyContent:'space-between'}}
                                            onClick={() => this.toggleDropdown()}
                                        >
                                            <i className="material-icons inline-block">account_circle</i>
                                            <div>Profile</div> 
                                        </Link>
                                        <div className='dropdown-item cursorPointer'  style={{display:'flex',justifyContent:'space-between'}} onClick={()=>this.handleLogout()}>
                                            <i className="material-icons">exit_to_app</i>
                                            <div>Logout</div> 
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            )
        }
        return null;
    }

    render(){
        return(
            <React.Fragment>
                {this.renderHeader(this.props)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state){
    return{
        user:state.Auth.user
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        webLogout, 
        toggleLeftMenuDrawer, 
        setNavbarVH
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));