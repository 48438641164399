import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';

export const setInhouzContactReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : data
    });
}

export const resetInhouzContactReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_CONTACT_REDUCER'
    });
}

export const createInhouzContact = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : {
            createInhouzContactLoading : true,
            createInhouzContactError : false,
            createInhouzContactErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/contacts/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                createInhouzContactLoading : false,
                createInhouzContactError : false,
                createInhouzContactErrorMessage : '',
                inhouzContact : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                createInhouzContactLoading : false,
                createInhouzContactError : true,
                createInhouzContactErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzContacts = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : {
            getInhouzContactsLoading : true,
            getInhouzContactsError : false,
            getInhouzContactsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/contacts/getcontacts`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                getInhouzContactsLoading : false,
                getInhouzContactsError : false,
                getInhouzContactsErrorMessage : '',
                inhouzContacts : request.results || [],
                inhouzContactsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                getInhouzContactsLoading : false,
                getInhouzContactsError : true,
                getInhouzContactsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzContact = (contactId) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : {
            getInhouzContactLoading : true,
            getInhouzContactError : false,
            getInhouzContactErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/contacts/getcontact/${contactId}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                getInhouzContactLoading : false,
                getInhouzContactError : false,
                getInhouzContactErrorMessage : '',
                inhouzContact : request,
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                getInhouzContactLoading : false,
                getInhouzContactError : true,
                getInhouzContactErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editInhouzContact = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : {
            editInhouzContactLoading : true,
            editInhouzContactError : false,
            editInhouzContactErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/contacts/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                editInhouzContactLoading : false,
                editInhouzContactError : false,
                editInhouzContactErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                editInhouzContactLoading : false,
                editInhouzContactError : true,
                editInhouzContactErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteInhouzContact = (contactId) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_CONTACT_REDUCER',
        payload : {
            deleteInhouzContactLoading : true,
            deleteInhouzContactError : false,
            deleteInhouzContactErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/contacts/delete/${contactId}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                deleteInhouzContactLoading : false,
                deleteInhouzContactError : false,
                deleteInhouzContactErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_CONTACT_REDUCER',
            payload : {
                deleteInhouzContactLoading : false,
                deleteInhouzContactError : true,
                deleteInhouzContactErrorMessage : request.errorMessage || ''
            }
        });
    }
}