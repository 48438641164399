const initialState = {
    viewInFocus : '',
    inhouzPayments : [],
    inhouzPaymentsTotalCount : 0,
    inhouzPaymentsLoading : false,
    inhouzPaymentsError : false,
    inhouzPaymentsErrorMessage : '',
    inhouzPayment : {},
    getInhouzPaymentLoading : false,
    getInhouzPaymentError : false,
    getInhouzPaymentErrorMessage : '',
    paymentRefundLoading : false,
    paymentRefundError : false,
    paymentRefundErrorMessage : '',
    refundChargeInFocus : {},
    inhouzPaymentsEnvironment : 'production',
    getPaymentPaymentMethodsLoading : false,
    getPaymentPaymentMethodsError : false,
    getPaymentPaymentMethodsErrorMessage : '',
    paymentPaymentMethods : [],
    paymentLinkMetadataLoading : false,
    paymentLinkMetadataError : false,
    paymentLinkMetadataErrorMessage : '',
    paymentLinkMetaData : {},
    changePaymentMethodLoading : false,
    changePaymentMethodError : false,
    changePaymentMethodErrorMessage : '',
    cachePaymentMetadataLoading : false,
    cachePaymentMetadataError : false,
    cachePaymentMetadataErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_PAY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_PAY_REDUCER':
            return initialState;
        default:
            return state;
    }
}