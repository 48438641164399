import getRequest from '../utils/requests/getRequest';
import postRequest from '../utils/requests/postRequest';
import deleteRequest from '../utils/requests/deleteRequest';

export async function getSavedFormElementStyles(){
    let request = await getRequest('/api/saveFormElement/get');
    return {
        type : 'GET_SAVED_FORM_ELEMENT',
        payload : request
    }
}

export const saveFormElementstyle = (element) => async dispatch => {
    dispatch({
        type : 'SAVE_REQUEST_IS_LOADING'
    })
    let request = await postRequest('/api/saveFormElement/save', 'POST', element);
    if(!request.hasError){
        dispatch({
            type : 'SAVE_FORM_ELEMENT_STYLE',
            payload : request
        })
    }else{
        dispatch({
            type : 'ERROR_SAVING_ELEMENT_STYLES'
        })
    }
    
}

export const editFormElementStyle = (element) => async dispatch => {
    dispatch({
        type : 'EDIT_REQUEST_IS_LOADING'
    })
    let request = await postRequest('/api/saveFormElement/put', 'PUT', element);
    if(!request.hasError){
        dispatch({
            type : 'EDIT_SAVED_FORM_ELEMENT_STYLE',
            payload : request
        })
    }else{
        dispatch({type : 'ERROR_EDITING_ELEMENT_STYLES'});
    }
}

export const deleteFormElementStyle = (id) => async dispatch => {
    dispatch({
        type : 'DELETE_REQUEST_IS_LOADING'
    })
    let request = await deleteRequest(`/api/saveFormElement/delete/${id}`);
    if(!request.hasError){
        dispatch({
            type : 'DELETE_FORM_ELEMENT_STYLE',
            payload : request
        })
    }else{
        dispatch({type : 'ERROR_DELETING_ELEMENT_STYLES'});
    }
}

export const clearDesignSaveErrorCache = (errorAction) => dispatch => {
    let type, type2;
    if(errorAction === 'saveAction'){
        type = 'ERROR_SAVING_ELEMENT_STYLES';
        type2='SAVE_REQUEST_IS_LOADING';
    }else if (errorAction === 'editAction'){
        type = 'ERROR_EDITING_ELEMENT_STYLES';
        type2 = 'EDIT_REQUEST_IS_LOADING';
    }else if(errorAction === 'deleteAction'){
        type = 'ERROR_DELETING_ELEMENT_STYLES';
        type2 = 'DELETE_REQUEST_IS_LOADING';
    }

    dispatch ({
        type, payload : false
    })
    dispatch({
        type : type2, payload : false
    })
}