import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';

export const setCustomerPortalReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : data
    });
}

export const resetCustomerPortalReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_CUSTOMER_PORTAL_REDUCER'
    });
}

export const getPortalMetadata = (subDomain) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getMetadataLoading : true,
            getMetadataError : false,
            getMetadataErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/customerportal/getportalmetadata/${subDomain}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getMetadataLoading : false,
                getMetadataError : false,
                getMetadataErrorMessage : '',
                portalMetadata : request,
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getMetadataLoading : false,
                getMetadataError : true,
                getMetadataErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getLoggedInCustomer = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getCustomerLoading : true,
            getCustomerError : false,
            getCustomerErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/customerportal/getloggedincustomer`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getCustomerLoading : false,
                getCustomerError : false,
                getCustomerErrorMessage : '',
                user : request,
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getCustomerLoading : false,
                getCustomerError : true,
                getCustomerErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const requestPortalPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            requestPasswordLoading : true,
            requestPasswordError : false,
            requestPasswordErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/customerportal/requestpassword`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : false,
                requestPasswordErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : true,
                requestPasswordErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const customerPortalLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            passwordLoginLoading : true,
            passwordLoginError : false,
            passwordLoginErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/customerportal/login`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : false,
                passwordLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : true,
                passwordLoginErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const customerPortalLogout = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            portalLogoutLoading : true,
            portalLogoutError : false,
            portalLogoutErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/customerportal/logout`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                portalLogoutLoading : false,
                portalLogoutError : false,
                portalLogoutErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                portalLogoutLoading : false,
                portalLogoutError : true,
                portalLogoutErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentSubscriber = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getPaymentSubscriberLoading : true,
            getPaymentSubscriberError : false,
            getPaymentSubscriberErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/getsubscriber`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscriberLoading : false,
                getPaymentSubscriberError : false,
                getPaymentSubscriberErrorMessage : '',
                paymentSubscriber : request
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscriberLoading : false,
                getPaymentSubscriberError : true,
                getPaymentSubscriberErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentSubscriberInFocus = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getSubscriberInFocusLoading : true,
            getSubscriberInFocusError : false,
            getSubscriberInFocusErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/getsubscriber`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getSubscriberInFocusLoading : false,
                getSubscriberInFocusError : false,
                getSubscriberInFocusErrorMessage : '',
                paymentSubscriberInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getSubscriberInFocusLoading : false,
                getSubscriberInFocusError : true,
                getSubscriberInFocusErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentSubscribers = (data={}, nonPortal=false) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getPaymentSubscribersLoading : true,
            getPaymentSubscribersError : false,
            getPaymentSubscribersErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/${nonPortal ? 'findpaymentsubscribers' : 'getsubscribers'}`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscribersLoading : false,
                getPaymentSubscribersError : false,
                getPaymentSubscribersErrorMessage : '',
                paymentSubscribers : request['results'] || [],
                paymentSubscribersTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscribersLoading : false,
                getPaymentSubscribersError : true,
                getPaymentSubscribersErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const findPaymentSubscribers = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            getPaymentSubscribersLoading : true,
            getPaymentSubscribersError : false,
            getPaymentSubscribersErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/findpaymentsubscribers`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscribersLoading : false,
                getPaymentSubscribersError : false,
                getPaymentSubscribersErrorMessage : '',
                paymentSubscribers : request['results'] || [],
                paymentSubscribersTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                getPaymentSubscribersLoading : false,
                getPaymentSubscribersError : true,
                getPaymentSubscribersErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editPaymentSubscriber = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            editSubscriberLoading : true,
            editSubscriberError : false,
            editSubscriberErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/editsubscriber`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                editSubscriberLoading : false,
                editSubscriberError : false,
                editSubscriberErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                editSubscriberLoading : false,
                editSubscriberError : true,
                editSubscriberErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const addPaymentSubscribers = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            addSubscriberLoading : true,
            addSubscriberError : false,
            addSubscriberErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/addsubscribers`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                addSubscriberLoading : false,
                addSubscriberError : false,
                addSubscriberErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                addSubscriberLoading : false,
                addSubscriberError : true,
                addSubscriberErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const cancelPaymentSubscriptions = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            cancelSubscriptionsLoading : true,
            cancelSubscriptionsError : false,
            cancelSubscriptionsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/subscription/cancelsubscriptions`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                cancelSubscriptionsLoading : false,
                cancelSubscriptionsError : false,
                cancelSubscriptionsErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                cancelSubscriptionsLoading : false,
                cancelSubscriptionsError : true,
                cancelSubscriptionsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const terminateSubscriptionPayment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            terminateSubscriptionPaymentLoading : true,
            terminateSubscriptionPaymentError : false,
            terminateSubscriptionPaymentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentlink/terminatesubscription`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                terminateSubscriptionPaymentLoading : false,
                terminateSubscriptionPaymentError : false,
                terminateSubscriptionPaymentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                terminateSubscriptionPaymentLoading : false,
                terminateSubscriptionPaymentError : true,
                terminateSubscriptionPaymentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const processOffSchedulePayment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            offSchedulePaymentLoading : true,
            offSchedulePaymentError : false,
            offSchedulePaymentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentlink/pay/offscheduledfixedflex`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                offSchedulePaymentLoading : false,
                offSchedulePaymentError : false,
                offSchedulePaymentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                offSchedulePaymentLoading : false,
                offSchedulePaymentError : true,
                offSchedulePaymentErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const payPastDueSubscriptionFee = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_CUSTOMER_PORTAL_REDUCER',
        payload : {
            pastDueSubscriptionPaymentLoading : true,
            pastDueSubscriptionPaymentError : false,
            pastDueSubscriptionPaymentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentlink/pay/subscriptionpayment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                pastDueSubscriptionPaymentLoading : false,
                pastDueSubscriptionPaymentError : false,
                pastDueSubscriptionPaymentErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_CUSTOMER_PORTAL_REDUCER',
            payload : {
                pastDueSubscriptionPaymentLoading : false,
                pastDueSubscriptionPaymentError : true,
                pastDueSubscriptionPaymentErrorMessage : request.errorMessage || ''
            }
        })
    }
}