const POST_LOGIN_CREDENTIALS = 'POST_LOGIN_CREDENTIALS';
const LOGOUT_SERVER = 'LOGOUT_SERVER'

const initialState = {
    user : {},
    getUserLoading : false,
    getUserError : false,
    getUserErrorMessage : '',
    signUpSuccess : false,
    signUpErrorMessage : '',
    signUpLoading : false,
    loginLoading : false,
    loginErrorMessage : '',
    loginResponse : {},
    loginHasError : false,
    validateTwoFactorLoading : false,
    validateTwoFactorError : false,
    validateTwoFactorErrorMessage : '',
    validateTwoFactorResponse : {},
    regenerateTwoFactorLoading : false,
    regenerateTwoFactorError : false,
    regenerateTwoFactorErrorMessage : '',
    forgotPasswordLoading : false,
    forgotPasswordError : false,
    forgotPasswordErrorMessage : '',
    forgotPasswordSuccess : false,
    resetPassword : '',
    newPassword : '',
    resetPasswordEmail : '',
    changePasswordLoading : false,
    changePasswordError : false,
    changePasswordErrorMessage : '',
    changePasswordSuccess : false,
    logoutIsLoading : false,
    logoutError : false,
    userProfileView : '',
    userProfileEdited : false,
    editUserProfileLoading : false,
    editUserProfileError : false,
    editUserProfileErrorMessage : '',
    apiKeyLoading : false,
    apiKeyError : false,
    apiKeyErrorMessage : false,
    apiKey : '',
    userAgent : {},
    validatePromoCodeLoading : false,
    validatePromoCodeError : false,
    validatePromoCodeErrorMessage : '',
    validatePromoCodeSuccessMessage : ''
}

export default function(state=initialState, action){
    switch(action.type){
        case POST_LOGIN_CREDENTIALS:
            return {...state, user:action.payload}
        case LOGOUT_SERVER:
            return {...state, user:action.payload}
        case 'API_KEY_LOADING':
            return {
                ...state,
                apiKeyLoading : true,
                apiKeyError : false,
                apiKeyErrorMessage : '',
                apiKey : ''
            }
        case 'API_KEY_ERROR':
            return {
                ...state,
                apiKeyLoading : false,
                apiKeyError : true,
                apiKeyErrorMessage : action.payload.errorMessage,
                apiKey : '',
                editUserProfileErrorMessage : ''
            }
        case 'GET_API_KEY_SUCCESS':
            return {
                ...state,
                apiKeyLoading : false,
                apiKeyError : false,
                apiKeyErrorMessage : '',
                apiKey : action.payload['apiKey'] || ''
            }
        case 'SIGN_UP_LOADING':
            return {
                ...state,
                signUpSuccess : false,
                signUpErrorMessage : '',
                signUpLoading : true
            }
        case 'SIGN_UP_SUCCESS':
            return {
                ...state,
                signUpSuccess : action.payload['success'] ? true : false,
                signUpErrorMessage : '',
                signUpLoading : false
            }
        case 'SIGN_UP_ERROR':
            return {
                ...state,
                signUpSuccess : false,
                signUpLoading : false,
                signUpErrorMessage : action.payload['errorMessage'] ? 
                action.payload['errorMessage'] : 'Sign up failed. Please try again.'
            }
        case 'WEB_LOGIN_LOADING':
            return {
                ...state,
                loginLoading : true,
                loginErrorMessage : '',
                loginHasError : false,
                loginResponse : {},
                getUserError : false,
                getUserErrorMessage : '',
            }
        case 'WEB_LOGIN_ERROR':
            return {
                ...state,
                loginLoading : false,
                loginHasError : true,
                loginErrorMessage : action.payload.errorMessage || '',
                loginResponse : {}
            }
        case 'WEB_LOGIN_SUCCESS':
            return {
                ...state,
                loginErrorMessage : '', 
                loginLoading : false,
                loginHasError : false,
                loginResponse : action.payload
            }
        case 'VALIDATE_TWO_FACTOR_LOADING':
            return {
                ...state,
                validateTwoFactorError : false,
                validateTwoFactorErrorMessage : '',
                validateTwoFactorLoading : true,
                validateTwoFactorResponse : {},
                regenerateTwoFactorError : false,
                regenerateTwoFactorErrorMessage : '',
                forgotPasswordError : false,
                forgotPasswordErrorMessage : '',
            }
        case 'VALIDATE_TWO_FACTOR_ERROR':
            return {
                ...state,
                validateTwoFactorError : true,
                validateTwoFactorErrorMessage : action.payload.errorMessage || '',
                validateTwoFactorLoading : false,
                validateTwoFactorResponse : {}
            }
        case 'VALIDATE_TWO_FACTOR_SUCCESS':
            return {
                ...state,
                validateTwoFactorError : false,
                validateTwoFactorErrorMessage : '',
                validateTwoFactorLoading : false,
                validateTwoFactorResponse : action.payload
            }
        case 'REGENERATE_TWO_FACTOR_LOADING':
            return {
                ...state,
                regenerateTwoFactorError : false,
                regenerateTwoFactorErrorMessage : '',
                regenerateTwoFactorLoading : true,
                validateTwoFactorError : false,
                validateTwoFactorErrorMessage : '',
                forgotPasswordError : false,
                forgotPasswordErrorMessage : '',
            }
        case 'REGENERATE_TWO_FACTOR_SUCCESS':
            return {
                ...state,
                regenerateTwoFactorError : false,
                regenerateTwoFactorErrorMessage : '',
                regenerateTwoFactorLoading : false,
                loginResponse : action.payload
            }
        case 'REGENERATE_TWO_FACTOR_LOADING':
            return {
                ...state,
                regenerateTwoFactorError : false,
                regenerateTwoFactorErrorMessage : action.payload.errorMessage || '',
                regenerateTwoFactorLoading : true
            }
        case 'FORGOT_PASSWORD_LOADING':
            return {
                ...state,
                forgotPasswordError : false,
                forgotPasswordErrorMessage : '',
                forgotPasswordSuccess : false,
                forgotPasswordLoading : true
            }
        case 'FORGOT_PASSWORD_ERROR':
            return {
                ...state,
                forgotPasswordError : true,
                forgotPasswordErrorMessage : action.payload.errorMessage || '',
                forgotPasswordSuccess : false,
                forgotPasswordLoading : false
            }
        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                forgotPasswordError : false,
                forgotPasswordErrorMessage : '',
                forgotPasswordSuccess : true,
                forgotPasswordLoading : false
            }
        case 'CHANGE_PASSWORD_LOADING':
            return {
                ...state,
                changePasswordError : false,
                changePasswordErrorMessage : '',
                changePasswordLoading : true,
                changePasswordSuccess : false
            }
        case 'CHANGE_PASSWORD_ERROR':
            return {
                ...state,
                changePasswordError : true,
                changePasswordErrorMessage : action.payload.errorMessage || '',
                changePasswordLoading : false,
                changePasswordSuccess : false
            }
        case 'CHANGE_PASSWORD_SUCCESS':
            return {
                ...state,
                changePasswordError : false,
                changePasswordErrorMessage : '',
                changePasswordLoading : false,
                changePasswordSuccess : true
            }
        case 'GET_LOGGED_IN_USER_LOADING':
            return {
                ...state,
                getUserError : false,
                getUserLoading : true,
                getUserErrorMessage : ''
            }
        case 'GET_LOGGED_IN_USER_ERROR':
            return {
                ...state,
                getUserError : true,
                getUserErrorMessage : action.payload.errorMessage || '',
                getUserLoading : false
            }
        case 'GET_LOGGED_IN_USER_SUCCESS':
            return {
                ...state,
                getUserError : false,
                getUserErrorMessage : '',
                getUserLoading : false,
                user : action.payload
            }
        case 'EDIT_USER_PROFILE_LOADING':
            return {
                ...state,
                editUserProfileError : false,
                editUserProfileLoading : true,
                editUserProfileErrorMessage : false
            }
        case 'EDIT_USER_PROFILE_ERROR':
            return {
                ...state,
                editUserProfileErrorMessage : action.payload.errorMessage || '',
                editUserProfileError : true,
                editUserProfileLoading : false,
                apiKeyErrorMessage : ''
            }
        case 'EDIT_USER_PROFILE_SUCCESS':
            return {
                ...state,
                editUserProfileErrorMessage : '',
                editUserProfileError : false,
                editUserProfileLoading : false,
                userProfileEdited : false
            }
        case 'WEB_LOGOUT_LOADING':
            return {
                ...state,
                logoutIsLoading : true,
                logoutError : false
            }
        case 'WEB_LOGOUT_ERROR':
            return {
                ...state,
                logoutIsLoading : false,
                logoutError : true
            }
        case 'WEB_LOGOUT_SUCCESS':
            return {
                ...state,
                logoutIsLoading : false,
                logoutError : false,
                user : {}
            }
        case 'SET_AUTH_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_AUTH_REDUCER':
            return initialState;
        default:
            return state;
    }
}