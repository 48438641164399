const initialState = {
    paymentMetricsQuery : {
        environment : 'production',
        dateRangeType : 'month',
        paymentOrigin : '',
        paymentOriginId : '',
        subscriptionServiceTierId : '',
        currency : 'USD',
        startDate : 0,
        endDate : 0
    },
    subcriptionTiers : [],
    topSectionMetrics : {},
    topSectionDeltaMetrics : {},
    topSectionLoading : false,
    topSectionError : false,
    topSectionErrorMessage : '',
    paymentTimelineMetrics : [],
    paymentTimelineMetricsLoading : false,
    paymentTimelineMetricsError : false,
    paymentTimelineMetricsErrorMessage : '',
    paymentPieMetrics : {},
    paymentPieMetricsLoading : false,
    paymentPieMetricsError : false,
    paymentPieMetricsErrorMessage : '',
    recurringRevenueMetrics : {},
    recurringRevenueDeltaMetrics : {},
    recurringRevenueRevenueLoading : false,
    recurringRevenueRevenueError : false,
    recurringRevenueRevenueErrorMessage : '',
    recurringRevenueTimelineMetrics : {},
    recurringRevenueTimelineLoading : false,
    recurringRevenueTimelineError : false,
    recurringRevenueTimelineErrorMessage : '',
    baseSessionMetrics : {},
    baseSessionDeltaMetrics : {},
    baseSessionMetricsLoading : false,
    baseSessionMetricsError : false,
    baseSessionMetricsErrorMessage : '',
    avgSessionDurationMetrics : {},
    avgSessionDurationDeltaMetrics : {},
    avgSessionDurationLoading : false,
    avgSessionDurationError : false,
    avgSessionDurationErrorMessage : '',
    sessionTimelineMetrics : [],
    sessionTimelineLoading : false,
    sessionTimelineError : false,
    sessionTimelineErrorMessage : '',
    sessionPieMetrics : {},
    sessionPieMetricsLoading : false,
    sessionPieMetricsError : false,
    sessionPieMetricsErrorMessage : '',
    conversionPieMetrics : {},
    conversionPieMetricsLoading : false,
    conversionPieMetricsError : false,
    conversionPieMetricsErrorMessage : '',
    taxMetrics : [],
    getTaxMetricsLoading : false,
    getTaxMetricsError : false,
    getTaxMetricsErrorMessage : ''
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_METRICS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_METRICS_REDUCER':
            return initialState;
        default:
            return state;
    }
}