const initialState = {
    subscriptionServices : [],
    isLoading : false,
    serviceHasError : false,
    serviceErrorMessage : '',
    subscriptionServicesTotalCount : 0,
    subscriptionServiceInFocus : {},
    subscriptionServiceView : '',
    externalSubscriptionsView : '',
    paymentTierInFocus : {},
    featureInFocus : {},
    subscriptionNameIsAvailable : true,
    signupUrlPathIsAvailable : true,
    accountSettingsSlugIsAvailable : true,
    trackerLoading : false,
    trackerError : false,
    trackerErrorMessage : '',
    subscriptionServiceTracker : {},
    subscriptionBaseView : '',
    serviceDiscountFormType : '',
    serviceDiscountInFocusId : '',
    migrationPaymentTierList : [],
    subscriberCountMap : {},
    addOnInFocus : {},
    planRegistrationData : {},
    planRegistrationActiveStep : 0,
    planRegistrationCurrency : '',
    planRegistrationCurrencyInitialized : false,
    onDemandPaymentAcknowledgement : false,
    signupSubscriptionServiceLoading : false,
    signupSubscriptionServiceError : false,
    signupSubscriptionServiceErrorMessage : '',
    signupSubscriptionService : {},
    planPriceData : {
        planPrice : 0,
        setupPrice : 0,
        overagePrice : 0,
        rateType : '',
        currencyOptions : [],
        isFree : false,
        perUser : false
    },
    signupSelectedAddonIds : [],
    addOnRateTypes : [],
    signupRateType : 'month',
    signupUserSelectionType : 'userForm', //userForm, userCsv, internalUsers
    userSignUpFormData : {
        isAdmin : true,
    },
    signupUserFormUsers : [],
    signupInternalUsers : [],
    csvDocument : null,
    csvDataMap : {},
    processedSignupCSVUsers : [],
    signupCSVUsersLoading : false,
    signupCSVUsersError : false,
    signupCSVUsersErrorMessage : '',
    signupCSVAdminEmail : '',
    signupBillingAddress : {
        country : 'US'
    },
    billingCardInfo : {},
    stripePaymentObject : {},
    extractPaymentDataTrigger : '',
    stripeError : false,
    signupPaymentBreakdown : {},
    signupLoading : false,
    signupError : false,
    signupErrorMessage : '',
    signupSuccessData : {},
    stripeSetupIntentId : '',
    stripeSetupIntentSecret : '',
    stripePublicKey : '',
    paymentFormComplete : false,
    paymentMethodId : '',
    getSetupIntentLoading : false,
    getSetupIntentError : false,
    getSetupIntentErrorMessage : '',
    subscriptionContractConsent : false,
    notifyInternalUsers : true,
    connectedSubscriptionServices : [],
    getConnectedServicesLoading : false,
    getConnectedServicesError : false,
    getConnectedServicesErrorMessage : '',
    createSubscriptionMigrationLoading : false,
    createSubscriptionMigrationError : false,
    createSubscriptionMigrationErrorMessage : '',
    userAllowList : [],
    userBlockList : [],
    getAllowListLoading : false,
    getAllowListError : false,
    getAllowListErrorMessage : '',
    getBlockListLoading : false,
    getBlockListError : false,
    getBlockListErrorMessage : '',
    userBlockListTotalCount : 0,
    userAllowListTotalCount : 0,
    addAllowListLoading : false,
    addAllowListError : false,
    addAllowListErrorMessage : '',
    addBlockListLoading : false,
    addBlockListError : false,
    addBlockListErrorMessage : '',
    deleteAllowListLoading : false,
    deleteAllowListError : false,
    deleteAllowListErrorMessage : '',
    deleteBlockListLoading : false,
    deleteBlockListError : false,
    deleteBlockListErrorMessage : '',
    subscriptionServiceList : [],
    getSubscriptionServiceListLoading : false,
    getSubscriptionServiceListError : false,
    getSubscriptionServiceListErrorMessage : '',
    cloneSubscriptionServiceLoading : false,
    cloneSubscriptionServiceError : false,
    cloneSubscriptionServiceErrorMessage : ''
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SUBSCRIPTION_SERVICE_LOADING':
            return {
                ...state,
                isLoading : true,
                serviceHasError : false,
                serviceErrorMessage : ''
            }
        case 'SET_SUBSCRIPTION_SERVICES_SUCCESS':
            return {
                ...state,
                isLoading : false,
                serviceHasError : false,
                serviceErrorMessage : '',
                subscriptionServicesTotalCount : action.payload['total_count'] || 0,
                subscriptionServices : action.payload.results
            }
        case 'SET_SUBSCRIPTION_SERVICE_SUCCESS':
            return {
                ...state,
                isLoading : false,
                serviceHasError : false,
                serviceErrorMessage : '',
                subscriptionServiceInFocus : action.payload
            }
        case 'SET_SUBSCRIPTION_SERVICE_ERROR':
            return {
                ...state,
                isLoading : false,
                serviceHasError : true,
                serviceErrorMessage : action.payload.errorMessage
            }
        case 'SET_SUBSCRIPTION_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_SUBSCRIPTION_SERVICE_TRACKER':
            return {
                ...state,
                trackerLoading : true,
                trackerError : false,
                trackerErrorMessage : ''
            }
        case 'SET_SUBSCRIPTION_SERVICE_TRACKER_ERROR':
            return {
                ...state,
                trackerLoading : false,
                trackerError : true,
                trackerErrorMessage : action.payload.errorMessage
            }
        case 'SET_SUBSCRIPTION_SERVICE_TRACKER_SUCCESS':
            return {
                ...state,
                trackerLoading : false,
                trackerErrorMessage : '',
                trackerError : false,
                subscriptionServiceTracker : action.payload
            }
        case 'CHANGE_SUBSCRIPTION_SERVICE_VERSION_SUCCESS':
            return {
                ...state,
                trackerLoading : false,
                trackerErrorMessage : '',
                trackerError : false
            }
        case 'DEPLOY_SUBSCRIPTION_SERVICE_VERSION_SUCCESS':
            return {
                ...state,
                isLoading : false,
                serviceHasError : false,
                serviceErrorMessage : ''
            }
        case 'RESET_SUBSCRIPTIONS_REDUCER':
            return initialState;
        default:
            return state;
    }
}