const initialState = {
    fileStorageServices : [],
    fileStorageServiceInFocus : {},
    fileStorageServiceError : false,
    fileStorageServiceErrorMessage : '',
    fileStorageServiceLoading : false,
    totalServices : 0,
    storageServiceNameIsAvailable : true
}

export default function(state=initialState, action){
    switch(action.type){
        case 'FILE_STORAGE_SERVICE_LOADING':
            return {
                ...state,
                fileStorageServiceLoading : true,
                fileStorageServiceError : false,
                fileStorageServiceErrorMessage : ''
            }
        case 'FILE_STORAGE_SERVICE_ERROR':
            return {
                ...state,
                fileStorageServiceLoading : false,
                fileStorageServiceError : true,
                fileStorageServiceErrorMessage : action.payload.errorMessage || ''
            }
        case 'FILE_STORAGE_SERVICE_SAVE_SUCCESS':
            return {
                ...state,
                fileStorageServiceLoading : false,
                fileStorageServiceError : false,
                fileStorageServiceErrorMessage : ''
            }
        case 'SET_STORAGE_SERVICE':
            return {
                ...state,
                fileStorageServiceLoading : false,
                fileStorageServiceError : false,
                fileStorageServiceErrorMessage : '',
                fileStorageServiceInFocus : action.payload
            }
        case 'SET_STORAGE_SERVICES':
            return {
                ...state,
                fileStorageServiceLoading : false,
                fileStorageServiceError : false,
                fileStorageServiceErrorMessage : '',
                fileStorageServices : action.payload['results'],
                totalServices : action.payload['total_count']
            }
        case 'SET_FILE_STORAGE_SERVICE_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_FILE_STORAGE_SERVICE_REDUCER':
            return initialState;
        default:
            return state;
    }
}