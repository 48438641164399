import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const signup = (data={}) => async dispatch => {
    dispatch({
        type : 'SIGN_UP_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/registercompany`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SIGN_UP_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SIGN_UP_ERROR',
            payload : request
        })
    }
}

export const webLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'WEB_LOGIN_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/login`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'WEB_LOGIN_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'WEB_LOGIN_ERROR',
            payload : request
        })
    }
}

export const webLogout = (data={}) => async dispatch => {
    dispatch({
        type : 'WEB_LOGOUT_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/logout`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'WEB_LOGOUT_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'WEB_LOGOUT_ERROR',
            payload : request
        })
    }
}

export const resetAuthReducer = () => dispatch => {
    dispatch({
        type : 'RESET_AUTH_REDUCER'
    });
}

export const setAuthReducer = (data) => dispatch => {
    dispatch({
        type : 'SET_AUTH_REDUCER',
        payload : data
    });
}

export const validateTwoFactor = (data={}) => async dispatch => {
    dispatch({
        type : 'VALIDATE_TWO_FACTOR_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/validatetwofactor`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'VALIDATE_TWO_FACTOR_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'VALIDATE_TWO_FACTOR_ERROR',
            payload : request
        })
    }
}

export const regenerateTwoFactorToken = (data={}) => async dispatch => {
    dispatch({
        type : 'REGENERATE_TWO_FACTOR_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/resendtwofactor`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'REGENERATE_TWO_FACTOR_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'REGENERATE_TWO_FACTOR_ERROR',
            payload : request
        })
    }
}

export const resetPasswordRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'FORGOT_PASSWORD_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/forgotpassword`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'FORGOT_PASSWORD_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'FORGOT_PASSWORD_ERROR',
            payload : request
        })
    }
}

export const setNewPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'CHANGE_PASSWORD_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/authentication/changepassword`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'CHANGE_PASSWORD_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'CHANGE_PASSWORD_ERROR',
            payload : request
        })
    }
}

export const getLoggedInUser = () => async (dispatch) => {
    dispatch({
        type : 'GET_LOGGED_IN_USER_LOADING'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/authentication/getloggedinuser`
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'GET_LOGGED_IN_USER_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_LOGGED_IN_USER_ERROR',
            payload : request
        })
    }
}

export const editUserProfile = (data) => async dispatch => {
    dispatch({
        type : 'EDIT_USER_PROFILE_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/users/edituser`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'EDIT_USER_PROFILE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'EDIT_USER_PROFILE_ERROR',
            payload : request
        })
    }
}

export const validateSignupDiscountCode = (data) => async dispatch => {
    dispatch({
        type : 'SET_AUTH_REDUCER',
        payload : {
            validatePromoCodeLoading : true,
            validatePromoCodeError : false,
            validatePromoCodeErrorMessage : '',
            validatePromoCodeSuccessMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzsubscription/validatediscountcode`, 
        'POST', 
        data
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_AUTH_REDUCER',
            payload : {
                validatePromoCodeLoading : false,
                validatePromoCodeError : false,
                validatePromoCodeErrorMessage : '',
                validatePromoCodeSuccessMessage : request['successMessage']
            }
        });
    }else{
        dispatch({
            type : 'SET_AUTH_REDUCER',
            payload : {
                validatePromoCodeLoading : false,
                validatePromoCodeError : true,
                validatePromoCodeErrorMessage : request['errorMessage'] || '',
                validatePromoCodeSuccessMessage : ''
            }
        })
    }
}