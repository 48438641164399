const initialState = {
    passwordLoginLoading : false,
    passwordLoginError : false,
    passwordLoginErrorMessage : '',
    requestPasswordLoading : false,
    requestPasswordError : false,
    requestPasswordErrorMessage : '',
    questionnaireAuthLoading : false,
    questionnaireAuthError : false,
    questionnaireAuthErrorMessage : '',
    questionnaireValidationMap : {},
    getDocumentVerificationKeyLoading : false,
    getDocumentVerificationKeyError : false,
    getDocumentVerificationKeyErrorMessage : '',
    documentVerificationClientSecret : '',
    identityPlatformKey : '',
    registerDocumentVerificationLoading : false,
    registerDocumentVerificationError : false,
    registerDocumentVerificationErrorMessage : '',
    documentVerificationLoginLoading : false,
    documentVerificationLoginError : false,
    documentVerificationLoginErrorMessage : '',
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_SECURITY_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_SECURITY_REDUCER':
            return initialState;
        default:
            return state;
    }
}