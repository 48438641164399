const initialState = {
    metricsQuery : {
        senderUserId : '',
        currency : 'USD',
        dateRangeType : 'month',
        startDate : 0,
        endDate : 0,
        projectId : '',
        environment : 'production'
    },
    topSectionLoading : false,
    topSectionError : false,
    topSectionErrorMessage : '',
    topSectionMetrics : {},
    topSectionDeltaMetrics : {},
    documentTimelineMetricsLoading : false,
    documentTimelineMetricsError : false,
    documentTimelineMetricsErrorMessage : '',
    documentTimelineMetrics : {},
    baseSessionMetricsLoading : false,
    baseSessionMetricsError : false,
    baseSessionMetricsErrorMessage : '',
    baseSessionMetrics : {},
    baseSessionDeltaMetrics : {},
    sessionPieMetrics : {},
    sessionPieMetricsLoading : false,
    sessionPieMetricsError : false,
    sessionPieMetricsErrorMessage : '',
    senderMetricsLoading : false,
    senderMetricsError : false,
    senderMetricsErrorMessage : '',
    senderMetricsData : [],
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_SIGN_METRICS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_SIGN_METRICS_REDUCER':
            return initialState;
        default:
            return state;
    }
}