const initialState = {
    createCollectionIsLoading : false,
    createCollectionError : false,
    collectionData : {}
}

export default function (state = initialState, action){
    switch(action.type){
        case 'CREATE_COLLECTION_LOADING':
            return {...state, createCollectionIsLoading : true}
        case 'CREATE_COLLECTION_ERROR':
            return {
                ...state,
                createCollectionIsLoading : false,
                createCollectionError : true
            }
        case 'CREATE_COLLECTION_DATA':
            return {
                ...state,
                createCollectionIsLoading : false,
                createCollectionError : false,
                collectionData : action.payload
            }
        case 'RESET_COLLECTION_REDUCER':
            return {
                ...state,
                createCollectionIsLoading : false,
                createCollectionError : false,
                collectionData : {}
            }
        default:
            return state;
    }
}