import getRequest from '../../../utils/requests/getRequest';
import postRequest from '../../../utils/requests/postRequest';
import config from '../../../config/config';

export const getCollection = (id='', prod=false) => async dispatch => {
    dispatch({type : 'GET_COLLECTION_LOADING'});
    let request = await getRequest(`${config.devServerProxy}/api/flexcollection/get/${id}/${prod}`);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'GET_COLLECTION_DATA',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_COLLECTION_ERROR'
        });
    }
}

export const getCollections = (data={}) => async dispatch => {
    dispatch({type : 'GET_COLLECTIONS_LOADING'});
    let request = await postRequest(`${config.devServerProxy}/api/flexcollection/get/getcollections`, 'POST', data);
    if(!request.hasError && !request.error){
        if(data.map){
            dispatch({
                type : 'GET_COLLECTIONS_AND_MAP',
                payload : request, 
                map : data.map
            })
        }else{
            dispatch({
                type : 'GET_COLLECTIONS_DATA',
                payload : request
            });
        }
    }else{
        dispatch({
            type : 'GET_COLLECTIONS_ERROR'
        });
    }
}

export const getSecondaryCollection = (id='', prod=false) => async dispatch => {
    dispatch({type : 'GET_SECONDARY_COLLECTION_LOADING'});
    let request = await getRequest(`${config.devServerProxy}/api/flexcollection/get/${id}/${prod}`);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'GET_SECONDARY_COLLECTION_DATA',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_SECONDARY_COLLECTION_ERROR'
        });
    }
}

export const removeMappedCollections = (map) => async dispatch => {
    dispatch({
        type : 'REMOVE_MAPPED_COLLECTIONS',
        map 
    });
}

export const resetGetCollectionReducer = () => async dispatch => {
    dispatch({
        type : 'RESET_GET_COLLECTION_REDUCER'
    });
}

export const setGetCollectionReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_GET_COLLECTION_REDUCER',
        payload : data
    })
}