const initialState = {
    savedDesignsList : [],
    saveRequestIsLoading : false,
    editRequestIsLoading : false,
    deleteRequestIsLoading : false,
    deleteFormElementStyle : {},
    editFormElementStyle : {},
    savedFormElementStyle : {}
}

export default function (state=initialState, action){
    switch(action.type){
        case 'GET_SAVED_FORM_ELEMENT':
            return {
                ...state,
                savedDesignsList : action.payload,
            }
        case 'SAVE_REQUEST_IS_LOADING':
            return {
                ...state,
                saveRequestIsLoading : action.payload || true
            }
        case 'EDIT_REQUEST_IS_LOADING':
            return {
                ...state,
                editRequestIsLoading : action.payload || true
            }
        case 'DELETE_REQUEST_IS_LOADING':
            return {
                ...state,
                deleteRequestIsLoading : action.payload || true
            }
        case 'SAVE_FORM_ELEMENT_STYLE':
            return {
                ...state,
                savedFormElementStyle : action.payload,
                saveRequestIsLoading : false,
                errorSavingElementStyles : false
            }
        case 'EDIT_SAVED_FORM_ELEMENT_STYLE':
            return {
                ...state,
                editFormElementStyle : action.payload,
                errorEditingElementStyles : false,
                editRequestIsLoading : false,
            }
        case 'DELETE_FORM_ELEMENT_STYLE':
            return {
                ...state,
                deleteFormElementStyle : action.payload,
                deleteRequestIsLoading : false,
                errorDeletingElementStyles : false
            }
        case 'ERROR_SAVING_ELEMENT_STYLES':
            return {
                ...state,
                errorSavingElementStyles : action.paylod || true
            }
        case 'ERROR_EDITING_ELEMENT_STYLES':
            return {
                ...state,
                errorEditingElementStyles : action.payload || true
            }
        case 'ERROR_DELETING_ELEMENT_STYLES':
            return {
                ...state,
                errorDeletingElementStyles : action.payload || true
            }
        default: 
            return state;
    }
}