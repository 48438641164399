const initialState = {
    databases : [],
    database : {},
    encryptedDatabase : {},
    getDatabasesLoading : false,
    getDatabasesError : false,
    createDatabaseError : false,
    createDatabaseLoading : false,
    createDatabaseSuccess : false,
    editDatabaseLoading : false,
    editDatabaseError : false,
    editDatabaseSuccess : false,
    getDatabaseLoading : false,
    getDatabaseError : false,
    deleteDatabaseError : false,
    deleteDatabaseSuccess : false,
    deleteDatabaseLoading : false,
    mappedDbList : {},
    connectionTestMap : {}
}

export default function (state = initialState, action){
    switch(action.type){
        case 'GET_DATABASES_LOADING':
            return {
                ...state,
                getDatabasesLoading : true
            }
        case 'GET_DATABASES_ERROR':
            return {
                ...state,
                getDatabasesLoading : false,
                getDatabasesError : true
            }
        case 'GET_DATABASES_DATA':
            return {
                ...state,
                getDatabasesError : false,
                getDatabasesLoading : false,
                databases : action.payload 
            }
        case 'CREATE_DATABASE_LOADING':
            return {
                ...state,
                createDatabaseLoading : true
            }
        case 'CREATE_DATABASE_ERROR':
            return {
                ...state,
                createDatabaseLoading : false,
                createDatabaseError : true
            }
        case 'CREATE_DATABASE_SUCCESS':
            return {
                ...state,
                createDatabaseError : false,
                createDatabaseLoading : false,
                createDatabaseSuccess : true
            }
        case 'EDIT_DATABASE_LOADING':
            return {
                ...state,
                editDatabaseLoading : true
            }
        case 'EDIT_DATABASE_SUCCESS':
            return {
                ...state,
                editDatabaseError : false,
                editDatabaseLoading : false,
                database : action.payload
            }
        case 'EDIT_DATABASE_ERROR':
            return {
                ...state,
                editDatabaseLoading : false,
                editDatabaseError : true
            }
        case 'EDIT_DATABASE_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'GET_DATABASE_LOADING':
            return {
                ...state,
                getDatabaseLoading : true
            }
        case 'GET_DATABASE_ERROR':
            return {
                ...state,
                getDatabaseError : true,
                getDatabaseLoading : false
            }
        case 'GET_DATABASE_DATA':
            return {
                ...state,
                getDatabaseError : false,
                getDatabaseLoading : false,
                encryptedDatabase : action.payload
            }
        case 'DELETE_DATABASE_LOADING':
            return {
                ...state,
                deleteDatabaseLoading : true
            }
        case 'DELETE_DATABASE_ERROR':
            return {
                ...state,
                deleteDatabaseLoading : false,
                deleteDatabaseError : true
            }
        case 'DELETE_DATABASE_SUCCESS':
            return {
                ...state,
                deleteDatabaseError : false,
                deleteDatabaseLoading : false,
                deleteDatabaseSuccess : true
            }
        case 'SET_MAPPED_DATABASES':
            return {
                ...state,
                mappedDbList : {
                    ...state.mappedDbList,
                    [action.componentId] : {
                        ...state.mappedDbList[action.componentId],
                        ...action.payload
                    }
                }
            }
        case 'TEST_DATABASE_CONNECTION':
            return {
                ...state,
                connectionTestMap : {
                    ...state.connectionTestMap,
                    [action.environment] : {
                        ...state.connectionTestMap[action.environment],
                        ...action.payload
                    }
                }
            }
        case 'RESET_DATABASE_REDUCER':
            return initialState;
        default: 
            return state;
    }
}