import getRequest from '../../utils/requests/getRequest';
import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const getSecrets = (data={}) => async dispatch => {
    dispatch ({type : 'GET_ALL_SECRETS'});
    let request = await postRequest(
        `${config.devServerProxy}/api/secret/getsecrets`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SECRETS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SECRETS_ERROR',
            payload : request
        })
    }
}

export const setSecretReducer = (data={}) => dispatch => {
    dispatch ({
        type : 'SET_SECRET_REDUCER',
        payload : data
    });
}

export const saveSecret = (data={}, cb) => async dispatch => {
    dispatch({
        type : 'GET_ALL_SECRETS'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/secret/${data['secretKeyId'] ? 'edit' : 'create'}`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SECRET_RESPONSE',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_SECRETS_ERROR',
            payload : request
        })
    }
    cb();
}