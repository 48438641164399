import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setPdfGeneratorMetricsReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
        payload : data
    });
}

export const resetPdfGeneratorMetricsReducer = () => dispatch => {
    dispatch({
        type : 'RESET_PDF_GENERATOR_METRICS_REDUCER'
    });
}

export const getPdfGeneratorTopSectionMetrics = (data={}, field) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
        payload : {
            topSectionLoading : true,
            topSectionError : false,
            topSectionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/metrics/topsection`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : false,
                topSectionErrorMessage : '',
                [field] : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
            payload : {
                topSectionLoading : false,
                topSectionError : true,
                topSectionErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPdfGeneratorTimelineMetrics = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
        payload : {
            documentTimelineMetricsLoading : true,
            documentTimelineMetricsError : false,
            documentTimelineMetricsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/pdfgenerator/metrics/timelinemetrics`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
            payload : {
                documentTimelineMetricsLoading : false,
                documentTimelineMetricsError : false,
                documentTimelineMetricsErrorMessage : '',
                documentTimelineMetrics : request,
            }
        });
    }else{
        dispatch({
            type : 'SET_PDF_GENERATOR_METRICS_REDUCER',
            payload : {
                documentTimelineMetricsLoading : false,
                documentTimelineMetricsError : true,
                documentTimelineMetricsErrorMessage : request.errorMessage || ''
            }
        })
    }
}