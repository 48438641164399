const initialState = {
    secrets : [],
    secretInFocus : {},
    isLoading : false,
    hasError : false,
    errorPayload : '',
    errorMessage : '',
    mappedSecret : {}
}

export default function (state=initialState, action){
    switch(action.type){
        case 'GET_ALL_SECRETS':
            return {
                ...state,
                isLoading : true,
                hasError : false,
                errorMessage : '',
                errorPayload : ''
            }
        case 'SET_SECRETS_SUCCESS':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                secrets : action.payload
            }
        case 'SET_SECRETS_ERROR':
            return {
                ...state,
                isLoading : false,
                hasError : true,
                errorMessage : action.payload.errorMessage,
                errorPayload : action.payload.errorPayload
            }
        case 'SET_SECRET_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_SECRET_RESPONSE':
            return {
                ...state,
                isLoading : false,
                hasError : false,
                errorMessage : '',
                errorPayload : '',
                secretInFocus : action.payload
            }
        default:
            return state;
    }
}