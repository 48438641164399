import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';

export const setTaxRuleReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : data
    });
}

export const resetTaxRuleReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_TAX_RULE_REDUCER'
    });
}

export const getTaxRules = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : {
            getTaxRulesLoading : true,
            getTaxRulesError : false,
            getTaxRulesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/taxrule/gettaxrules`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                getTaxRulesLoading : false,
                getTaxRulesError : false,
                getTaxRulesErrorMessage : '',
                taxRules : request.results || [],
                taxRuleTotalCount : request.totalCount || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                getTaxRulesLoading : false,
                getTaxRulesError : true,
                getTaxRulesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createTaxRule = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : {
            createTaxRuleLoading : true,
            createTaxRuleError : false,
            createTaxRuleErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/taxrule/createtaxrule`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                createTaxRuleLoading : false,
                createTaxRuleError : false,
                createTaxRuleErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                createTaxRuleLoading : false,
                createTaxRuleError : true,
                createTaxRuleErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editTaxRule = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : {
            updateTaxRuleLoading : true,
            updateTaxRuleError : false,
            updateTaxRuleErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/taxrule/edittaxrule`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                updateTaxRuleLoading : false,
                updateTaxRuleError : false,
                updateTaxRuleErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                updateTaxRuleLoading : false,
                updateTaxRuleError : true,
                updateTaxRuleErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deleteTaxRule = (id='') => async dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : {
            deleteTaxRuleLoading : true,
            deleteTaxRuleError : false,
            deleteTaxRuleErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/paymentgateway/taxrule/deletetaxrule/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                deleteTaxRuleLoading : false,
                deleteTaxRuleError : false,
                deleteTaxRuleErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                deleteTaxRuleLoading : false,
                deleteTaxRuleError : true,
                deleteTaxRuleErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getTaxRule = (id='') => async dispatch => {
    dispatch({
        type : 'SET_TAX_RULE_REDUCER',
        payload : {
            getTaxRuleLoading : true,
            getTaxRuleError : false,
            getTaxRuleErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/paymentgateway/taxrule/gettaxrule/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                getTaxRuleLoading : false,
                getTaxRuleError : false,
                getTaxRuleErrorMessage : '',
                taxRuleInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_TAX_RULE_REDUCER',
            payload : {
                getTaxRuleLoading : false,
                getTaxRuleError : true,
                getTaxRuleErrorMessage : request.errorMessage || ''
            }
        });
    }
}