import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const SetPaymentGatewayReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : data
    });
}

export const resetPaymentGatewayReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_GATEWAY_REDUCER',
        payload : data
    });
}

export const getPaymentGateways = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentGatewaysLoading : true,
            getPaymentGatewaysError : false,
            getPaymentGatewaysErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/gateway/getgateways`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentGatewaysLoading : false,
                getPaymentGatewaysError : false,
                getPaymentGatewaysErrorMessage : '',
                paymentGateways : request.results || [],
                paymentGatewayTotalCount : request.totalCount || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentGatewaysLoading : false,
                getPaymentGatewaysError : true,
                getPaymentGatewaysErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentGateway = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createPaymentGatewayLoading : true,
            createPaymentGatewayError : false,
            createPaymentGatewayErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/gateway/creategateway`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentGatewayLoading : false,
                createPaymentGatewayError : false,
                createPaymentGatewayErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentGatewayLoading : false,
                createPaymentGatewayError : true,
                createPaymentGatewayErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentGateway = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentGatewayLoading : true,
            getPaymentGatewayError : false,
            getPaymentGatewayErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/gateway/getgateway`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentGatewayLoading : false,
                getPaymentGatewayError : false,
                getPaymentGatewayErrorMessage : '',
                paymentGatewayInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentGatewayLoading : false,
                getPaymentGatewayError : true,
                getPaymentGatewayErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updatePaymentGateway = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            updatePaymentGatewayLoading : true,
            updatePaymentGatewayError : false,
            updatePaymentGatewayErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/gateway/editgateway`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updatePaymentGatewayLoading : false,
                updatePaymentGatewayError : false,
                updatePaymentGatewayErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updatePaymentGatewayLoading : false,
                updatePaymentGatewayError : true,
                updatePaymentGatewayErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deletePaymentGateway = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            deletePaymentGatewayLoading : true,
            deletePaymentGatewayError : false,
            deletePaymentGatewayErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/gateway/deletegateway`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deletePaymentGatewayLoading : false,
                deletePaymentGatewayError : false,
                deletePaymentGatewayErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deletePaymentGatewayLoading : false,
                deletePaymentGatewayError : true,
                deletePaymentGatewayErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionAccounts = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionAccountsLoading : true,
            getPaymentDistributionAccountsError : false,
            getPaymentDistributionAccountsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/inhouz/getdistributionaccounts`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountsLoading : false,
                getPaymentDistributionAccountsError : false,
                getPaymentDistributionAccountsErrorMessage : '',
                paymentDistributionAccounts : request['results'] || [],
                paymentDistributionAccountsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountsLoading : false,
                getPaymentDistributionAccountsError : true,
                getPaymentDistributionAccountsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionAccountRequests = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionAccountRequestsLoading : true,
            getPaymentDistributionAccountRequestsError : false,
            getPaymentDistributionAccountRequestsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/inhouz/getdistributionaccountrequests`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountRequestsLoading : false,
                getPaymentDistributionAccountRequestsError : false,
                getPaymentDistributionAccountRequestsErrorMessage : '',
                paymentDistributionAccountRequests : request['results'] || [],
                paymentDistributionAccountRequestsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountRequestsLoading : false,
                getPaymentDistributionAccountRequestsError : true,
                getPaymentDistributionAccountRequestsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentDistributionAccountRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createPaymentDistributionAccountRequestLoading : true,
            createPaymentDistributionAccountRequestError : false,
            createPaymentDistributionAccountRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/createdistributionaccountrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentDistributionAccountRequestLoading : false,
                createPaymentDistributionAccountRequestError : false,
                createPaymentDistributionAccountRequestErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentDistributionAccountRequestLoading : false,
                createPaymentDistributionAccountRequestError : true,
                createPaymentDistributionAccountRequestErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getDistributionRequestUser = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getRequestPortalUserLoading : true,
            getRequestPortalUserError : false,
            getRequestPortalUserErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/requestportal/getportaluser`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getRequestPortalUserLoading : false,
                getRequestPortalUserError : false,
                getRequestPortalUserErrorMessage : '',
                distributionRequestUser : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getRequestPortalUserLoading : false,
                getRequestPortalUserError : true,
                getRequestPortalUserErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getDistributionRequestPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getDistributionRequestPasswordLoading : true,
            getDistributionRequestPasswordError : false,
            getDistributionRequestPasswordErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/requestportal/passwordrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionRequestPasswordLoading : false,
                getDistributionRequestPasswordError : false,
                getDistributionRequestPasswordErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionRequestPasswordLoading : false,
                getDistributionRequestPasswordError : true,
                getDistributionRequestPasswordErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const distributionAccountRequestLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            distributionRequestLoginLoading : true,
            distributionRequestLoginError : false,
            distributionRequestLoginErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/requestportal/portallogin`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                distributionRequestLoginLoading : false,
                distributionRequestLoginError : false,
                distributionRequestLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                distributionRequestLoginLoading : false,
                distributionRequestLoginError : true,
                distributionRequestLoginErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionAccountRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getDistributionAccountRequestLoading : true,
            getDistributionAccountRequestError : false,
            getDistributionAccountRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/inhouz/getdistributionaccountrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionAccountRequestLoading : false,
                getDistributionAccountRequestError : false,
                getDistributionAccountRequestErrorMessage : '',
                paymentDistributionAccountRequest : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionAccountRequestLoading : false,
                getDistributionAccountRequestError : true,
                getDistributionAccountRequestErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deletePaymentDistributionAccountRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            deleteDistributionAccountRequestLoading : true,
            deleteDistributionAccountRequestError : false,
            deleteDistributionAccountRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/deletedistributionaccountrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteDistributionAccountRequestLoading : false,
                deleteDistributionAccountRequestError : false,
                deleteDistributionAccountRequestErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteDistributionAccountRequestLoading : false,
                deleteDistributionAccountRequestError : true,
                deleteDistributionAccountRequestErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionAccount = (data={}, fromSubscription=false) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionAccountLoading : true,
            getPaymentDistributionAccountError : false,
            getPaymentDistributionAccountErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/${fromSubscription ? 'subscription' : 'inhouz'}/getdistributionaccount`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountLoading : false,
                getPaymentDistributionAccountError : false,
                getPaymentDistributionAccountErrorMessage : '',
                paymentDistributionAccount : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionAccountLoading : false,
                getPaymentDistributionAccountError : true,
                getPaymentDistributionAccountErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentDistributionAccount = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createPaymentDistributionAccountLoading : true,
            createPaymentDistributionAccountError : false,
            createPaymentDistributionAccountErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/createaccount`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentDistributionAccountLoading : false,
                createPaymentDistributionAccountError : false,
                getPaymentDistributionAccountErrorMessage : '',
                paymentDistributionAccount : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createPaymentDistributionAccountLoading : false,
                createPaymentDistributionAccountError : true,
                createPaymentDistributionAccountErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createDistributionAccountOnboardingLink = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createOnboardingLinkLoading : true,
            createOnboardingLinkError : false,
            createOnBoardingLinkErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/createonboardinglink`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createOnboardingLinkLoading : false,
                createOnboardingLinkError : false,
                createOnBoardingLinkErrorMessage : '',
                stripeAccountLinkUrl : request['url']
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createOnboardingLinkLoading : false,
                createOnboardingLinkError : true,
                createOnBoardingLinkErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const validatePaymentDistributionAccount = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            distributionAccountValidationLoading : true,
            distributionAccountValidationError : false,
            distributionAccountValidationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/validateaccount`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                distributionAccountValidationLoading : false,
                distributionAccountValidationError : false,
                distributionAccountValidationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                distributionAccountValidationLoading : false,
                distributionAccountValidationError : true,
                distributionAccountValidationErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deletePaymentDistributionAccount = (data={}, fromSubscription) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            deleteDistributionAccountLoading : true,
            deleteDistributionAccountError : false,
            deleteDistributionAccountErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/paymentgateway/distribution/${fromSubscription ? 'deletesubscriptionaccount' : 'deletepaymentaccount'}`, 
        'POST', 
        data,
        {},
        fromSubscription ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteDistributionAccountLoading : false,
                deleteDistributionAccountError : false,
                deleteDistributionAccountErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteDistributionAccountLoading : false,
                deleteDistributionAccountError : true,
                deleteDistributionAccountErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const generatePaymentAccountLoginUrl = (data={}, fromSubscription) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createLoginLinkLoading : true,
            createLoginLinkError : false,
            createLoginLinkErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/paymentgateway/distribution/subscription/getdashboardlink`, 
        'POST', 
        data,
        {},
        fromSubscription ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createLoginLinkLoading : false,
                createLoginLinkError : false,
                createLoginLinkErrorMessage : '',
                paymentAccountLoginUrl : request['url'] || ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createLoginLinkLoading : false,
                createLoginLinkError : true,
                createLoginLinkErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const runPaymentDistribution = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            runDistributionLoading : true,
            runDistributionError : false,
            runDistributionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/distribute/run`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                runDistributionLoading : false,
                runDistributionError : false,
                runDistributionErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                runDistributionLoading : false,
                runDistributionError : true,
                runDistributionErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributions = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionsLoading : true,
            getPaymentDistributionsError : false,
            getPaymentDistributionsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/getpaymentdistributions`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionsLoading : false,
                getPaymentDistributionsError : false,
                getPaymentDistributionsErrorMessage : '',
                paymentDistributions : request['results'] || [],
                paymentDistributionsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionsLoading : false,
                getPaymentDistributionsError : true,
                getPaymentDistributionsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistribution = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionLoading : true,
            getPaymentDistributionError : false,
            getPaymentDistributionErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/getpaymentdistribution`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionLoading : false,
                getPaymentDistributionError : false,
                getPaymentDistributionErrorMessage : '',
                paymentDistribution : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionLoading : false,
                getPaymentDistributionError : true,
                getPaymentDistributionErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionTopups = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getDistributionTopupsLoading : true,
            getDistributionTopupsError : false,
            getDistributionTopupsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/getpaymentdistributionTopups`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionTopupsLoading : false,
                getDistributionTopupsError : false,
                getDistributionTopupsErrorMessage : '',
                distributionTopups : request['results'] || [],
                distributionTopupsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionTopupsLoading : false,
                getDistributionTopupsError : true,
                getDistributionTopupsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionPayouts = (data={}, urlPath='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getDistributionPayoutsLoading : true,
            getDistributionPayoutsError : false,
            getDistributionPayoutsErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/paymentgateway/distribution/${urlPath ? urlPath : 'inhouz'}/getdistributionpayouts`, 
        'POST', 
        data,
        {},
        urlPath === 'subscription' ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionPayoutsLoading : false,
                getDistributionPayoutsError : false,
                getDistributionPayoutsErrorMessage : '',
                distributionPayouts : request['results'] || [],
                distributionPayoutsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getDistributionPayoutsLoading : false,
                getDistributionPayoutsError : true,
                getDistributionPayoutsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionApprovalWorkflow = () => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getGlobalApprovalWorkflowLoading : true,
            getGlobalApprovalWorkflowError : false,
            getGlobalApprovalWorkflowErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/getglobalworkflow`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getGlobalApprovalWorkflowLoading : false,
                getGlobalApprovalWorkflowError : false,
                getGlobalApprovalWorkflowErrorMessage : '',
                globalApprovalWorkflow : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getGlobalApprovalWorkflowLoading : false,
                getGlobalApprovalWorkflowError : true,
                getGlobalApprovalWorkflowErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updatePaymentDistributionApprovalWorkflow = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            updateApprovalWorkflowLoading : true,
            updateApprovalWorkflowError : false,
            updateApprovalWorflowErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/updateworkflow`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updateApprovalWorkflowLoading : false,
                updateApprovalWorkflowError : false,
                updateApprovalWorflowErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updateApprovalWorkflowLoading : false,
                updateApprovalWorkflowError : true,
                updateApprovalWorflowErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionApprovalRequests = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getApprovalRequestsLoading : true,
            getApprovalRequestsError : false,
            getApprovalRequestsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/getapprovalrequests`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalRequestsLoading : false,
                getApprovalRequestsError : false,
                getApprovalRequestsErrorMessage : '',
                approvalRequests : request['results'] || [],
                approvalRequestsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalRequestsLoading : false,
                getApprovalRequestsError : true,
                getApprovalRequestsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionApprovalBreakdown = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getApprovalBreakdownLoading : true,
            getApprovalBreakdownError : false,
            getApprovalBreakdownErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/getapprovalbreakdown`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalBreakdownLoading : false,
                getApprovalBreakdownError : false,
                getApprovalBreakdownErrorMessage : '',
                approvalBreakdown : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalBreakdownLoading : false,
                getApprovalBreakdownError : true,
                getApprovalBreakdownErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPendingDistributionPayments = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            pendingDistributionPaymentsLoading : true,
            pendingDistributionPaymentsError : false,
            pendingDistributionPaymentsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/inhouz/getpendingpayouts`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                pendingDistributionPaymentsLoading : false,
                pendingDistributionPaymentsError : false,
                pendingDistributionPaymentsErrorMessage : '',
                pendingDistributionPayments : request['results'] || [],
                pendingDistributionPaymentsTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                pendingDistributionPaymentsLoading : false,
                pendingDistributionPaymentsError : true,
                pendingDistributionPaymentsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionApprovalRequest = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getApprovalRequestLoading : true,
            getApprovalRequestError : false,
            getApprovalRequestErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/getapprovalrequest`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalRequestLoading : false,
                getApprovalRequestError : false,
                getApprovalRequestErrorMessage : '',
                approvalRequest : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getApprovalRequestLoading : false,
                getApprovalRequestError : true,
                getApprovalRequestErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const paymentDistributionApproval = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            paymentDistributionApprovalLoading : true,
            paymentDistributionApprovalError : false,
            paymentDistributionApprovalErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/approval/approvalhandler`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                paymentDistributionApprovalLoading : false,
                paymentDistributionApprovalError : false,
                paymentDistributionApprovalErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                paymentDistributionApprovalLoading : false,
                paymentDistributionApprovalError : true,
                paymentDistributionApprovalErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionFiles = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionFilesLoading : true,
            getPaymentDistributionFilesError : false,
            getPaymentDistributionFilesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/getpaymentdistributionfiles`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionFilesLoading : false,
                getPaymentDistributionFilesError : false,
                getPaymentDistributionFilesErrorMessage : '',
                paymentDistributionFiles : request['results'] || [],
                paymentDistributionFilesTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionFilesLoading : false,
                getPaymentDistributionFilesError : true,
                getPaymentDistributionFilesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const uploadPaymentDistributionFile = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            uploadPaymentDistributionFileLoading : true,
            uploadPaymentDistributionFileError : false,
            uploadPaymentDistributionFileErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/fileupload`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                uploadPaymentDistributionFileLoading : false,
                uploadPaymentDistributionFileError : false,
                uploadPaymentDistributionFileErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                uploadPaymentDistributionFileLoading : false,
                uploadPaymentDistributionFileError : true,
                uploadPaymentDistributionFileErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getPaymentDistributionFile = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getPaymentDistributionFileLoading : true,
            getPaymentDistributionFileError : false,
            getPaymentDistributionFileErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/getpaymentdistributionfile`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionFileLoading : false,
                getPaymentDistributionFileError : false,
                getPaymentDistributionFileErrorMessage : '',
                paymentDistributionFileBase64 : request['base64'] || ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getPaymentDistributionFileLoading : false,
                getPaymentDistributionFileError : true,
                getPaymentDistributionFileErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getOutgoingPaymentCategories = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getOutgoingPaymentCategoriesLoading : true,
            getOutgoingPaymentCategoriesError : false,
            getOutgoingPaymentCategoriesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/category/getcategories`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getOutgoingPaymentCategoriesLoading : false,
                getOutgoingPaymentCategoriesError : false,
                getOutgoingPaymentCategoriesErrorMessage : '',
                outgoingPaymentCategories : request['results'] || [],
                outgoingPaymentCategoriesTotalCount : request['totalCount'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getOutgoingPaymentCategoriesLoading : false,
                getOutgoingPaymentCategoriesError : true,
                getOutgoingPaymentCategoriesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getOutgoingPaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            getOutgoingPaymentCategoryLoading : true,
            getOutgoingPaymentCategoryError : false,
            getOutgoingPaymentCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/category/getcategory`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getOutgoingPaymentCategoryLoading : false,
                getOutgoingPaymentCategoryError : false,
                getOutgoingPaymentCategoryErrorMessage : '',
                outgoingPaymentCategoryInFocus : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                getOutgoingPaymentCategoryLoading : false,
                getOutgoingPaymentCategoryError : true,
                getOutgoingPaymentCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteOutgoingPaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            deleteOutgoingPaymentCategoryLoading : true,
            deleteOutgoingPaymentCategoryError : false,
            deleteOutgoingPaymentCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/category/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteOutgoingPaymentCategoryLoading : false,
                deleteOutgoingPaymentCategoryError : false,
                deleteOutgoingPaymentCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                deleteOutgoingPaymentCategoryLoading : false,
                deleteOutgoingPaymentCategoryError : true,
                deleteOutgoingPaymentCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createOutgoingPaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            createOutgoingPaymentCategoryLoading : true,
            createOutgoingPaymentCategoryError : false,
            createOutgoingPaymentCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/category/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createOutgoingPaymentCategoryLoading : false,
                createOutgoingPaymentCategoryError : false,
                createOutgoingPaymentCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                createOutgoingPaymentCategoryLoading : false,
                createOutgoingPaymentCategoryError : true,
                createOutgoingPaymentCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updateOutgoingPaymentCategory = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_GATEWAY_REDUCER',
        payload : {
            updateOutgoingPaymentCategoryLoading : true,
            updateOutgoingPaymentCategoryError : false,
            updateOutgoingPaymentCategoryErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentgateway/distribution/category/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updateOutgoingPaymentCategoryLoading : false,
                updateOutgoingPaymentCategoryError : false,
                updateOutgoingPaymentCategoryErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_GATEWAY_REDUCER',
            payload : {
                updateOutgoingPaymentCategoryLoading : false,
                updateOutgoingPaymentCategoryError : true,
                updateOutgoingPaymentCategoryErrorMessage : request.errorMessage || ''
            }
        })
    }
}