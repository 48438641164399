const initialState = {
    paymentIntents : [],
    paymentIntentsTotalCount : 0,
    paymentIntentLoading : false,
    paymentIntentError : false,
    paymentIntentErrorMessage : '',
    paymentCharges : [],
    paymentChargesTotalCount : 0,
    paymentChargeLoading : false,
    paymentChargeError : false,
    paymentChargeErrorMessage : '',
    quickPayLoading : false,
    quickPayError : false,
    quickPayErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_TRANSACTION_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYMENT_TRANSACTION_REDUCER':
            return initialState;
        default:
            return state;
        }
}