import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';
import getRequest from '../../utils/requests/getRequest';
import deleteRequest from '../../utils/requests/deleteRequest';
import encryptDecrypt from '../../utils/cryptography/encryptDecrypt';

export const setPaymentLinkReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : data
    });
}

export const resetPaymentLinkReducer = (data={}) => dispatch => {
    dispatch({
        type : 'RESET_PAYMENT_LINK_REDUCER'
    });
}

export const getPaymentLinks = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getPaymentLinksLoading : true,
            getPaymentLinksError : false,
            getPaymentLinksErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/getpaymentlinks`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentLinksLoading : false,
                getPaymentLinksError : false,
                getPaymentLinksErrorMessage : '',
                paymentLinks : request.results || [],
                paymentLinkTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentLinksLoading : false,
                getPaymentLinksError : true,
                getPaymentLinksErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createPaymentLink = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            createPaymentLinkLoading : true,
            createPaymentLinkError : false,
            createPaymentLinkErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                createPaymentLinkLoading : false,
                createPaymentLinkError : false,
                createPaymentLinkErrorMessage : '',
                paymentLinkInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                createPaymentLinkLoading : false,
                createPaymentLinkError : true,
                createPaymentLinkErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updatePaymentLink = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            updatePaymentLinkLoading : true,
            updatePaymentLinkError : false,
            updatePaymentLinkErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                updatePaymentLinkLoading : false,
                updatePaymentLinkError : false,
                updatePaymentLinkErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                updatePaymentLinkLoading : false,
                updatePaymentLinkError : true,
                updatePaymentLinkErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deletePaymentLink = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            deletePaymentLinkLoading : true,
            deletePaymentLinkError : false,
            deletePaymentLinkErrorMessage : ''
        }
    });
    let request = await deleteRequest(`${config.devServerProxy}/api/paymentlink/delete/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                deletePaymentLinkLoading : false,
                deletePaymentLinkError : false,
                deletePaymentLinkErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                deletePaymentLinkLoading : false,
                deletePaymentLinkError : true,
                deletePaymentLinkErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const deployPaymentLink = (data) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            deployPaymentLinkLoading : true,
            deployPaymentLinkError : false,
            deployPaymentLinkErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/deploy`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                deployPaymentLinkLoading : false,
                deployPaymentLinkError : false,
                deployPaymentLinkErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                deployPaymentLinkLoading : false,
                deployPaymentLinkError : true,
                deployPaymentLinkErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentLink = (id='') => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getPaymentLinkLoading : true,
            getPaymentLinkError : false,
            getPaymentLinkErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/paymentlink/getpaymentlink/${id}`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentLinkLoading : false,
                getPaymentLinkError : false,
                getPaymentLinkErrorMessage : '',
                paymentLinkInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentLinkLoading : false,
                getPaymentLinkError : true,
                getPaymentLinkErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getDeployedPaymentLink = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getDeployedPaymentLinkLoading : true,
            getDeployedPaymentLinkError : false,
            getDeployedPaymentLinkErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/getlinkdata`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        const {
            encryptedResponse='', termsOfUse=''
        } = request;
        let decryptedData = encryptDecrypt(encryptedResponse, false);
        let parsed = JSON.parse(decryptedData);
        const {
            payload={}, expirationTimestamp=0
        } = parsed;
        let linkData = {}, extra={};
        if(expirationTimestamp >= new Date().getTime()){
            linkData = payload['paymentLink'] || {};
            if(payload['splitPaymentUser']){
                extra['splitPaymentUser'] = payload['splitPaymentUser'];
            }
        }else{
            extra = {
                getDeployedPaymentLinkError : true,
                getDeployedPaymentLinkErrorMessage : 'Access key is expired. Refresh the page.'
            }
        }
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDeployedPaymentLinkLoading : false,
                getDeployedPaymentLinkError : false,
                getDeployedPaymentLinkErrorMessage : '',
                deployedPaymentLink : {
                    ...linkData,
                    termsOfUse
                },
                ...extra
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDeployedPaymentLinkLoading : false,
                getDeployedPaymentLinkError : true,
                getDeployedPaymentLinkErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const paymentLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            passwordLoginLoading : true,
            passwordLoginError : false,
            passwordLoginErrorMessage : '',
            requestPasswordError : false,
            requestPasswordErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/passwordlogin`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : false,
                passwordLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                passwordLoginLoading : false,
                passwordLoginError : true,
                passwordLoginErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const requestPaymentPassword = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            requestPasswordLoading : true,
            requestPasswordError : false,
            requestPasswordErrorMessage : '',
            passwordLoginError : false,
            passwordLoginErrorMessage : '',
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/requestuserpassword`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : false,
                requestPasswordErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                requestPasswordLoading : false,
                requestPasswordError : true,
                requestPasswordErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const authenticateQuestionnaire = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            questionnaireAuthLoading : true,
            questionnaireAuthError : false,
            questionnaireAuthErrorMessage : '',
            questionnaireValidationMap : {}
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/questionnaireauth`, 'POST', data);
    const {
        encryptedResponse=''
    } = request;
    let extra={};
    if(encryptedResponse){
        let decryptedData = encryptDecrypt(encryptedResponse, false);
        let parsed = JSON.parse(decryptedData);
        const {
            payload={}, expirationTimestamp=0
        } = parsed;
        if(expirationTimestamp >= new Date().getTime()){
            let {
                validationMap={}, success=false
            } = payload;
            extra['questionnaireValidationMap'] = validationMap;
            if(!success){
                extra['questionnaireAuthError'] = true;
                extra['questionnaireAuthErrorMessage'] = 'Validation failed. please try again.';
            }
        }else{
            extra = {
                questionnaireAuthError : true,
                questionnaireAuthErrorMessage : 'Access key is expired. Refresh the page.'
            }
        }
    }
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                questionnaireAuthLoading : false,
                questionnaireAuthError : false,
                questionnaireAuthErrorMessage : '',
                ...extra
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                questionnaireAuthLoading : false,
                questionnaireAuthError : true,
                questionnaireAuthErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getPaymentLinkDocumentVerificationSecret = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getDocumentVerificationKeyLoading : true,
            getDocumentVerificationKeyError : false,
            getDocumentVerificationKeyErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/getdocumentverificationclientsecret`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        const {
            encryptedResponse=''
        } = request;
        let decryptedData = encryptDecrypt(encryptedResponse, false);
        let parsed = JSON.parse(decryptedData);
        const {
            payload={}, expirationTimestamp=0
        } = parsed;
        let extra={};
        if(expirationTimestamp >= new Date().getTime()){
            extra['documentVerificationClientSecret'] = payload['clientSecret'];
            extra['identityPlatformKey'] = payload['identityPlatformKey'];
        }else{
            extra = {
                getDocumentVerificationKeyError : true,
                getDocumentVerificationKeyErrorMessage : 'Access key is expired. Refresh the page.'
            }
        }
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDocumentVerificationKeyLoading : false,
                getDocumentVerificationKeyError : false,
                getDocumentVerificationKeyErrorMessage : '',
                ...extra
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDocumentVerificationKeyLoading : false,
                getDocumentVerificationKeyError : true,
                getDocumentVerificationKeyErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const registerPaymentLinkDocumentVerification = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            registerDocumentVerificationLoading : true,
            registerDocumentVerificationError : false,
            registerDocumentVerificationErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/registerdocumentverification`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                registerDocumentVerificationLoading : false,
                registerDocumentVerificationError : false,
                registerDocumentVerificationErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                registerDocumentVerificationLoading : false,
                registerDocumentVerificationError : true,
                registerDocumentVerificationErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const paymentDocumentVerificationLogin = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            paymentDocumentVerificationLoginLoading : true,
            paymentDocumentVerificationLoginError : false,
            paymentDocumentVerificationLoginErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/documentverificationlogin`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                paymentDocumentVerificationLoginLoading : false,
                paymentDocumentVerificationLoginError : false,
                paymentDocumentVerificationLoginErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                paymentDocumentVerificationLoginLoading : false,
                paymentDocumentVerificationLoginError : true,
                paymentDocumentVerificationLoginErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const paymentLinkSetupIntent = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getSetupIntentLoading : true,
            getSetupIntentError : false,
            getSetupIntentErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzpay/stripe/createsetupintent`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : false,
                getSetupIntentErrorMessage : '',
                stripeSetupIntentId : request['setupIntentId'],
                subMerchantAccountId : request['subMerchantAccountId'],
                stripeSetupIntentSecret : encryptDecrypt(request['encyptedKey'], false),
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getSetupIntentLoading : false,
                getSetupIntentError : true,
                getSetupIntentErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const paymentLinkPaymentIntent = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getPaymentIntentLoading : true,
            getPaymentIntentError : false,
            getPaymentIntentErrorMessage : '',
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/inhouzpay/stripe/createpaymentintent`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentIntentLoading : false,
                getPaymentIntentError : false,
                getPaymentIntentErrorMessage : '',
                stripePaymentIntentId : request['paymentIntentId'],
                subMerchantAccountId : request['subMerchantAccountId'],
                stripePaymentIntentSecret : encryptDecrypt(request['encyptedKey'], false),
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getPaymentIntentLoading : false,
                getPaymentIntentError : true,
                getPaymentIntentErrorMessage : request.errorMessage || '',
            }
        });
    }
}

export const getPaymentLinkTaxRate = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getTaxRateLoading : true,
            getTaxRateError : false,
            getTaxRateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/tax/gettaxamount`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        const {taxPercentage=0} = request;
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getTaxRateLoading : false,
                getTaxRateError : false,
                getTaxRateErrorMessage : '',
                taxRate : taxPercentage > 0 ? (taxPercentage / 100) : 0
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getTaxRateLoading : false,
                getTaxRateError : true,
                getTaxRateErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const finalizePaymentLinkPaymentIntent = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            finalizePaymentIntentLoading : true,
            finalizePaymentIntentError : false,
            finalizePaymentIntentErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/stripe/finalizestripeuipayment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                finalizePaymentIntentLoading : false,
                finalizePaymentIntentError : false,
                finalizePaymentIntentErrorMessage : '',
                paymentConfirmationId : request['confirmationId'],
                paymentNumber : request['paymentNumber'],
                paymentStillProcessing : request['paymentProcessing'],
                stripePaymentIntentId : '',
                // stripePaymentIntentSecret : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                finalizePaymentIntentLoading : false,
                finalizePaymentIntentError : true,
                finalizePaymentIntentErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const submitFixedFlexPayment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            fixedFlexPaymentLoading : true,
            fixedFlexPaymentError : false,
            fixedFlexPaymentErrorMessage : '',
            paymentSubmissionId : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentlink/initfixedflexpayment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                fixedFlexPaymentLoading : false,
                fixedFlexPaymentError : false,
                fixedFlexPaymentErrorMessage : '',
                paymentStillProcessing : request['addedToSetupIntentTracker'] ? true : false,
                stripeSetupIntentId : '',
                paymentSubmissionId : Math.random()
                // stripePaymentIntentSecret : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                fixedFlexPaymentLoading : false,
                fixedFlexPaymentError : true,
                fixedFlexPaymentErrorMessage : request.errorMessage || '',
                paymentSubmissionId : ''
            }
        });
    }
}


export const submitSubscriptionPayment = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            subscriptionPaymentLoading : true,
            subscriptionPaymentError : false,
            subscriptionPaymentErrorMessage : '',
            paymentSubmissionId : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzpay/payment/paymentlink/initsubscriptionpayment`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                subscriptionPaymentLoading : false,
                subscriptionPaymentError : false,
                subscriptionPaymentErrorMessage : '',
                paymentStillProcessing : request['addedToSetupIntentTracker'] ? true : false,
                stripeSetupIntentId : '',
                paymentSubmissionId : Math.random()
                // stripePaymentIntentSecret : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                subscriptionPaymentLoading : false,
                subscriptionPaymentError : true,
                subscriptionPaymentErrorMessage : request.errorMessage || '',
                paymentSubmissionId : ''
            }
        });
    }
}

export const setPaymentLinkActiveStatus = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            setActiveStatusLoading : true,
            setActiveStatusError : false,
            setActiveStatusErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/paymentlink/setactivestatus`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                setActiveStatusLoading : false,
                setActiveStatusError : false,
                setActiveStatusErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                setActiveStatusLoading : false,
                setActiveStatusError : true,
                setActiveStatusErrorMessage : request.errorMessage || ''
            }
        });
    }
}

export const getDefaultPaymentLinkDesign = () => async dispatch => {
    dispatch({
        type : 'SET_PAYMENT_LINK_REDUCER',
        payload : {
            getDefaultPaymentPageDesignLoading : true,
            getDefaultPaymentPageDesignError : false,
            getDefaultPaymentPageDesignErrorMessage : ''
        }
    });
    let request = await getRequest(`${config.devServerProxy}/api/paymentlink/design/getdefaultdesign`);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDefaultPaymentPageDesignLoading : false,
                getDefaultPaymentPageDesignError : false,
                getDefaultPaymentPageDesignErrorMessage : '',
            }
        });
        dispatch({
            type : 'SET_PAYMENT_LINK_DESIGN',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_PAYMENT_LINK_REDUCER',
            payload : {
                getDefaultPaymentPageDesignLoading : false,
                getDefaultPaymentPageDesignError : true,
                getDefaultPaymentPageDesignErrorMessage : request.errorMessage || ''
            }
        });
    }
}