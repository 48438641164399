const initialState = {
    getProjectsLoading : false,
    getProjectsError : false,
    getProjectsErrorMessage : '',
    inhouzProjects : [],
    inhouzProjectCount : 0,
    inhouzProjectInFocus : {},
    createProjectLoading : false,
    createProjectError : false,
    createProjectErrorMessage : '',
    getInhouzProjectLoading : false,
    getInhouzProjectError : false,
    getInhouzProjectErrorMessage : '',
    viewInFocus : 'details',
    wrapperViewInFocus : 'projects',
    updateInhouzProjectLoading : false,
    updateInhouzProjectError : false,
    updateInhouzProjectErrorMessage : '',
    updateInhouzProjectStatusLoading : false,
    updateInhouzProjectStatusError : false,
    updateInhouzProjectStatusErrorMessage : '',
    deleteProjectLoading : false,
    deleteProjectError : false,
    deleteProjectErrorMessage : '',
    changeProjectStateLoading : false,
    changeProjectStateError : false,
    changeProjectStateErrorMessage : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_PROJECT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_PROJECT_REDUCER':
            return initialState;
        default:
            return state;
    }
}