import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const getScheduledTasks = (data={}, pagination=false) => async dispatch => {
    dispatch({
        type : 'SCHEDULED_TASK_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/scheduledtask/getscheduledtasks`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SCHEDULED_TASKS_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'SCHEDULED_TASK_RESPONSE_ERROR',
            payload : request
        })
    }
}

export const getScheduledTaskLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SCHEDULED_TASK_REDUCER',
        payload : {
            getScheduledTaskLogsLoading : true,
            getScheduledTaskLogsError : false,
            getScheduledTaskLogsErrorPayload : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/scheduledtask/getscheduledtasklogs`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SCHEDULED_TASK_REDUCER',
            payload : {
                getScheduledTaskLogsLoading : false,
                getScheduledTaskLogsError : false,
                getScheduledTaskLogsErrorPayload : '',
                scheduledTaskLogList : request['results'] || [],
                scheduledTaskLogTotalCount : request['total_count'] || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_SCHEDULED_TASK_REDUCER',
            payload : {
                getScheduledTaskLogsLoading : false,
                getScheduledTaskLogsError : true,
                getScheduledTaskLogsErrorPayload : request['errorMessage'] || ''
            }
        });
    }
}

export const getScheduledTask = (data={}) => async dispatch => {
    dispatch({
        type : 'SCHEDULED_TASK_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/scheduledtask/getscheduledtask`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SCHEDULED_TASK_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SCHEDULED_TASK_RESPONSE_ERROR',
            payload : request
        })
    }
}

export const saveScheduledTask = (data={}) => async dispatch => {
    dispatch({
        type : 'SCHEDULED_TASK_REQUEST_SENT'
    });
    let request = await postRequest(
        data['scheduledTaskId'] ? 
        `${config.devServerProxy}/api/scheduledtask/edit` : 
        `${config.devServerProxy}/api/scheduledtask/create`,
        'POST',
        {
            scheduledTaskObject : data
        }
    );
    
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SAVE_SCHEDULED_TASK_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SCHEDULED_TASK_RESPONSE_ERROR',
            payload : request
        })
    }
}

export const setScheduledTaskReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_SCHEDULED_TASK_REDUCER',
        payload : data
    });
}

export const runScheduledTask = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SCHEDULED_TASK_REDUCER',
        payload : {
            runScheduledTaskLoading : true,
            runScheduledTaskError : false,
            runScheduledTaskErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/scheduledtask/run`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SCHEDULED_TASK_REDUCER',
            payload : {
                runScheduledTaskLoading : false,
                runScheduledTaskError : false,
                runScheduledTaskErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SCHEDULED_TASK_REDUCER',
            payload : {
                runScheduledTaskLoading : false,
                runScheduledTaskError : true,
                runScheduledTaskErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}