const initialState = {
    collections : [],
    getQueryCollectionsLoading : false,
    getQueryCollectionsError : false,
    queryBuilderTester : {},
    functionQueryMap : {},
    queryBuilderQuery : {},
    getQueriesLoading : false,
    getQueriesError : false,
    queries : [],
    query : {},
    
}

export default function(state=initialState, action){
    switch(action.type){
        case 'GET_COLLECTIONS_FOR_QUERY_BUILDER':
            return {
                ...state,
                getQueryCollectionsLoading : true,
                getQueryCollectionsError : false
            }
        case 'SET_COLLECTIONS_FOR_QUERY_BUILDER':
            return {
                ...state,
                getQueryCollectionsLoading : false,
                getQueryCollectionsError : false,
                collections : action.payload
            }
        case 'SET_COLLECTIONS_FOR_QUERY_BUILDER_ERROR':
            return {
                ...state,
                getQueryCollectionsLoading : false,
                getQueryCollectionsError : true,
            }
        case 'GET_COLLECTION_FOR_QUERY_BUILDER':
            if(!state[action.builderName]){
                state[action.builderName] = {};
            }
            return {
                ...state,
                [action.builderName] : {
                    ...state[action.builderName],
                    [action.collectionId] : {
                        ...state[action.builderName][action.collectionId],
                        loading : true,
                        error : false
                    }
                }
            }
        case 'SET_COLLECTION_FOR_QUERY_BUILDER':
            if(!state[action.builderName]){
                state[action.builderName] = {};
            }
            return {
                ...state,
                [action.builderName] : {
                    ...state[action.builderName],
                    [action.collectionId] : {
                        ...state[action.builderName][action.collectionId],
                        loading : false,
                        error : false,
                        collectionData : action.payload
                    }
                }
            }
        case 'SET_COLLECTION_FOR_QUERY_BUILDER_ERROR':
            if(!state[action.builderName]){
                state[action.builderName] = {};
            }
            return {
                ...state,
                [action.builderName] : {
                    ...state[action.builderName],
                    [action.collectionId] : {
                        ...state[action.builderName][action.collectionId],
                        loading : false,
                        error : true,
                    }
                }
            }
        case 'CLEAR_QUERY_BUILDER':
            return {
                ...state,
                [action.builderName] : {},
                queryBuilderTester : {
                    ...state.queryBuilderTester,
                    [action.builderName] : {
                        loading : false,
                        response : ''
                    }
                }
            }
        case 'RESET_QUERY_REDUCER':
            return {
                ...initialState,
                functionQueryMap : state.functionQueryMap
            };
        case 'RUN_QUERYBUILDER':
            return {
                ...state,
                queryBuilderTester : {
                    ...state.queryBuilderTester,
                    [action.builderName] : {
                        loading : true,
                        response : {
                            message : 'Loading...'
                        }
                    }
                }
            }
        case 'SET_QUERYBUILDER_RESPONSE':
            return {
                ...state,
                queryBuilderTester : {
                    ...state.queryBuilderTester,
                    [action.builderName] : {
                        loading : false,
                        response : action.payload
                    }
                }
            }
        case 'RESET_QUERYBUILDER_RESPONSE':
            return {
                ...state,
                queryBuilderTester : {
                    ...state.queryBuilderTester,
                    [action.builderName] : {
                        loading : false,
                        response : ''
                    }
                }
            }
        case 'GET_FUNCTION_QUERY':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : state.functionQueryMap[action.functionId] ? 
                    {
                        ...state.functionQueryMap[action.functionId],
                        isLoading : true
                    }
                    :
                    {
                       isLoading : true,
                       hasError : false,
                       query : {} 
                    }
                }
            }
        case 'SET_FUNCTION_QUERY':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : {
                        isLoading : false,
                        hasError : action.payload.error || action.payload.hasError ?
                        true : false,
                        query : action.payload.error || action.payload.hasError ? 
                        {} : action.payload
                    }
                }
            }
        case 'CLEAR_FUNCTION_QUERY':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : {
                        isLoading : false,
                        hasError : false,
                        query : {},
                        queries : []
                    }
                }
            }
        case 'GET_QUERIES':
            return {
                ...state,
                getQueriesLoading : true,
                getQueriesError : false
            }
        case 'SET_QUERIES':
            return {
                ...state,
                getQueriesLoading : false,
                getQueriesError : action.payload.error || action.payload.hasError ?
                true : false,
                queries : action.payload.error || action.payload.hasError ? 
                [] : action.payload
            }
        case 'UPDATE_FUNCTION_QUERY':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : state.functionQueryMap[action.functionId] ? 
                    {
                        ...state.functionQueryMap[action.functionId],
                        isLoading : true
                    }
                    :
                    {
                       isLoading : true,
                       hasError : false,
                       query : {} 
                    }
                }
            }
        case 'SET_FUNCTION_QUERY_UPDATE':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : {
                        isLoading : false,
                        hasError : action.payload.error || action.payload.hasError ?
                        true : false,
                        query : action.payload.error || action.payload.hasError ? 
                        {} : action.payload
                    }
                }
            }
        case 'GET_FUNCTION_QUERIES':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : state.functionQueryMap[action.functionId] ? 
                    {
                        ...state.functionQueryMap[action.functionId],
                        isLoading : true,
                        queries : []
                    }
                    :
                    {
                       isLoading : true,
                       hasError : false,
                       query : {},
                       queries : [] 
                    }
                }
            }
        case 'SET_FUNCTION_QUERIES':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : {
                        query : state.functionQueryMap[action.functionId]['query'] ? 
                        state.functionQueryMap[action.functionId]['query'] : {},
                        isLoading : false,
                        hasError : action.payload.error || action.payload.hasError ?
                        true : false,
                        queries : action.payload.error || action.payload.hasError ? 
                        [] : action.payload
                    }
                }
            }
        case 'CLEAR_FUNCTION_QUERIES':
            return {
                ...state,
                functionQueryMap : {
                    ...state.functionQueryMap,
                    [action.functionId] : {
                        ...state.functionQueryMap[action.functionId],
                        isLoading : false,
                        hasError : false,
                        queries : []
                    }
                }
            }
        default: 
            return state;
    }
}