const initialState = {
    systemUser : {}, 
    systemUsers : [],
    total_system_user_count : 0,
    getSystemUserLoading : false,
    getSystemUserError : false,
    getSystemUserErrorMessage : '',
    getSystemUsersLoading : false,
    getSystemUsersError : false,
    getSystemUsersErrorMessage : '',
    deleteSystemUserLoading : false,
    deleteSystemUserError : false,
    deleteSystemUserErrorMessage : '',
    createSystemUserLoading : false,
    createSystemUserError : false,
    createSystemUserErrorMessage : '',
    editSystemUserLoading : false,
    editSystemUserError : false,
    editSystemUserErrorMessage : '',
    userNameValidationLoading : false,
    userNameValidationError : false,
    userNameValidationErrorMessage : '',
    systemUserNameAvailable : false,
    getApiKeyLoading : false,
    getApiKeyError : false,
    getApiKeyErrorMessage : '',
    apiKeyMap : {},
    getAppBuilderSystemUsersLoading : false,
    getAppBuilderSystemUsersError : false,
    getAppBuilderSystemUsersErrorMessage : '',
    appBuilderSystemUsers : []
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_SYSTEM_USER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_SYSTEM_USER_REDUCER':
            return initialState;
        default:
            return state;
    }
}