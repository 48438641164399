const initialState = {
    metricsTopSection : {},
    metricsTopSectionDelta : {},
    appVisitsTimeline : [],
    pieMetrics : {},
    metricsHubView : '', //appMetrics, apiMetrics, subscriptionMetrics
    appMetricsQuery : {
        environment : 'production', //production, test, development
        dateRangeType : 'month', //day, week, month, halfYear, year, custom
        appId : '',
        startDate : 0,
        endDate : 0
    },
    apiMetricsQuery : {
        environment : 'production',
        dateRangeType : 'day', //hour, halfDay, day, week, month, halfYear, year, custom
        flexApiId : '',
        endpointId : '',
        startDate : 0,
        endDate : 0
    },
    topSectionLoading : false,
    topSectionErrorMessage : '',
    topSectionError : false,
    appVisitsLoading : false,
    appVisitsError : false,
    appVisitsErrorMessage : '',
    webPieMetricsLoading : false,
    webPieMetricsError : false,
    webPieMetricsErrorMessage : '',
    basicApiMetrics : {},
    altBasicApiMetrics : {},
    basicApiMetricsLoading : false,
    basicApiMetricsError : false,
    basicApiMetricsErrorMessage : '',
    averageApiTimeLoading : false,
    averageApiTimeError : false,
    averageApiTimeErrorMessage : '',
    averageApiTime : {},
    altAverageApiTime : {},
    apiTrafficLoading : false,
    apiTrafficError : false,
    apiTrafficErrorMessage : '',
    apiTraffic : [],
    apiUsageSummaryLoading : false,
    apiUsageSummaryError : false,
    apiUsageSummaryErrorMessage : '',
    apiUsageSummary : []
}

export default function (state = initialState, action){
    switch(action.type){
        case 'SET_APP_METRICS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_APP_METRICS_REDUCER':
            return initialState;
        default:
            return state;
    }
}