const initialState = {
    paymentLinks : [],
    paymentLinkTotalCount : 0,
    paymentLinkInFocus : {
        paymentLinkType : 'one-time',
        defaultCurrency : 'USD',
        paymentAmount : 0,
        collectTax : true,
        isSingleUse : false,
        collectInvoiceNumber : false,
        collectShippingAddress : true,
        collectBillingAddress : true,
        collectPurchaseOrderNumber : false,
        invoiceNumber : '',
        invoiceDueDate : 0,
        addressToTax : 'billing',
        paymentMethods : ['us_bank_account', 'card'],
        passwordRestrictionType : 'basicPassword',
        basicPasswordSet : false,
        collectSubscriberData : true,
        styleConfiguration : {
            hideLogo : false,
            hideCompanyName : false,
            hidePaymentLinkName : false,
            hidePaymentLinkDescription : false,
            productHeaderText : 'Summary',
            pageBackground : '',
            totalPriceColor : '#6c757d',
            paymentLinkNameColor : 'rgba(0,0,0,.87)',
            detailsPrimaryTextColor : 'rgba(0,0,0,.87)',
            detailsSecondaryTextColor : '#6c757d',
            detailsSectionHrBackgroundColor : 'rgba(0,0,0,.12)',
            detailsSectionInputBorder : '1px solid #ced4da',
            detailsSectionInputTextColor : '#495057',
            detailsSectionInputBackgroundColor : 'white',
            productPrimaryTextColor : 'rgba(0,0,0,.87)',
            productSecondaryTextColor : '#6c757d',
            productDeleteTextColor : '#17a2b8',
            productPriceTextColor : 'rgba(0,0,0,.87)',
            productBackgroundColor : 'white',
            productBorderColor : 'rgba(0,0,0,.12)',
            productImagePosition : 'inline',
            hideProductDescription : false,
            detailsBackground : '',
            companyNameTextColor : '#6c757d',
            stripeTheme : 'stripe',
            paymentSectionPrimaryTextColor : '#000000',
            paymentSectionSecondaryTextColor : '#6c757d',
            paymentSectionInputBorder : '1px solid #ced4da',
            paymentSectionInputTextColor : '#495057',
            paymentSectionInputBackgroundColor : 'white',
            paymentButtonBackgroundColor : 'rgb(0, 116, 212)',
            paymentButtonBackgroundTextColor : 'white',
            paymentSectionBackground : '',
            legalTextColor : '#17a2b8',
            fontFamily : 'Open Sans'
        }
    },
    defaultStyleConfiguration : {
        hideLogo : false,
        hideCompanyName : false,
        hidePaymentLinkName : false,
        hidePaymentLinkDescription : false,
        productHeaderText : 'Summary',
        pageBackground : '',
        totalPriceColor : '#6c757d',
        paymentLinkNameColor : 'rgba(0,0,0,.87)',
        detailsPrimaryTextColor : 'rgba(0,0,0,.87)',
        detailsSecondaryTextColor : '#6c757d',
        detailsSectionHrBackgroundColor : 'rgba(0,0,0,.12)',
        detailsSectionInputBorder : '1px solid #ced4da',
        detailsSectionInputTextColor : '#495057',
        detailsSectionInputBackgroundColor : 'white',
        productPrimaryTextColor : 'rgba(0,0,0,.87)',
        productSecondaryTextColor : '#6c757d',
        productDeleteTextColor : '#17a2b8',
        productPriceTextColor : 'rgba(0,0,0,.87)',
        productBackgroundColor : 'white',
        productBorderColor : 'rgba(0,0,0,.12)',
        productImagePosition : 'inline',
        hideProductDescription : false,
        detailsBackground : '',
        companyNameTextColor : '#6c757d',
        stripeTheme : 'stripe',
        paymentSectionPrimaryTextColor : '#000000',
        paymentSectionSecondaryTextColor : '#6c757d',
        paymentSectionInputBorder : '1px solid #ced4da',
        paymentSectionInputTextColor : '#495057',
        paymentSectionInputBackgroundColor : 'white',
        paymentButtonBackgroundColor : 'rgb(0, 116, 212)',
        paymentButtonBackgroundTextColor : 'white',
        paymentSectionBackground : '',
        legalTextColor : '#17a2b8',
        fontFamily : 'Open Sans'
    },
    getPaymentLinkLoading : false,
    getPaymentLinkError : false,
    getPaymentLinkErrorMessage : '',
    getPaymentLinksLoading : false,
    getPaymentLinksError : false,
    getPaymentLinksErrorMessage : '',
    createPaymentLinkLoading : false,
    createPaymentLinkError : false,
    createPaymentLinkErrorMessage : '',
    deployPaymentLinkLoading : false,
    deployPaymentLinkError : false,
    deployPaymentLinkErrorMessage : '',
    updatePaymentLinkLoading : false,
    updatePaymentLinkError : false,
    updatePaymentLinkErrorMessage : '',
    deletePaymentLinkLoading : false,
    deletePaymentLinkError : false,
    deletePaymentLinkErrorMessage : '',
    getDeployedPaymentLinkLoading : false,
    getDeployedPaymentLinkError : false,
    getDeployedPaymentLinkErrorMessage : '',
    deployedPaymentLink : {},
    splitPaymentUser : {},
    passwordLoginLoading : false,
    passwordLoginError : false,
    passwordLoginErrorMessage : '',
    requestPasswordLoading : false,
    requestPasswordError : false,
    requestPasswordErrorMessage : '',
    questionnaireAuthLoading : false,
    questionnaireAuthError : false,
    questionnaireAuthErrorMessage : '',
    questionnaireValidationMap : {},
    getDocumentVerificationKeyLoading : false,
    getDocumentVerificationKeyError : false,
    getDocumentVerificationKeyErrorMessage : '',
    documentVerificationClientSecret : '',
    identityPlatformKey : '',
    registerDocumentVerificationLoading : false,
    registerDocumentVerificationError : false,
    registerDocumentVerificationErrorMessage : '',
    paymentDocumentVerificationLoginLoading : false,
    paymentDocumentVerificationLoginError : false,
    paymentDocumentVerificationLoginErrorMessage : '',
    getSetupIntentLoading : false,
    getSetupIntentError : false,
    getSetupIntentErrorMessage : '',
    stripeSetupIntentId : '',
    stripeSetupIntentSecret : '',
    subMerchantAccountId : '',
    getPaymentIntentLoading : false,
    getPaymentIntentError : false,
    getPaymentIntentErrorMessage : '',
    stripePaymentIntentId : '',
    stripePaymentIntentSecret : '',
    getTaxRateLoading : false,
    getTaxRateError : false,
    getTaxRateErrorMessage : '',
    taxRate : 0,
    finalizePaymentIntentLoading : false,
    finalizePaymentIntentError : false,
    finalizePaymentIntentErrorMessage : '',
    paymentConfirmationId : '',
    paymentNumber : '',
    paymentStillProcessing : false,
    paymentLinkValidationError : false,
    paymentLinkValidationErrorMessage : '',
    fixedFlexPaymentLoading : false,
    fixedFlexPaymentError : false,
    fixedFlexPaymentErrorMessage : '',
    paymentSubmissionId : '',
    subscriptionPaymentLoading : false,
    subscriptionPaymentError : false,
    subscriptionPaymentErrorMessage : '',
    setActiveStatusLoading : false,
    setActiveStatusError : false,
    setActiveStatusErrorMessage : '',
    getDefaultPaymentPageDesignLoading : false,
    getDefaultPaymentPageDesignError : false,
    getDefaultPaymentPageDesignErrorMessage : '',
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_PAYMENT_LINK_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_PAYMENT_LINK_DESIGN':
            return {
                ...state,
                paymentLinkInFocus : {
                    ...state.paymentLinkInFocus,
                    styleConfiguration : action.payload
                },
                defaultStyleConfiguration : action.payload
            }
        case 'RESET_PAYMENT_LINK_REDUCER':
            return initialState;
        default:
            return state;
    }
}