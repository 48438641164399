
export const setAppViewerReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_REDUCER',
        payload : data
    });
}

export const setAppViewerAppObject = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_APP_OBJECT',
        payload : data
    });
}

export const setViewerElementAppData = (params={}) => dispatch => {
    dispatch({
        type : 'SET_VIEWER_ELEMENT_APP_DATA',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            refresh : params['refresh'] ? true : false,
            clearCache : params['clearCache'],
            appId : params['appId']
        }
    });
}

export const initializeAppViewerApp = (data={}) => dispatch => {
    dispatch({
        type : 'INITIALIZE_APP_VIEWER_APP',
        payload : data
    });
}

export const setAppviewerDataInReducer = (payload={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_DATA_IN_REDUCER',
        payload
    });
}

export const setViewerAppData = (data={}, appId='', updateSettings=false) => dispatch => {
    if(updateSettings){
        dispatch({
            type : 'SET_VIEWER_APP_SETTINGS',
            payload : {
                data,
                appId
            }
        });
    }else{
        dispatch({
            type : 'SET_VIEWER_APP_DATA',
            payload : {
                data,
                appId
            }
        });
    }
}

export const setViewerWebComponent = (params={}) => async dispatch => {
    const {
        app={}, appId='', appInFocusId=''
    } = params;
    dispatch({
        type : 'SET_VIEWER_WEB_COMPONENT',
        payload : {
            appId,
            app,
            appInFocusId
        }
    });
}

export const updateGenericElementCacheInReducer = (params={}) => dispatch => {
    dispatch({
        type : 'UPDATE_GENERIC_ELEMENT_CACHE_IN_REDUCER',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            field : params['field']
        }
    });
}