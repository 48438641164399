const initialState = {
    fileUploadLoading : false,
    fileUploadError : false,
    fileUploadErrorMessage : '',
    newFileKey : '',
    newFileUrl : ''
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_FILE_UPLOADER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_INHOUZ_FILE_UPLOADER_REDUCER':
            return initialState;
        default:
            return state;
    }
}