import dotNotationObjectUpdate from "../../utils/data/dotNotationObjectUpdate";

const initialState = {
    viewInFocus : '',
    templateInFocus : {},
    templates : [],
    appElements : [],
    appPages : [],
    templateTotalCount : 0,
    getInhouzSignTemplatesLoading : false,
    getInhouzSignTemplatesError : false,
    getInhouzSignTemplatesErrorMessage : '',
    createTemplateLoading : false,
    createTemplateError : false,
    createTemplateErrorMessage : '',
    documentInFocus : {},
    documents : [],
    documentsTotalCount : 0,
    createModalFormStep : 0,
    uploadedFiles : [],
    getInhouzSignTemplateLoading : false,
    getInhouzSignTemplateError : false,
    getInhouzSignTemplateErrorMessage : '',
    editInhouzSignTemplateLoading : false,
    editInhouzSignTemplateError : false,
    editInhouzSignTemplateErrorMessage : '',
    deleteInhouzSignTemplateLoading : false,
    deleteInhouzSignTemplateError : false,
    deleteInhouzSignTemplateErrorMessage : '',
    getGlobalApprovalWorkflowLoading : false,
    getGlobalApprovalWorkflowError : false,
    getGlobalApprovalWorkflowErrorMessage : '',
    globalApprovalWorkflow : {},
    updateApprovalWorkflowLoading : false,
    updateGlobalApprovalWorkflowError : false,
    updateGlobalApprovalWorflowErrorMessage : '',
    globalApprovalWorkflowDelta : {},
    getInhouzSignDocumentsLoading : false,
    getInhouzSignDocumentsError : false,
    getInhouzSignDocumentsErrorMessage : '',
    getInhouzSignDocumentLoading : false,
    getInhouzSignDocumentError : false,
    getInhouzSignDocumentErrorMessage : '',
    createDocTemplateName : '',
    createDocProjectName : '',
    createInhouzDocumentLoading : false,
    createInhouzDocumentError : false,
    createInhouzDocumentErrorMessage : '',
    editInhouzSignDocumentLoading : false,
    editInhouzSignDocumentError : false,
    editInhouzSignDocumentErrorMessage : '',
    deleteInhouzSignDocumentLoading : false,
    deleteInhouzSignDocumentError : false,
    deleteInhouzSignDocumentErrorMessage : '',
    sendInhouzSignDocumentLoading : false,
    sendInhouzSignDocumentError : false,
    sendInhouzSignDocumentErrorMessage : '',
    inhouzSignContractAppBuild : {},
    getContractBuildLoading : false,
    getContractBuildError : false,
    getContractBuildErrorMessage : '',
    contractAppBuildLoading : false,
    contractAppBuildError : false,
    contractAppBuildErrorMessage : '',
    contractAppBuild : {},
    getApprovalRequestsLoading : false,
    getApprovalRequestsError : false,
    getApprovalRequestsErrorMessage : '',
    approvalRequests : [],
    approvalRequestsTotalCount : 0,
    documentApprovalLoading : false,
    documentApprovalError : false,
    documentApprovalErrorMessage : '',
    getApprovalRequestLoading : false,
    getApprovalRequestError : false,
    getApprovalRequestErrorMessage : '',
    approvalRequest : {},
    getApprovalBreakdownLoading : false,
    getApprovalBreakdownError : false,
    getApprovalBreakdownErrorMessage : '',
    approvalBreakdown : {},
    getAuditLogsLoading : false,
    getAuditLogsError : false,
    getAuditLogsErrorMessage : '',
    auditLogs : [],
    auditLogsTotalCount : 0,
    inhouzSignMode : false,
    signerMode : false,
    printMode : false,
    inhouzSignSubmitTrackerKey : '',
    inhouzSignUpdateTrackerKey : '',
    inhouzSignRequiredFieldTracker : {},
    distributeDocumentLoading : false,
    distributeDocumentError : false,
    distributeDocumentErrorMessage : '',
    extractRecipientTrigger : '',
    inhouzSignDocumentDataDelta : {},
    signDocumentLoading : false,
    signDocumentError : false,
    signDocumentErrorMessage : '',
    generateInhouzSignPdfLoading : false,
    generateInhouzSignPdfError : false,
    generateInhouzSignPdfErrorMessage : '',
    inhouzSignFileKeyMap : {},
    getInhouzSignPdfLoading : false,
    getInhouzSignPdfError : false,
    getInhouzSignPdfErrorMessage : '',
    inhouzSignPdfBase64 : '',
    cancelDocumentLoading : false,
    cancelDocumentError : false,
    cancelDocumentErrorMessage : '',
    declineDocumentLoading : false,
    declineDocumentError : false,
    declineDocumentErrorMessage : '',
    generateInhouzSignUrlLoading : false,
    generateInhouzSignUrlError : false,
    generateInhouzSignUrlErrorMessage : '',
    inhouzSignerUrl : '',
    getIdentityVerificationLogsLoading : false,
    getIdentityVerificationLogsError : false,
    getIdentityVerificationLogsErrorMessage : '',
    identityVerificationLogs : [],
    getAttachmentListLoading : false,
    getAttachmentListError : false,
    getAttachmentListErrorMessage : '',
    attachmentList : [],
    attachmentListTotalCount : 0,
    inhouzSignPaymentNotificationLoading : false,
    inhouzSignPaymentNotificationError : false,
    inhouzSignPaymentNotificationErrorMessage : '',
    markAsCompleteLoading : false,
    markAsCompleteError : false,
    markAsCompleteErrorMessage : '',
    inhouzSignDocumentDataTracker : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_INHOUZ_SIGN_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_INHOUZ_SIGN_DOCUMENT_DELTA':
            let field = action.payload.isTemplate ?
            'templateInFocus' : 'documentInFocus';
            return {
                ...state,
                [field] : {
                    ...state[field],
                    ...action.payload.data
                }
            }
        case 'UPDATE_INHOUZ_SIGN_DOCUMENT_DATA':
            const {
                documentInFocus={},
                inhouzSignDocumentDataDelta={}
            } = state;
            const {
                documentData={}
            } = documentInFocus;
            const {
                path='', data
            } = action.payload;
            let updatedDocumentData = dotNotationObjectUpdate(
                documentData,
                data,
                path
            );
            let deltaUpdate = dotNotationObjectUpdate(
                inhouzSignDocumentDataDelta,
                data,
                path
            );
            return {
                ...state,
                documentInFocus : {
                    ...state.documentInFocus,
                    documentData : updatedDocumentData
                },
                inhouzSignDocumentDataDelta : deltaUpdate,
                inhouzSignDocumentDataTracker : Math.random()
            }
        case 'SET_INHOUZ_SIGN_DATA_IN_REDUCER':
            let reducerUpdate = dotNotationObjectUpdate(
                state,
                action.payload.data,
                action.payload.path
            );

            return {
                ...state,
                ...reducerUpdate
            }
        case 'MANAGE_INHOUZ_SIGN_REQUIRED_FIELDS':
            return {
                ...state,
                inhouzSignRequiredFieldTracker : {
                    ...state.inhouzSignRequiredFieldTracker,
                    [action.payload.elementId] : action.payload.data
                }
            }
        case 'RESET_INHOUZ_SIGN_REDUCER':
            return initialState;
        default:
            return state;
    }
}