const initialState = {
    payoutAccounts : [],
    payoutAccountInFocus : {},
    payoutAccountError : false,
    payoutAccountErrorMessage : '',
    payoutAccountLoading : false,
    payoutAccountTotalCount : 0,
    payoutAccountNameIsAvailable : true,
    sanityCheckResponse : {},
    stripeAccountLinkUrl : '',
    sanityCheckError : false,
    sanityCheckErrorMessage : '',
    sanityCheckLoading : false,
    stripeDashboardLoading : false,
    stripeDashboardError : false,
    stripeDashboardErrorMessage : '',
    stripeDashboardUrl : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'PAYOUT_ACCOUNT_LOADING':
            return {
                ...state,
                payoutAccountLoading : true,
                payoutAccountError : false,
                payoutAccountErrorMessage : ''
            }
        case 'PAYOUT_ACCOUNT_ERROR':
            return {
                ...state,
                payoutAccountLoading : false,
                payoutAccountError : true,
                payoutAccountErrorMessage : action.payload.errorMessage
            }
        case 'GET_PAYOUT_ACCOUNTS_SUCCESS':
            return {
                ...state,
                payoutAccountLoading : false,
                payoutAccountError : false,
                payoutAccountErrorMessage : '',
                payoutAccounts : action.payload['results'] || [],
                payoutAccountTotalCount : action.payload['total_count'] || 0
            }
        case 'PAYOUT_ACCOUNT_SANITY_CHECK_RESPONSE':
            return {
                ...state,
                sanityCheckLoading : false,
                sanityCheckError : false,
                sanityCheckErrorMessage : '',
                sanityCheckResponse : action.payload
            }
        case 'PAYOUT_ACCOUNT_SANITY_CHECK_LOADING':
            return {
                ...state,
                sanityCheckLoading : true,
                sanityCheckError : false,
                sanityCheckErrorMessage : ''
            }
        case 'PAYOUT_ACCOUNT_SANITY_CHECK_ERROR':
            return {
                ...state,
                sanityCheckError : true,
                sanityCheckErrorMessage : action.payload.errorMessage,
                sanityCheckLoading : false
            }
        case 'GET_PAYOUT_ACCOUNT_SUCCESS':
            return {
                ...state,
                payoutAccountLoading : false,
                payoutAccountError : false,
                payoutAccountErrorMessage : '',
                payoutAccountInFocus : action.payload
            }
        case 'PAYOUT_ACCOUNT_SUCCESS':
            return {
                ...state,
                payoutAccountLoading : false,
                payoutAccountError : false,
                payoutAccountErrorMessage : ''
            }
        case 'STRIPE_ACCOUNT_LINK':
            return {
                ...state,
                payoutAccountLoading : false,
                payoutAccountErrorMessage : '',
                payoutAccountError : false,
                stripeAccountLinkUrl : action.payload['url']
            }
        case 'SET_PAYMENT_ACCOUNT_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_PAYOUT_ACCOUNT_REDUCER':
                return initialState;
        case 'PAYOUT_STRIPE_DASHBOARD_LOADING':
            return {
                ...state,
                stripeDashboardError : false,
                stripeDashboardErrorMessage : '',
                stripeDashboardLoading : true
            }
        case 'PAYOUT_STRIPE_DASHBOARD_ERROR':
            return {
                ...state,
                stripeDashboardLoading : false,
                stripeDashboardError : true,
                stripeDashboardErrorMessage : action.payload.errorMessage
            }
        case 'PAYOUT_STRIPE_DASHBOARD_LINK':
            return {
                ...state,
                stripeDashboardError : false,
                stripeDashboardErrorMessage : '',
                stripeDashboardLoading : false,
                stripeDashboardUrl : action.payload['url']
            }
        default:
            return state;
    }
}