import postRequest from '../../../utils/requests/postRequest';
import config from '../../../config/config';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';

export const subscribe = (data) => async dispatch => {
    dispatch({
        type : 'SUBSCRIBER_ACTION_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriber/subscribe`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SUBSCRIBER_ACTION_ERROR',
            payload : request
        });
    }
}

export const cancelSubscription = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : {
            cancelSubscriptionLoading : true,
            cancelSubscriptionError : false,
            cancelSubscriptionErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriber/cancelsubscription`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                cancelSubscriptionLoading : false,
                cancelSubscriptionError : false,
                cancelSubscriptionErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                cancelSubscriptionLoading : false,
                cancelSubscriptionError : true,
                cancelSubscriptionErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}

export const transferSubscriptions = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : {
            transferSubscriptionLoading : true,
            transferSubscriptionError : false,
            transferSubscriptionErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/transfersubscriptions`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                transferSubscriptionLoading : false,
                transferSubscriptionError : false,
                transferSubscriptionErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                transferSubscriptionLoading : false,
                transferSubscriptionError : true,
                transferSubscriptionErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}

export const addToMigrationQueue = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : {
            subscriptionMigrationLoading : true,
            subscriptionMigrationError : false,
            subscriptionMigrationErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscribe/changeplan`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                subscriptionMigrationLoading : false,
                subscriptionMigrationError : false,
                subscriptionMigrationErrorMessage : '',
                addToMigrationQueueResponse : request
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                subscriptionMigrationLoading : false,
                subscriptionMigrationError : true,
                subscriptionMigrationErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}

export const getMigrationTiers = (data={}) => async dispatch => {
    dispatch({
        type : 'GET_MIGRATION_TIERS_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriptionservice/getmigrationtiers`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_MIGRATION_TIERS_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'GET_MIGRATION_TIERS_ERROR',
            payload : request
        });
    }
} 

export const getSubscribedUserInfo = (data) => async dispatch => {
    dispatch({
        type : 'SUBSCRIBER_ACTION_LOADING'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriber/getsubscribedusersinfo`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBED_USER_INFO_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'SUBSCRIBER_ACTION_ERROR',
            payload : request
        });
    }
}

export const setSubscriberReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : data
    });
}

export const resetSubscriberReducer = () => dispatch => {
    dispatch({
        type : 'RESET_SUBSCRIBER_REDUCER'
    })
}

export const getApiKey = (data, external) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : {
            getApiKeyLoading : true,
            getApiKeyError : false,
            getApiKeyErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriber/getsubscriptionapikey`,
        'POST',
        data,
        {},
        external ? 'x-react' : ''
    );
    if(
        !request.hasError && !request.error
    ){
        let parsedResponse={}, decryptedResponse='';
        try{
            decryptedResponse = encryptDecrypt(
                request['encryptedData'], 
                false
            ); 
            parsedResponse = JSON.parse(decryptedResponse);
        }catch(e){
            console.log(e);
        }
        if(
            !parsedResponse ||
            (new Date().getTime() > parsedResponse['expirationTimestamp'])
        ){
            dispatch({
                type : 'SET_SUBSCRIBER_REDUCER',
                payload : {
                    getApiKeyLoading : false,
                    getApiKeyError : true,
                    getApiKeyErrorMessage : 'Request timed out. Try again.'
                }
            });
        }else{
            dispatch({
                type : 'SET_SUBSCRIBER_REDUCER',
                payload : {
                    getApiKeyLoading : false,
                    getApiKeyError : false,
                    getApiKeyErrorMessage : '',
                    apiKey : parsedResponse['apiKey']
                }
            });
        }
    }else{
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                getApiKeyLoading : false,
                getApiKeyError : true,
                getApiKeyErrorMessage : request['errorMessage'] || ''
            }
        })
    }
}

export const changeApiKey = (data={}, external) => async dispatch => {
    dispatch({
        type : 'SET_SUBSCRIBER_REDUCER',
        payload : {
            changeApiKeyLoading : true,
            changeApiKeyError : false,
            changeApiKeyErrorMessage : '',
            apiKey : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/subscriber/changesubscriptionapikey`,
        'POST',
        data,
        {},
        external ? 'x-react' : ''
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                changeApiKeyLoading : false,
                changeApiKeyError : false,
                changeApiKeyErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_SUBSCRIBER_REDUCER',
            payload : {
                changeApiKeyLoading : false,
                changeApiKeyError : true,
                changeApiKeyErrorMessage : request['errorMessage'] || ''
            }
        });
    }
}