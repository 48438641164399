import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const getCollectionsForQueryBuilder = (data={}) => async dispatch => {
    dispatch({type : 'GET_COLLECTIONS_FOR_QUERY_BUILDER'});
    let request = await postRequest(`${config.devServerProxy}/api/flexcollection/get/getcollections`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_COLLECTIONS_FOR_QUERY_BUILDER',
            payload : request
        });
    }else{
        dispatch({
            type : 'SET_COLLECTIONS_FOR_QUERY_BUILDER_ERROR'
        });
    }
}

export const getCollectionForQueryBuilder = (id='', builderName='', prod=false) => async dispatch => {
    dispatch({
        type : 'GET_COLLECTION_FOR_QUERY_BUILDER',
        builderName,
        collectionId : id
    });
    let request = await getRequest(`${config.devServerProxy}/api/flexcollection/get/${id}/${prod}`);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'SET_COLLECTION_FOR_QUERY_BUILDER',
            payload : request,
            builderName,
            collectionId : id
        });
    }else{
        dispatch({
            type : 'SET_COLLECTION_FOR_QUERY_BUILDER_ERROR',
            builderName,
            collectionId : id
        });
    }
}

export const runQueryBuilder = (data={}, builderName='') => async dispatch => {
    dispatch({
        type : 'RUN_QUERYBUILDER',
        builderName
    });
    let request = await postRequest(`${config.devServerProxy}/api/querybuildertester/queryParser`, 'POST', data);
    dispatch({
        type : 'SET_QUERYBUILDER_RESPONSE',
        payload : request,
        builderName,
    });
}

export const clearQueryBuilder = (builderName='') => dispatch => {
    dispatch({
        type : 'CLEAR_QUERY_BUILDER',
        builderName
    });
}

export const resetQueryReducer = () => dispatch => {
    dispatch({
        type : 'RESET_QUERY_REDUCER'
    });
}

export const resetQueryBuilderResponse = () => dispatch => {
    dispatch({
        type : 'RESET_QUERYBUILDER_RESPONSE'
    });
}

export const getFunctionQuery = (queryId='', functionId='') => async dispatch => {
    dispatch({
        type : 'GET_FUNCTION_QUERY',
        functionId 
    });
    let request = await getRequest(`${config.devServerProxy}/api/query/get/${queryId}`);
    dispatch({
        type : 'SET_FUNCTION_QUERY',
        functionId,
        payload : request
    });
}

export const clearFunctionQuery = (functionId='') => dispatch => {
    dispatch({
        type : 'CLEAR_FUNCTION_QUERY',
        functionId
    });
}

export const clearFunctionQueries = (functionId='') => dispatch => {
    dispatch({
        type : 'CLEAR_FUNCTION_QUERIES',
        functionId
    });
}

export const getQueries = (data) => async dispatch => {
    dispatch({
        type : 'GET_QUERIES'
    });
    let request = await postRequest(`${config.devServerProxy}/api/query/get`, 'POST', data);
    dispatch({
        type : 'SET_QUERIES',
        payload : request
    });
}

export const getFunctionQueries = (data, functionId='') => async dispatch => {
    dispatch({
        type : 'GET_FUNCTION_QUERIES',
        functionId
    });
    let request = await postRequest(`${config.devServerProxy}/api/query/get`, 'POST', data);
    dispatch({
        type : 'SET_FUNCTION_QUERIES',
        payload : request,
        functionId
    });
}

export const updateQuery = (data='', functionId='') => async dispatch => {
    const {
        queryObject={}
    } = data;

    dispatch({
        type : 'UPDATE_FUNCTION_QUERY',
        functionId
    });
    let request;
    if(!queryObject.queryId){
        request = await postRequest(`${config.devServerProxy}/api/query/create`, 'POST', data);
    }else{
        request = await postRequest(`${config.devServerProxy}/api/query/edit`, 'POST', data);
    }

    dispatch({
        type : 'SET_FUNCTION_QUERY_UPDATE',
        functionId,
        payload : request
    });
}