import postRequest from '../../utils/requests/postRequest';
import getRequest from '../../utils/requests/getRequest';
import config from '../../config/config';

export const getPermissions = (data={}, pagination=false) => async dispatch => {
    dispatch({
        type : 'PERMISSIONS_REQUEST_SENT'
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/permissions/getpermissions`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_PERMISSIONS_SUCCESS',
            payload : request,
            pagination
        });
    }else{
        dispatch({
            type : 'PERMISSIONS_REQUEST_ERROR',
            payload : request
        })
    }
}

export const savePermission = (data={}) => async dispatch => {
    dispatch({
        type : 'PERMISSIONS_REQUEST_SENT'
    });
    let request = await postRequest(
        data['_id'] ? 
        `${config.devServerProxy}/api/permissions/edit` : 
        `${config.devServerProxy}/api/permissions/create`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_PERMISSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PERMISSIONS_REQUEST_ERROR',
            payload : request
        })
    }
}

export const getPermission = (id='') => async dispatch => {
    dispatch({
        type : 'PERMISSIONS_REQUEST_SENT'
    });
    let request = await getRequest(
        `${config.devServerProxy}/api/roles/getrole/${id}`
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'GET_PERMISSION_SUCCESS',
            payload : request
        });
    }else{
        dispatch({
            type : 'PERMISSIONS_REQUEST_ERROR',
            payload : request
        })
    }
}

export const setPermissionReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_PERMISSION_REDUCER',
        payload : data
    });
}

export const deletePermission = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_PERMISSION_REDUCER',
        payload : {
            permissionDeleteLoading : true,
            permissionDeleteError : false,
            permissionDeleteErrorMessage : ''
        }
    });
    let request = await postRequest(
        `${config.devServerProxy}/api/permissions/delete`,
        'POST',
        data
    );
    if(
        !request.hasError &&
        !request.error
    ){
        dispatch({
            type : 'SET_PERMISSION_REDUCER',
            payload : {
                permissionDeleteLoading : false,
                permissionDeleteError : false,
                permissionDeleteErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_PERMISSION_REDUCER',
            payload : {
                permissionDeleteLoading : false,
                permissionDeleteError : true,
                permissionDeleteErrorMessage : request.errorMessage || ''
            }
        })
    }
}