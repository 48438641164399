const initialState = {
    subscribers : [],
    subscribersLong : [],
    subscribersCompanyBreakdown : [],
    subscriptions : [],
    subscriptionsCount : 0,
    subscribersCompanyCount : 0,
    total_subscribers : 0,
    getSubscribersLoading : false,
    getSubscribersError : false,
    getSubscribersErrorMessage : '',
    subscriptionLogsLoading : false,
    subscriptionLogsError : false,
    subscriptionLogsErrorMessage : '',
    subscriptionUsageLogs : [],
    subscriptionUsageTotalCount : 0,
    subscriberActionLogsLoading : false,
    subscriberActionLogsError : false,
    subscriberActionLogsErrorMessage : '',
    subscriberActionLogs : [],
    subscriberActionLogsTotalCount : 0,
    subscriberCountMap : {},
    dailyActiveUserCountMap : {},
    recurringRevenueMap : {},
    churnMap : {},
    ltvMap : {},
    dailyRevenueMap : {},
    subscriberCountChartMap : {},
    dailyRecurringRevenuesMap : {},
    subscriptionsLoading : false,
    subscriptionsError : false,
    subscriptionsErrorMessage : '',
    subscribedPackagesLoading : false,
    subscribedPackagesError : false,
    subscribedPackagesErrorMessage : '',
    subscribedPackages : [],
    subscribedPackagesTotalCount : 0,
    subscribedServicesLoading : false,
    subscribedServicesError : false,
    subscribedServicesErrorMessage : '',
    subscribedServices : [],
    subscribedCompaniesLoading : false,
    subscribedCompaniesError : false,
    subscribedCompaniesErrorMessage : '',
    subscribedCompanies : []
}

export default function (state = initialState, action){
    if(action.componentId && !state.subscriberCountMap[action.componentId]){
        state.subscriberCountMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            subscriberCount : 0,
            isLoading : false
        }
    }
    if(action.componentId && !state.dailyActiveUserCountMap[action.componentId]){
        state.dailyActiveUserCountMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            dailyActiveUserCount : 0,
            isLoading : false
        }
    }
    if(action.componentId && !state.recurringRevenueMap[action.componentId]){
        state.recurringRevenueMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            MRR : 0,
            ARR : 0,
            monthYear : '',
            isLoading : false,
            userCount : 0,
            monthlyRevenuePerUser : 0,
            annualRevenuePerUser : 0
        }
    }
    if(action.componentId && !state.churnMap[action.componentId]){
        state.churnMap[action.componentId] = {
            isLoading : false,
            hasError : false,
            errorMessage : '',
            churnedMRR : 0,
            churnedARR : 0,
            churnedRevenueRate : 0,
            churnCount : 0,
            churnRate : 0,
            currentSubscriberCount : 0,
            startingSubscriberCount : 0
        }
    }
    if(action.componentId && !state.ltvMap[action.componentId]){
        state.ltvMap[action.componentId] = {
            isLoading : false,
            hasError : false,
            errorMessage : '',
            LTV : 0,
            ARPU : 0,
            churnRate : 0,
            MRR : 0,
            userCount : 0
        }
    }
    if(action.componentId && !state.dailyRevenueMap[action.componentId]){
        state.dailyRevenueMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            dailyRevenue : [],
            isLoading : false
        }
    }
    if(action.componentId && !state.subscriberCountChartMap[action.componentId]){
        state.subscriberCountChartMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            subscriberCountData : [],
            isLoading : false
        }
    }
    if(action.componentId && !state.dailyRecurringRevenuesMap[action.componentId]){
        state.dailyRecurringRevenuesMap[action.componentId] = {
            hasError : false,
            errorMessage : '',
            recurringRevenues : [],
            isLoading : false
        }
    }
    switch(action.type){
        case 'GET_SUBSCRIBERS_LOADING':
            return {
                ...state,
                getSubscribersError : false,
                getSubscribersErrorMessage : '',
                getSubscribersLoading : true
            }
        case 'GET_SUBSCRIBERS_ERROR':
            return {
                ...state,
                getSubscribersError : true,
                getSubscribersErrorMessage : action.payload.errorMessage,
                getSubscribersLoading : false
            }
        case 'GET_SUBSCRIBERS_SUCCESS':
            return {
                ...state,
                subscribers : action.payload.results || [],
                // subscribersLong : action.payload.subscribersLong || [],
                // subscribersCompanyBreakdown : action.payload.companyBreakdown || [],
                // subscribersCompanyCount : action.payload.companyCount || 0,
                // subscriptionsCount : action.payload.subscriptionsCount || 0,
                total_subscribers : action.payload.total_count || 0,
                getSubscribersError : false,
                getSubscribersErrorMessage : '',
                getSubscribersLoading : false
            }
        case 'GET_SUBSCRIPTIONS_SUCCESS':
            return {
                getSubscribersError : false,
                getSubscribersErrorMessage : '',
                getSubscribersLoading : false,
                subscriptions : action.payload.results,
                subscriptionsCount : action.payload.total_count
            }
        case 'GET_SUBSCRIPTION_LOGS_LOADING':
            return {
                ...state,
                subscriptionLogsLoading : true,
                subscriptionLogsError : false,
                subscriptionLogsErrorMessage : ''
            }
        case 'GET_SUBSCRIPTION_LOGS_ERROR':
            return {
                ...state,
                subscriptionLogsLoading : false,
                subscriptionLogsError : true,
                subscriptionLogsErrorMessage : action.payload.errorMessage
            }
        case 'GET_SUBSCRIPTION_LOGS_SUCCESS':
            return {
                ...state,
                subscriptionLogsLoading : false,
                subscriptionLogsError : false,
                subscriptionLogsErrorMessage : '',
                subscriptionUsageLogs : action.payload.results || [],
                subscriptionUsageTotalCount : action.payload.total_count || 0
            }
        case 'GET_SUBSCRIBER_ACTION_LOGS_LOADING':
            return {
                ...state,
                subscriberActionLogsLoading : true,
                subscriberActionLogsError : false,
                subscriberActionLogsErrorMessage : '',
            }
        case 'GET_SUBSCRIBER_ACTION_LOGS_ERROR':
            return {
                ...state,
                subscriberActionLogsLoading : false,
                subscriberActionLogsError : true,
                subscriberActionLogsErrorMessage : action.payload.errorMessage,
            }
        case 'GET_SUBSCRIBER_ACTION_LOGS_SUCCESS':
            return {
                ...state,
                subscriberActionLogsLoading : false,
                subscriberActionLogsError : false,
                subscriberActionLogsErrorMessage : '',
                subscriberActionLogs : action.payload.results || [],
                subscriberActionLogsTotalCount : action.payload.total_count || 0
            }
        case 'SET_SUBSCRIPTION_METRICS_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_SUBSCRIPTION_METRICS_REDUCER':
            return initialState;
        case 'GET_SUBSCRIBER_COUNT_LOADING':
            return {
                ...state,
                subscriberCountMap : {
                    ...state.subscriberCountMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'GET_SUBSCRIBER_COUNT_ERROR':
            return {
                ...state,
                subscriberCountMap : {
                    ...state.subscriberCountMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'GET_SUBSCRIBER_COUNT_SUCCESS':
            return {
                ...state,
                subscriberCountMap : {
                    ...state.subscriberCountMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : false,
                        subscriberCount : action.payload.subscriberCount
                    }
                }
            }  
        case 'GET_DAILY_ACTIVE_USER_COUNT_LOADING':
            return {
                ...state,
                dailyActiveUserCountMap : {
                    ...state.dailyActiveUserCountMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'GET_DAILY_ACTIVE_USER_COUNT_ERROR':
            return {
                ...state,
                dailyActiveUserCountMap : {
                    ...state.dailyActiveUserCountMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'GET_DAILY_ACTIVE_USER_COUNT_SUCCESS':
            return {
                ...state,
                dailyActiveUserCountMap : {
                    ...state.dailyActiveUserCountMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : false,
                        dailyActiveUserCount : action.payload.userCount
                    }
                }
            } 
        case 'GET_RECURRING_REVENUE_LOADING':
            return {
                ...state,
                recurringRevenueMap : {
                    ...state.recurringRevenueMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'GET_RECURRING_REVENUE_ERROR':
            return {
                ...state,
                recurringRevenueMap : {
                    ...state.recurringRevenueMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'GET_RECURRING_REVENUE_SUCCESS':
            return {
                ...state,
                recurringRevenueMap : {
                    ...state.recurringRevenueMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : false,
                        MRR : action.payload.MRR,
                        ARR : action.payload.ARR,
                        monthYear : action.payload.monthYear,
                        userCount : action.payload.userCount,
                        monthlyRevenuePerUser : action.payload.monthlyRevenuePerUser,
                        annualRevenuePerUser : action.payload.annualRevenuePerUser
                    }
                }
            } 
        case 'GET_CHURN_DATA_LOADING':
            return {
                ...state,
                churnMap : {
                    ...state.churnMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'GET_CHURN_DATA_ERROR':
            return {
                ...state,
                churnMap : {
                    ...state.churnMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'GET_CHURN_DATA_SUCCESS':
            return {
                ...state,
                churnMap : {
                    ...state.churnMap,
                    [action.componentId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        churnedMRR : action.payload.churnedMRR,
                        churnedARR : action.payload.churnedARR,
                        churnedRevenueRate : action.payload.churnedRevenueRate,
                        churnCount : action.payload.churnCount,
                        churnRate : action.payload.churnRate,
                        currentSubscriberCount : action.payload.currentSubscriberCount,
                        startingSubscriberCount : action.payload.startingSubscriberCount
                    }
                }
            } 
        case 'GET_LTV_LOADING':
            return {
                ...state,
                ltvMap : {
                    ...state.ltvMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'GET_LTV_ERROR':
            return {
                ...state,
                ltvMap : {
                    ...state.ltvMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'GET_LTV_SUCCESS':
            return {
                ...state,
                ltvMap : {
                    ...state.ltvMap,
                    [action.componentId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        LTV : action.payload.LTV,
                        ARPU : action.payload.ARPU,
                        churnRate : action.payload.churnRate,
                        MRR : action.payload.MRR,
                        userCount : action.payload.userCount
                    }
                }
            } 
        case 'SUBSCRIPTION_DAILY_REVENUE_LOADING':
            return {
                ...state,
                dailyRevenueMap : {
                    ...state.dailyRevenueMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'SUBSCRIPTION_DAILY_REVENUE_ERROR':
            return {
                ...state,
                dailyRevenueMap : {
                    ...state.dailyRevenueMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'SUBSCRIPTION_DAILY_REVENUE_SUCCESS':
            return {
                ...state,
                dailyRevenueMap : {
                    ...state.dailyRevenueMap,
                    [action.componentId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        dailyRevenue : action.payload.results || []
                    }
                }
            } 
        case 'SUBSCRIBER_COUNT_CHART_LOADING':
            return {
                ...state,
                subscriberCountChartMap : {
                    ...state.subscriberCountChartMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'SUBSCRIBER_COUNT_CHART_ERROR':
            return {
                ...state,
                subscriberCountChartMap : {
                    ...state.subscriberCountChartMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'SUBSCRIBER_COUNT_CHART_SUCCESS':
            return {
                ...state,
                subscriberCountChartMap : {
                    ...state.subscriberCountChartMap,
                    [action.componentId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        subscriberCountData : action.payload.results || []
                    }
                }
            } 
        case 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_LOADING':
            return {
                ...state,
                dailyRecurringRevenuesMap : {
                    ...state.dailyRecurringRevenuesMap,
                    [action.componentId] : {
                        hasError : false,
                        errorMessage : '',
                        isLoading : true
                    }
                }
            }
        case 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_ERROR':
            return {
                ...state,
                dailyRecurringRevenuesMap : {
                    ...state.dailyRecurringRevenuesMap,
                    [action.componentId] : {
                        hasError : true,
                        errorMessage : action.payload.errorMessage,
                        isLoading : false
                    }
                }
            }
        case 'DAILY_SUBSCRIPTION_RECURRING_REVENUES_SUCCESS':
            return {
                ...state,
                dailyRecurringRevenuesMap : {
                    ...state.dailyRecurringRevenuesMap,
                    [action.componentId] : {
                        isLoading : false,
                        hasError : false,
                        errorMessage : '',
                        recurringRevenues : action.payload.results || []
                    }
                }
            } 
        default:
            return state;
    }
}