import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setInhouzProjectReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : data
    });
}

export const resetInhouzProjectReducer = () => dispatch => {
    dispatch({
        type : 'RESET_INHOUZ_PROJECT_REDUCER'
    });
}

export const getInhouzProjects = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            getProjectsLoading : true,
            getProjectsError : false,
            getProjectsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/getprojects`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                getProjectsLoading : false,
                getProjectsError : false,
                getProjectsErrorMessage : '',
                inhouzProjects : request.results || [],
                inhouzProjectCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                getProjectsLoading : false,
                getProjectsError : true,
                getProjectsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createInhouzProject = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            createProjectLoading : true,
            createProjectError : false,
            createProjectErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/create`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                createProjectLoading : false,
                createProjectError : false,
                createProjectErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                createProjectLoading : false,
                createProjectError : true,
                createProjectErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getInhouzProject = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            getInhouzProjectLoading : true,
            getInhouzProjectError : false,
            getInhouzProjectErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/getproject`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                getInhouzProjectLoading : false,
                getInhouzProjectError : false,
                getInhouzProjectErrorMessage : '',
                inhouzProjectInFocus : request
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                getInhouzProjectLoading : false,
                getInhouzProjectError : true,
                getInhouzProjectErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updateInhouzProject = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            updateInhouzProjectLoading : true,
            updateInhouzProjectError : false,
            updateInhouzProjectErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/edit`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                updateInhouzProjectLoading : false,
                updateInhouzProjectError : false,
                updateInhouzProjectErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                updateInhouzProjectLoading : false,
                updateInhouzProjectError : true,
                updateInhouzProjectErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const updateInhouzProjectStatus = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            updateInhouzProjectStatusLoading : true,
            updateInhouzProjectStatusError : false,
            updateInhouzProjectStatusErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/changestatus`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                updateInhouzProjectStatusLoading : false,
                updateInhouzProjectStatusError : false,
                updateInhouzProjectStatusErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                updateInhouzProjectStatusLoading : false,
                updateInhouzProjectStatusError : true,
                updateInhouzProjectStatusErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteInhouzProject = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            deleteProjectLoading : true,
            deleteProjectError : false,
            deleteProjectErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/delete`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                deleteProjectLoading : false,
                deleteProjectError : false,
                deleteProjectErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                deleteProjectLoading : false,
                deleteProjectError : true,
                deleteProjectErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const changeInhouzProjectState = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_INHOUZ_PROJECT_REDUCER',
        payload : {
            changeProjectStateLoading : true,
            changeProjectStateError : false,
            changeProjectStateErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/inhouzproject/project/changeprojectstate`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                changeProjectStateLoading : false,
                changeProjectStateError : false,
                changeProjectStateErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_INHOUZ_PROJECT_REDUCER',
            payload : {
                changeProjectStateLoading : false,
                changeProjectStateError : true,
                changeProjectStateErrorMessage : request.errorMessage || ''
            }
        })
    }
}